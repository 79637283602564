import React, { Component } from 'react';
import { IAuthService, IUserService, Team, TeamMember } from 'telehealth-abstractions';
import { StatusService, AdditionalHubNotification, HubStatusMethod, UserService, CallService } from 'telehealth-api';
import { CallTreeTeam } from './CallTreeTeam'

import './CallHeader.css';
import './CallTree.css';

interface CallTreeState {
	teams: Array<Team>,
	roomNameRedirect: string | null
}

export interface CallTreeProps {
	authService: IAuthService,
	onCallCreated: (roomId: string) => any,
	getCallDetailsAsync: () => Promise<EliteCallDetails>
}

export interface EliteCallDetails {
	unitNumber: string | undefined,
	agencyId: string | undefined,
	agencyName: string | undefined
}

export class CallTree extends Component<Readonly<CallTreeProps>, Readonly<CallTreeState>> {
	_callCreatedCallback: ((...args: any[]) => void) | null = null;
	userService: IUserService;
	authService: IAuthService;
	callService: CallService;
	onCallCreated: (roomId: string) => any;

	constructor(props: Readonly<CallTreeProps>) {
		super(props);
		this.state = {
			teams: [],
			roomNameRedirect: null
		};
		this.userService = new UserService(props.authService);
		this.callService = new CallService(props.authService);
		this.authService = props.authService;
		this.onCallCreated = props.onCallCreated;
	}

	componentDidMount() {
		this.updateContacts();
	}

	alphaSort(a: string, b: string) {
		if ((a === null || a === undefined) && (b === null || b === undefined)) {
			return 0;
		}
		if (a === null || a === undefined) {
			return -1;
		}
		return a.localeCompare(b);
	}

	teamMemberSort(a: TeamMember, b: TeamMember) {
		if (a.status && !b.status) {
			return -1;
		}
		if (!a.status && b.status) {
			return 1;
		}
		let sortOrder = this.alphaSort(a.lastName, b.lastName);
		if (sortOrder === 0) {
			sortOrder = this.alphaSort(a.firstName, b.firstName);
		}
		return sortOrder;
	}

	async updateContacts() {
		const teams = await this.userService.getTeamsStatuses();
		teams.sort((a, b) => this.alphaSort(a.name, b.name));
		teams.forEach(t => {
			t.existingMembers.sort((a, b) => this.teamMemberSort(a, b));
		});

		this.setState({ teams });
	}

	async createTeamMemberCall(to: TeamMember) {
		let callDetails = await this.props.getCallDetailsAsync();
		let response = await this.callService.createCall({
			recipientUser: to.userId,
			recipientTeam: undefined,
			unitNumber: callDetails.unitNumber,
			agencyId: callDetails.agencyId,
			agencyName: callDetails.agencyName
		});
		this.onCallCreated(response.roomId);
	}

	async createTeamCall(to: Team) {
		let callDetails = await this.props.getCallDetailsAsync();
		let response = await this.callService.createCall({
			recipientUser: undefined,
			recipientTeam: to.id,
			unitNumber: callDetails.unitNumber,
			agencyId: callDetails.agencyId,
			agencyName: callDetails.agencyName
		});
		this.onCallCreated(response.roomId);
	}

	renderContact(contact: TeamMember) {
		return (
			<div className="row justify-content-start contact no-gutters" key={contact.userId}>
				<div className="contact-inner col-md-12">
					<div className="contact-status bg-success">
						<i className="fas fa-check-circle fa-lg"></i>
					</div>
					<div className="contact-name-container">
						<div className="contact-name">{contact.firstName} {contact.lastName}</div>
						<div className="contact-title"></div>
					</div>
					<button type="button" className="btn btn-primary contact-phone" onClick={this.createTeamMemberCall.bind(this, contact)}>
						<i className="fas fa-phone fa-lg"></i>
					</button>
				</div>
			</div>
		);
	}

	renderTeam(team: Team) {
		return (
			<CallTreeTeam createTeamMemberCall={this.createTeamMemberCall.bind(this)} createTeamCall={this.createTeamCall.bind(this)} team={team} ></CallTreeTeam>
		)
	}

	render() {
		var teamContents = this.state.teams.map(this.renderTeam.bind(this));
		return (
			<div>
				<header className="layout-header call-header">
					<nav className="navbar navbar-expand-sm navbar-toggleable-sm bg-secondary border-bottom box-shadow mb-3 navbar navbar-light">
						<div className="container"><a className="navbar-brand" href="/"></a></div>
					</nav>
				</header>
				<div className="container-fluid call-container">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="elite-container bg-white">
								<div>
									<p className="elite-container-header">Available Providers</p>
								</div>
								<div className="elite-container-contents">
									<div>Please select a team or an individual to initiate a call.</div>
									{teamContents}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}