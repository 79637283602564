import { IPermission } from '../IPermission';
import { Claim } from '../Claim';
import { AdminRoleRequiredPermission } from './AdminRoleRequiredPermission'

export class DisplayUsersPermission extends AdminRoleRequiredPermission {
	private _requiredPerm: IPermission | undefined;
	private _checkPermissionGranted = (onGranted: () => void) => super.granted(onGranted);
	private _checkPermissionDenied = (onDenied: () => void) => super.denied(onDenied);

	constructor(userPermGroups: Claim, additionalPermNeeded?: IPermission) {
		super(userPermGroups);

		if (additionalPermNeeded) {
			this._requiredPerm = additionalPermNeeded;

			this._checkPermissionGranted = (onGranted: () => void) => super.granted(() => this._requiredPerm!.granted(() => onGranted()));
			this._checkPermissionDenied = (onDenied: () => void) => super.denied(() => this._requiredPerm!.denied(() => onDenied()));
		}
	}

	granted(onGranted: () => void) { 
		if(!!!onGranted) {
			throw new Error("onGranted was not provided");
		}

		this._checkPermissionGranted(() => onGranted());
	}

	denial(onDenial: () => void ) {
		if(!!!onDenial) {
			throw new Error("onDenial was not provided");
		}

		this._checkPermissionDenied(() => onDenial());
	}
}