import SettingStrings from 'common/settings/SettingStrings';

export class SettingRequestPayload {
	readonly system: string;
	readonly subSystem: string | undefined;
	readonly category: string;
	readonly action: string;
	readonly value: string | boolean;
	readonly name: string;


	constructor(system: string, category: string, action: string, value: string | boolean, name: string, subSystem?: string) {
		this.system = system;
		this.category = category;
		this.action = action;
		this.value = value;
		this.name = name;
		this.subSystem = subSystem;
	}

	delete() {
		return new SettingRequestPayload(this.system, this.category, SettingStrings.deleteAction, this.value, this.name, this.subSystem);
	}
}

