import React, { Component } from 'react';
import { AudioCard } from './AudioCard';
import { ParticipantInfo } from './ParticipantInfo';
import { Publications, PublicationsContext } from './Publications';
import { TrackContext, TrackSelector, selectMainAudioTrack, selectMainVideoTrack } from './TrackSelector';
import { VideoCard } from './VideoCard';
import './ParticipantCard.css';

interface ParticipantCardProps {
	participant: ParticipantInfo
}

// Actual UI for a participant
export class ParticipantCard extends Component<Readonly<ParticipantCardProps>, Readonly<{}>> {

	constructor(props: Readonly<ParticipantCardProps>) {
		super(props);
	}

	render() {
		const p = this.props.participant;
		let participantCss = "participant";
		if (!this.props.participant.isLocalParticipant) {
			participantCss = "participant remote-participant";
		}
		return (
			<div className={participantCss} data-identity={p.identity} id={p.sid} key={p.sid} >
				<Publications participant={p.participant}>
					<PublicationsContext.Consumer>
					{(pubContext) => (
						<React.Fragment>
							<TrackSelector publications={pubContext.publications} trackSelector={selectMainAudioTrack}>
								<TrackContext.Consumer>
								{({track}) => (
									<AudioCard isLocal={p.isLocalParticipant} track={track}></AudioCard>
								)}
								</TrackContext.Consumer>
							</TrackSelector>
							<TrackSelector publications={pubContext.publications} trackSelector={selectMainVideoTrack}>
								<TrackContext.Consumer>
								{({track}) => (
									<VideoCard isLocal={p.isLocalParticipant} track={track} displayName={""} position={""}></VideoCard>
								)}
								</TrackContext.Consumer>
							</TrackSelector>
						</React.Fragment>
      				)}
					</PublicationsContext.Consumer>
				</Publications>
			</div>
		);
	}
}