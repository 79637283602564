export class Claim {
	type: string;
	value: string;

	constructor(type: string, value: string) {
		if(!type) {
			throw new Error("Claim type must be provided.");
		}

		if(!value){
			throw new Error("Claim value must be provided");
		}

		this.type = type;
		this.value = value;
	}
}