import React, { Component } from 'react';
import { ParticipantInfo } from './ParticipantInfo';
import { ParticipantCard } from './ParticipantCard';
import './RoomBody.css';

interface RoomBodyProps {
    participants: ParticipantInfo[]
}

export class RoomBody extends React.Component<Readonly<RoomBodyProps>, Readonly<{}>> {

	constructor(props: Readonly<RoomBodyProps>) {
		super(props);
	}

	render() {
        const participantContents = this.props.participants.map(p => (<ParticipantCard key={p.sid} participant={p}></ParticipantCard>));
		let connectingMessageContents = null;
		if (!this.props.participants.some(p => !p.isLocalParticipant)) {
			// render the connecting message
			connectingMessageContents = (
				<div className="connecting-message-container">
					Waiting for Participant...
				</div>
			);
		}
        return (
            <div className="room-body">
                {participantContents}
                {connectingMessageContents}
            </div>
        );
	}
}