import { NameParts } from "./NameParts";

export class NameSplitter {
	private _namePattern = /^(?<prefix>(?:Mr[sS]{0,1}\.?|Ms\.?|Dr\.?)?)\s?(?<first>[a-zA-Z]{2,})\s+(?:(?<middle>[a-zA-Z]+)\s(?=(?:[^sSrR]{2}|[^jJrR]{2}),?\s*(?:[sSrR]|[jJrR])*)|\s{0,1})(?<last>[a-zA-Z]+),{0,1}\s?(?<suffix>(?:[sSrR]|[jJrR]){2}\.?)?$/;

	split (name: string): NameParts {
		if (!name) {
			throw new Error("name was not provided");
		}

		if(this._namePattern.test(name)) {
			var nameMatch = name.match(this._namePattern);

			//ensure we have required parts, first and last
			if(!nameMatch?.groups?.first || !nameMatch?.groups?.last) {
				return new NameParts(name, name);
			}

			let nameParts = new NameParts(nameMatch.groups.first, nameMatch.groups.last, nameMatch.groups.middle);

			if(nameMatch.groups.prefix) {
				nameParts.addPrefix(nameMatch.groups.prefix);
			}

			if(nameMatch.groups.suffix) {
				nameParts.addSuffix(nameMatch.groups.suffix);
			}

			return nameParts;
		}

		return new NameParts(name, name);
	}
}