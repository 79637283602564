import React from 'react';
import authService from './AuthorizeService';
import { UserManagerContext } from './UserManagerContext'

export const defaultAuthContextProvider = {
	provider: authService,
	initialize: async () => await authService.ensureUserManagerInitialized(),
	onAuthenticationChange: (callback: () => void) => authService.subscribe(() => callback()),
	cancelAuthenticationChange: (withSubscriptionId: number) => authService.unsubscribe(withSubscriptionId),
	isAuthenticated: async () => await authService.isAuthenticated(),
	user: async () => await authService.getUser()
}

export const AuthContext = React.createContext<UserManagerContext>(defaultAuthContextProvider);
