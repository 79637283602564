import React, { HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { Column, Cell, HeaderGroup, Row, useSortBy, useTable, RowPropGetter, PropGetter, TablePropGetter } from 'react-table';
import { Table } from 'reactstrap';

import 'components/Common/ImageTrendTable.scss';

class ImageTrendTableProps<T extends {}> {
	renderableData: T[] = [];
	headers: Column<T>[] = [];
	searchable?: boolean = false;
	filterable?: boolean = false;
	rowProps?: (row: T) => RowPropGetter<T>;
	title?: string;
}

export const ImageTrendTable = <T extends {}>({ renderableData, 
	headers, 
	searchable, 
	filterable,
	rowProps = (row => ({})),
	title,
	className
	}: ImageTrendTableProps<T> & HTMLAttributes<T>) => {
	
	const tableColumns = useMemo(() => headers, [headers]);
	const tableData = useMemo(() => renderableData, [renderableData]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({ data: tableData, columns: tableColumns}, useSortBy);

	const renderHeaderCell = (hg: HeaderGroup<T>) => {
		hg.getSortByToggleProps()
		return (
			<th {...hg.getHeaderProps(hg.getSortByToggleProps())}>{hg.render('Header')}
			<span>
                    {hg.isSorted
                      ? hg.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
			</th>
		)
	};

	const renderHeaderGroup = (hg: HeaderGroup<T>) => {
		return (
			<tr {...hg.getHeaderGroupProps()}>{
				hg.headers.map(col => renderHeaderCell(col))}
			</tr>
		)
	};

	const renderBodyCell = (cell: Cell<T>) => {
		return (
			<td {...cell.getCellProps()}>{
				cell.render('Cell')
			}
			</td>
		)
	};

	const renderBodyRow = (row: Row<T>) => {
		return (
			<tr {...row.getRowProps(rowProps(row.original))}>{
				row.cells.map(c => renderBodyCell(c))
			}
			</tr>
		)
	};

	return (
		<Table className={className} striped={true} {...getTableProps({title: title} as TablePropGetter<T>)}>
			<thead>{headerGroups.map(hg => renderHeaderGroup(hg))}</thead>
			<tbody {...getTableBodyProps()}>{
				rows.map(r => {
					prepareRow(r);
					return renderBodyRow(r);
				})}
			</tbody>
		</Table>
	)
}

export class ImageTrendTableHeader<T extends {}> {
	readonly Header: string;
	readonly accessor: string | ((item: T, rowIndex: number, row: Row<T>) => void);
	readonly id?: string;

	constructor(headerText: string, valueAccessor: string | ((item: T, rowIndex: number, row: Row<T>) => void), columnId?: string) {
		this.Header = headerText;
		this.accessor = valueAccessor;

		if(columnId) {
			this.id = columnId;
		}
	}
}