import React, { Component } from 'react';

interface ModalHeaderProps {
	close: () => any,
	title: string
}

export const ModalHeader : React.FunctionComponent<ModalHeaderProps> = props => {
	return (
		<div className="modal-header">
			<h5 className="modal-title">{props.title}</h5>
			<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.close}>
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
	);
}