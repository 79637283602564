import { ApiResponseHandler }  from 'common/ApiResponseHandler';
import UserResponse from 'user/UserResponse';
import { ApiUrl } from 'ApiConstants';
import authService from 'components/ApiAuthorization/AuthorizeService';
import ReinviteRequest from 'user/ReinviteUserRequest';
import ReinviteUserRequest from 'user/ReinviteUserRequest';

export default class UserService {
    async lookupByUsername(username: string) {
        const authToken = await authService.getAccessToken();

        if(!authToken) {
            console.log('User not authenticated. Redirecting to login.');
            
            await authService.signOut({});
        }

        const response = await fetch(`${ApiUrl.UserUrl}/username/${username}`, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            },
            method: 'GET'
        });

       var handler = new ApiResponseHandler<UserResponse, UserResponse>();

       const userResponse = await handler.handle(response, 
                                                    ur => ur, 
                                                    () => new UserResponse()); 

        let user = new UserResponse();

        userResponse.successful(ur => user = ur);

        return user;
   }

   async invite(username: string) {
    const authToken = await authService.getAccessToken();

    if(!authToken) {
        console.log('User not authenticated. Redirecting to login.');
        
        await authService.signOut({});
    }

    let reinviteRequest = new ReinviteRequest(username);

    let userInviteResponse = await fetch(`${ApiUrl.UserUrl}/invite`, {
        headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        },
        method: 'PATCH',
        body: JSON.stringify(reinviteRequest.payload)
    });

    var handler = new ApiResponseHandler<ReinviteUserRequest, {}>();
    //currently the invite API doesn't return anything, so we're just plopping in a dummy object with the original username    
    const inviteResponse = await handler.handle(userInviteResponse, 
        () => reinviteRequest, 
        () => {
            console.error(`Failed to invite ${username}`);
            return reinviteRequest
    });

    return inviteResponse;
   }
}