import { SecurityStrings } from '../ApiConstants';
import { SecurePerson } from '../Person';
import { Claim } from '../security/Claim';
import { Team } from '../Team';

export class OrgUser extends SecurePerson  {
	flatTeams: string = "";
	private _teams: Team[] = []

	constructor(id: string, name: string, email: string, username: string, claims: Claim[])  {
		super(id, name, email, username, claims);
	}

	get teams(): Iterable<Team> {
		return this._teams;
	}

	set teams(teams) {
		this._teams = Array.from(teams);
	}

	onTeam(team: Team) {
		if(!team){
			return;
		}

		if(this._teams.find(t => t.equals(team))){
			return;
		}

		this._teams.push(team)
	}

	whenAdministrator(invoke: () => void) {
		if(!!!invoke) {
			console.error("invoke callback was not provided");
			
			return;
		}

		if(!!!this.flatPermissionGroups) {
			console.error("Permission groups are not assigned");

			return;
		}

		let permissionGroups = this.flatPermissionGroups.split(",");

		if(!!!permissionGroups) {
			 return;
		}

		if(permissionGroups.includes(SecurityStrings.AdminRoleName)) {
			invoke();
		}
	}
}