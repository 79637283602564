import React, { Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { AccessDenied } from './AccessDenied';
import { RenderPermission } from '../security/Permission/RenderPermission';

export interface SecuredProps {
	permission: RenderPermission
}

export interface SecuredComponentProps extends PropsWithChildren<SecuredProps> {
}

export const SecuredComponent = ({permission, children}: SecuredComponentProps) => {
	const [displayComponent, setDisplayComponent] = useState(false);
	const [shouldRender, setShouldRender] = useState(false);

	useEffect(() => {
		setShouldRender(false);
		
		permission.granted(() => setDisplayComponent(true))
		
		permission.shouldRenderOutput(() => setShouldRender(true));
	}, [permission]);

	return (
		<Fragment>
			{ shouldRender
			? !displayComponent 
				? <AccessDenied /> 
				: children 
			: null }
		</Fragment>
	)
}