import { EventEmitter } from 'events';
import { cookieManager } from './common/CookieManager';

export interface DeviceInfo {
	deviceId: string,
	label: string
}

export interface Devices {
	audioDevices: Array<DeviceInfo>,
	videoDevices: Array<DeviceInfo>,
	selectedAudioDevice: DeviceInfo | undefined,
	selectedVideoDevice: DeviceInfo | undefined
}

export enum DeviceManagerEvents {
	SELECTED_CAMERA_CHANGED = "selectedCameraChanged",
    SELECTED_MICROPHONE_CHANGED = "selectedMicrophoneChanged"
}

export class DeviceManager extends EventEmitter {
	private cachedDevices: Devices | null = null;

	getSafeDeviceLabel(device: DeviceInfo) {
		let label = device.label || "";
		return label.toLowerCase();
	}

	guessDefaultVideoDevice(devices: Array<DeviceInfo>) {
		// iOS front camera will have a label of "Front Camera"
		// iOS rear camera will have a label of "Back Camera"
		if (devices.length === 0) {
			return undefined;
		}
		else if (devices.length === 1) {
			return devices[0];
		}
		// try to find a default device
		let defaultDevice = devices.find(v => v.deviceId === 'default');
		if (defaultDevice) {
			return defaultDevice;
		}
		// look for a device with "front" in the label
		defaultDevice = devices.find(x => this.getSafeDeviceLabel(x).indexOf('front') > -1);
		if (defaultDevice) {
			return defaultDevice;
		}
		// look for a device that doesn't have "rear" or "back" or " ir " in the label
		let maybeFrontDevices = devices.filter(x => {
			let label = this.getSafeDeviceLabel(x);
			if (label.indexOf('rear') > -1 || label.indexOf('back') > -1) {
				return false;
			}
			// also remove any infrared cameras
			if (label.indexOf(' ir ') > -1) {
				return false;
			}
			return true;
		});
		if (maybeFrontDevices.length > 0) {
			return maybeFrontDevices[0];
		}
		// just pick the first one at this point
		return devices[0];
	}

	async getDefaultVideoDevice(devices: Array<DeviceInfo>) {
		// read from cookie first
		let selectedVideoDeviceId = cookieManager.getSelectedCamera();
		if (selectedVideoDeviceId) {
			let maybeSelectedVideoDevices = devices.filter(d => d.deviceId === selectedVideoDeviceId);
			if (maybeSelectedVideoDevices.length > 0) {
				return maybeSelectedVideoDevices[0];
			}
		}

		// try to let the browser determine which device is front facing,
		// if it can't or if the device does not have a front facing camera we
		// will determine this ourselves.
		let frontFacingCamera = await this.getFrontFacingCameraFromBrowserAsync(devices);
		if (frontFacingCamera) {
			return frontFacingCamera;
		}
		return this.guessDefaultVideoDevice(devices);
	}

	getDefaultAudioDevice(devices: Array<DeviceInfo>) {
		// read from cookie first
		let selectedAudioDeviceId = cookieManager.getSelectedMicrophone();
		if (selectedAudioDeviceId) {
			let maybeSelectedAudioDevices = devices.filter(d => d.deviceId === selectedAudioDeviceId);
			if (maybeSelectedAudioDevices.length > 0) {
				return maybeSelectedAudioDevices[0];
			}
		}

		if (devices.length === 0) {
			return undefined;
		}
		let defaultAudioDevice = devices.find(a => a.deviceId === 'default');
		if (!defaultAudioDevice) {
			defaultAudioDevice = devices[0];
		}
		return defaultAudioDevice;
	}

	async getFrontFacingCameraFromBrowserAsync(devices: Array<DeviceInfo>) {
		let mediaStream: MediaStream;
		try {
			mediaStream = await navigator.mediaDevices.getUserMedia({
				video: {
					facingMode: {
						exact: 'user'
					}
				}
			});
		} catch (ex) {
			// getUserMedia will throw an overconstrained error if the device does not have a front facing camera
			return undefined;
		}
		mediaStream.getTracks().forEach(t => t.stop());
		let videoTracks = mediaStream.getVideoTracks();
		if (videoTracks.length === 0) {
			return undefined;
		}
		let settings = videoTracks[0].getSettings();
		return devices.find(x => x.deviceId === settings.deviceId);
	}

	getCachedDevices(): Devices | null {
		return this.cachedDevices;
	}

	async getDevicesAsync(): Promise<Devices> {
		if (this.cachedDevices) {
			return this.cachedDevices;
		}

		// Call this so we prompt the user for permissions if necessary.
		// This will also start up a media stream, which will turn on the camera light on your device.
		let mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
		// stop all the tracks we get back
		mediaStream.getTracks().forEach(t => t.stop());
		let devices = await navigator.mediaDevices.enumerateDevices();
		let audioDevices = devices.filter(d => d.kind === 'audioinput').map(d => ({ deviceId: d.deviceId, label: d.label }));
		let videoDevices = devices.filter(d => d.kind === 'videoinput').map(d => ({ deviceId: d.deviceId, label: d.label }));
		let i = 1;
		// add labels to any that don't have labels
		audioDevices.forEach(d => {
			if (!d.label) {
				d.label = `Unknown audio #${i}`;
			}
		});
		i = 1;
		videoDevices.forEach(d => {
			if (!d.label) {
				d.label = `Unknown video #${i}`;
			}
		});
		let selectedAudioDevice = this.getDefaultAudioDevice(audioDevices);
		let selectedVideoDevice = await this.getDefaultVideoDevice(videoDevices);

		this.cachedDevices = {
			audioDevices,
			videoDevices,
			selectedAudioDevice,
			selectedVideoDevice
		}
		return this.cachedDevices;
	}

	setCamera(camera: DeviceInfo) {
		if (!this.cachedDevices?.videoDevices.some(v => v.deviceId === camera.deviceId)) {
			throw new Error('Cannot select unknown camera.');
		}
		this.cachedDevices.selectedVideoDevice = camera;
		cookieManager.setSelectedCamera(camera.deviceId);
		this.emit(DeviceManagerEvents.SELECTED_CAMERA_CHANGED, camera.deviceId);
	}

	setMicrophone(microphone: DeviceInfo) {
		if (!this.cachedDevices?.audioDevices.some(a => a.deviceId === microphone.deviceId)) {
			throw new Error('Cannot select unknown microphone.');
		}
		this.cachedDevices.selectedAudioDevice = microphone;
		cookieManager.setSelectedMicrophone(microphone.deviceId);
		this.emit(DeviceManagerEvents.SELECTED_MICROPHONE_CHANGED, microphone.deviceId);
	}
}

export var deviceManager = new DeviceManager();