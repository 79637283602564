import React from "react";
import { CardBody, CardTitle, Form, FormGroup, Input, Label } from "reactstrap";
import { UserDemographics } from "../../user/UserDemographics";
import { PrefixSelectInput } from "../../components/Common/PrefixSelectInput";
import { SuffixSelector } from "../../components/Common/SuffixSelector";
import {
  DemographicPrefix,
  MrDemographicPrefix,
  MrsDemographicPrefix,
  MsDemographicPrefix,
  DrDemographicPrefix,
} from "../Common/DemographicPrefix";
import { DemographicSuffix } from "../Common/DemographicSuffix";

interface UserDemoGraphicsCardProps {
  demographics: UserDemographics;
  editMode: boolean;
  onPrefixSelected: (selectedPrefix?: DemographicPrefix) => void;
  onSuffixSelected: (selectedSuffix: DemographicSuffix) => void;
  onFirstNameChange: (newFirstName: string) => void;
  onMiddleNameChange: (newMiddleName: string) => void;
  onLastNameChange: (newLastName: string) => void;
  onEmailAddressChange: (newEmailAddress: string) => void;
  onPhoneNumberChange: (newPhoneNumber: string) => void;
}

export const UserDemographicsCard = ({
  demographics,
  onPrefixSelected,
  onSuffixSelected,
  onFirstNameChange,
  onMiddleNameChange,
  onLastNameChange,
  onEmailAddressChange,
  onPhoneNumberChange,
}: UserDemoGraphicsCardProps) => {
  const prefixOptions = [
    DemographicPrefix.empty(),
    new MrDemographicPrefix(),
    new MrsDemographicPrefix(),
    new MsDemographicPrefix(),
    new DrDemographicPrefix(),
  ] as DemographicPrefix[];

  return (
    <>
      <CardBody>
        <CardTitle tag="h5">Demographics</CardTitle>
        <Form>
          <div className="form-row">
            <FormGroup className="col-md-1">
              <PrefixSelectInput
                options={prefixOptions}
                onSelected={(chosen) => onPrefixSelected(chosen)}
                optionText={(option) => option?.descriptor ?? "Please Select"}
                selected={demographics?.prefix}
              />
            </FormGroup>
            <FormGroup className="col-md-4">
              <Label for="firstName">First Name (required)</Label>
              <Input
                id="firstName"
                type="text"
                name="firstName"
                value={demographics?.firstName}
                onChange={(event) => onFirstNameChange(event.target.value)}
              ></Input>
            </FormGroup>
            <FormGroup className="col-md-2">
              <Label for="middleName">Middle Name</Label>
              <Input
                id="middleName"
                type="text"
                name="middleName"
                value={demographics?.middleName}
                onChange={(event) => onMiddleNameChange(event.target.value)}
              ></Input>
            </FormGroup>
            <FormGroup className="col-md-4">
              <Label for="lastName">Last Name (required)</Label>
              <Input
                id="lastName"
                type="text"
                name="lastName"
                value={demographics?.lastName}
                onChange={(event) => onLastNameChange(event.target.value)}
              ></Input>
            </FormGroup>
            <FormGroup className="col-md-1">
              <SuffixSelector
                onSuffixSelected={(selectedSuffix) => onSuffixSelected(selectedSuffix!)}
                selected={demographics?.suffix}
              />
            </FormGroup>
          </div>
          <div className="form-row">
            <FormGroup className="col-md-5">
              <Label for="email">Email Address (required)</Label>
              <Input
                id="email"
                type="text"
                name="email"
                value={demographics?.emailAddress}
                onChange={(event) => onEmailAddressChange(event.target.value)}
              ></Input>
            </FormGroup>
            <FormGroup className="col-md-2">
              <Label for="phoneNumber">Phone Number</Label>
              <Input
                id="phoneNumber"
                type="text"
                name="phoneNumber"
                value={demographics?.phoneNumber}
                onChange={(event) => onPhoneNumberChange(event.target.value)}
              ></Input>
            </FormGroup>
          </div>
        </Form>
        <hr></hr>
      </CardBody>
    </>
  );
};
