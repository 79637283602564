import React, { Component } from 'react';
import { AudioManager, AudioManagerEvents } from './AudioManager';

export interface ToggleAudioButtonProps {
	audioManager: AudioManager | null
}

interface ToggleAudioButtonState {
	isMuted: boolean
}

export class ToggleAudioButton extends React.Component<Readonly<ToggleAudioButtonProps>, Readonly<ToggleAudioButtonState>> {

	constructor(props: Readonly<ToggleAudioButtonProps>) {
		super(props);
		let isMuted = false;
		if (props.audioManager) {
			isMuted = props.audioManager.isMuted();
			props.audioManager.on(AudioManagerEvents.AUDIO_MUTED, this.updateMuteStatus);
		}
		this.state = {
			isMuted: false
		};
	}

	componentWillUnmount() {
		if (this.props.audioManager) {
			this.props.audioManager.off(AudioManagerEvents.AUDIO_MUTED, this.updateMuteStatus);
		}
	}

	updateMuteStatus = (isMuted: boolean) => {
		this.setState({
			isMuted
		});
	}

	mute = () => {
		if (this.props.audioManager) {
			this.props.audioManager.mute();
		}
	}

	unmute = () => {
		if (this.props.audioManager) {
			this.props.audioManager.unmute();
		}
	}

	render() {
		if (this.state.isMuted) {
			return (
				<button type="button" className="btn" onClick={this.unmute}>
					<i className="fas fa-microphone-slash fa-fw"></i>
				</button>
			);
		}
		return (
			<button type="button" className="btn" onClick={this.mute}>
				<i className="fas fa-microphone fa-fw"></i>
			</button>
		);
	}
}