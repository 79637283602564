import Message from 'common/Message';
import CallNotificationPreferenceChange from 'common/messaging/CallNotificationPreferenceChange';
import UserDeclinedCall from 'common/messaging/UserDeclinedCall';
import { UserModifySuccessMessage, UserModifyFailMessage } from 'common/messaging/UserModificationMessage';

export enum MessageId {
    userCallAudioNotificationEnabled = 'setting-call-notification-user-action-modify',
    userCallDecline = 'user-call-decline',
    userModifySuccess = 'user-modify-success',
    userModifyFail = 'user-modify-fail',
    voidMessage = 'void-message'
}

export class Messages {
    static callAudioAlertChange() {
        return new CallNotificationPreferenceChange();
    }

    static userDeclinedCall() {
        return new UserDeclinedCall();
    }

    static userModifySuccess() {
        return new UserModifySuccessMessage()
    }

    static userModifyFail() {
        return new UserModifyFailMessage();
    }
}

export class MessageSubscription {
    private readonly subscriptionId: number = 0;
    private readonly message: Message;
    private readonly onMessageReceived: Function
    
    constructor(subscriptionId: number, subscribeTo: Message, onMessageReceived: Function) {
        this.subscriptionId = subscriptionId;
        this.message = subscribeTo;
        this.onMessageReceived = onMessageReceived; 
    }

    get id() {
        return this.subscriptionId
    }

    get subscribedTo() {
        return this.message;
    }

    get onMessage() {
        return this.onMessageReceived;
    }
}

export class MessagePublisher {
    private _subscriptions: MessageSubscription[] = []
    private _currentSubId = 0;
    
    public publish<T>(message: Message, value: T) {
        for(const subscription of this._subscriptions) {
            if(subscription.subscribedTo.equals(message)) {
                subscription.onMessage(value);
            }
        }
    }

    public unsubscribeFrom(subscription: MessageSubscription) {
        for(let i = 0; i < this._subscriptions.length; i++) {
            const sub = this._subscriptions[i];

            if(sub.subscribedTo.equals(subscription.subscribedTo)) {
                this.removeSubscription(i);
            }
        }
    }

    public subscribe(toMessage: Message, withCallback: Function): MessageSubscription {
        const sub = new MessageSubscription(++this._currentSubId, toMessage, withCallback);

        this._subscriptions.push(sub);

        return sub;
    }

    /*****************
     * This method exists primarily to work around how React tracks items in the context API, which is by reference.
     * This is called from the App component after a new message is subscribed to. This causes a new reference,
     * which then triggers the context API to update all subscribed components.
     */
    public clone(): MessagePublisher {
        let clonedPublisher = new MessagePublisher();

        clonedPublisher._currentSubId = this._currentSubId;

        for(let sub of this._subscriptions) {
            clonedPublisher._subscriptions.push(sub);
        }
        
        return clonedPublisher;
    }

    private removeSubscription(index: number) {
        this._subscriptions = this._subscriptions.splice(index, 1);
    }
}

let instance = new MessagePublisher();

export default instance;