import { IPermission } from '../IPermission';
import { SecurityStrings } from '../../ApiConstants';
import { Claim } from '../Claim';

export class AdminRoleRequiredPermission implements IPermission {
	private _allowedPermissionGroups: string[] = [SecurityStrings.AdminRoleName]
	private _userPermGroupsClaim: Claim;

	constructor(userPermGroups: Claim) {
		if (!!!userPermGroups) {
			throw new Error('userPermsGroup claim was not provided.');
		}

		this._userPermGroupsClaim = userPermGroups;
	}

	granted(onGranted: () => void) {
		if(!!!onGranted){
			throw new Error("onGranted was not provided");
		}

		var allowedPermGroupIndex = this._allowedPermissionGroups.indexOf(this._userPermGroupsClaim.value);

		if (allowedPermGroupIndex > - 1) {
			onGranted();
		}
	}

	async grantedAsync(onGrantedAsync: () => Promise<void>) {
		let granted = false;
		this.granted(() => granted = true);

		if(granted) {
			await onGrantedAsync();
		}
	}

	denied(onDenial: () => void){
		if(!!!onDenial){
			throw new Error("onDenial was not provided");
		}

		var allowedPermGroupIndex = this._allowedPermissionGroups.indexOf(this._userPermGroupsClaim.value);

		if(allowedPermGroupIndex < 0) {
			onDenial();
		}
	}

	async deniedAsync(onDenialAsync: () => Promise<void>) {
		if(!onDenialAsync) {
			throw new Error("onDenialAsync callback was not provided");
		}

		let denied = false;
		this.denied(() => denied = true);

		if(denied) {
			await onDenialAsync();
		}
	}
}