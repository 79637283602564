import { Participant } from "twilio-video";

export class ParticipantInfo {
	identity: string = "";
	sid: string = "";
	isLocalParticipant: boolean = false;
	participant: Participant;

	constructor(identity: string, sid: string, isLocalParticipant: boolean, participant: Participant) {
		this.identity = identity;
		this.sid = sid;
		this.isLocalParticipant = isLocalParticipant;
		this.participant = participant;
	}
}