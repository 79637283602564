import React, { useEffect, useState, useRef, PropsWithChildren } from 'react';
import { Claims } from 'security/Claims';
import { SecuredPersonService } from '../../services/SecuredPersonService';
import { SecuredComponent } from '../SecuredComponent';
import TelehealthPortalPermission from '../../security/Permission/TelehealthPortalPermission';
import { NotGranted } from '../../security/Permission/NotGranted';
import { RenderPermission } from '../../security/Permission/RenderPermission';
import useAuthContext from './useAuthContext';

export interface RequirePortalAccountProps extends PropsWithChildren<{}> {
    personService: SecuredPersonService
}

/****************************************
 * Represents a component which requires 
 * the user to have the "telehealth" 
 * account claim. 
 ***************************************/
const TelehealthAccountSecuredComponent = ({children, personService}: RequirePortalAccountProps) => {
    const [renderPermission, setRenderPermission] = useState<RenderPermission>(new RenderPermission(new NotGranted()));
    const authContext = useAuthContext();
    const initialized = useRef(false);

    useEffect(() => {
        const lookupUser = async () => {
            const authed = await authContext.isAuthenticated();
            
            if(authed) {
                renderPermission.deniedAsync(async () => {
                    const user = await personService.lookupLoggedInUser();
                        let perm = new RenderPermission(new TelehealthPortalPermission(new Claims(user.claims))).enableRendering();
                        setRenderPermission(perm);
                });                                    
            } else {
                renderPermission.granted(() => {
                    let perm = new RenderPermission(new NotGranted()).enableRendering(); 
                    setRenderPermission(perm);
                });
            }
        }
        
        if(!initialized.current) {
            initialized.current = true;
            lookupUser();
        }
        
    }, [authContext, renderPermission, personService, authContext.isAuthenticated]);
 
    return (
        <SecuredComponent permission={renderPermission}>
            {children}
        </SecuredComponent>
    )
}

export default TelehealthAccountSecuredComponent;