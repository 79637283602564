import { DemographicPrefix } from "components/Common/DemographicPrefix";
import { DemographicSuffix } from "components/Common/DemographicSuffix";
import { Messages } from "components/Common/Messages";
import { UserAccountCard } from "components/user/UserAccountCard";
import { UserDemographicsCard } from "components/user/UserDemographicsCard";
import { UserPermissionsCard } from "components/user/UserPermissionsCard";
import { Organization } from "Organization";
import OrganizationService from "organization/OrganizationService";
import { OrgUser } from "organization/OrgUser";
import { UpdateOrganizationRequest } from "organization/UpdateOrganizationRequest";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { IPermission } from "security/IPermission";
import { AdminRoleRequiredPermission } from "security/Permission/AdminRoleRequiredPermission";
import { RoleRetriever } from "security/RoleRetriever";
import { SecuredPersonService } from "services/SecuredPersonService";
import { Team } from "Team";
import { ChangeUserRequestBuilder } from "user/ChangeUserRequestBuilder";
import { UserDemographics } from "user/UserDemographics";
import MessagingContext from "common/messaging/MessagingContext";
import { MessageSubscription, Messages as MessageFactory } from "common/Messaging";
import Message from "common/Message";

interface EditUserProps {
  userId?: string;
  userOrg: Organization;
  editing: boolean;
  onCancel: () => void;
  onUserChanged: (user: OrgUser) => void;
}

export const EditUser = ({ userId = "", userOrg, editing, onCancel, onUserChanged }: EditUserProps) => {
  const [userDisplayName, setUserDisplayName] = useState<string>();
  const [user, setOrgUser] = useState<OrgUser>();

  //demographic fields
  const [userDemographics, setUserDemographics] = useState<UserDemographics>();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [namePrefix, setNamePrefix] = useState<string>("");
  const [nameSuffix, setNameSuffix] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  //account fields
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
  const [userActive, setUserActive] = useState<boolean>(true);
  const [passwordResetRequired, setPasswordReset] = useState<boolean>();
  const [showUsername, setShowUsername] = useState<boolean>(true);

  //security fields
  const [assignedTeams, setAssignedTeams] = useState<Iterable<Team>>([]);
  const [availableTeams, setAvailableTeams] = useState<Iterable<Team>>(userOrg.teams);
  const [permGroup, setAssignedPermGroup] = useState<string>("Please Select");

  let rBuilder = new ChangeUserRequestBuilder().activateUser();

  const [requestBuilder, setRequestBuilder] = useState(rBuilder);

  const [errors, setErrors] = useState<string[]>([]);
  const [successMessages, setSuccessMessages] = useState<string[]>([]);

  const [userEditPermission, setEditUserPermission] = useState<IPermission>();

  const messagingSubs = useRef<MessageSubscription[]>([]);

  const messagingContext = useContext(MessagingContext);

  const tryRemoveSubscription = useCallback(
    (messagingSub: MessageSubscription, fromMessage: Message) => {
      if (!messagingSub) {
        return;
      }

      let subRemovedIndex = -1;

      if (messagingSub.subscribedTo.equals(fromMessage)) {
        messagingContext.unsubscribeFrom(messagingSub);
        subRemovedIndex = messagingSubs.current.findIndex((sub) => messagingSub.id === sub.id);
      }

      return subRemovedIndex;
    },
    [messagingContext]
  );

  useEffect(() => {
    const setEditPermission = async () => {
      let roleRetrieval = new RoleRetriever(new SecuredPersonService());
      let role = await roleRetrieval.ofLoggedInUser();

      setEditUserPermission(new AdminRoleRequiredPermission(role));
    };

    setEditPermission();

    return () => {
      for (let messageSub of messagingSubs.current) {
        messagingContext.unsubscribeFrom(messageSub);
      }

      messagingSubs.current = [];
    };
  }, [messagingContext]);

  useEffect(() => {
    //Listen for error messages from our sub-components
    const listenForFailureMessages = () => {
      let errorMsgSub = messagingContext.subscribeTo(MessageFactory.userModifyFail(), (errorMsg: string) => {
        const errorMsgIndex = errors.findIndex((err) => err === errorMsg);

        if (errorMsgIndex > -1) {
          //we already have this message, don't add it again
          return;
        }

        setErrors([...errors, errorMsg]);
      });

      messagingSubs.current.push(errorMsgSub);
    };

    listenForFailureMessages();

    return () => {
      //clean up our error message subscriptions
      let removeSubsIndexes: number[] = [];

      messagingSubs.current.forEach((messagingSub, index) => {
        let removalIndex = tryRemoveSubscription(messagingSub, MessageFactory.userModifyFail());

        if (removalIndex && removalIndex > -1) {
          removeSubsIndexes.push(removalIndex);
        }
      });

      removeSubsIndexes.forEach((subIndex) => messagingSubs.current.splice(subIndex));
    };
  }, [errors, messagingContext, tryRemoveSubscription]);

  useEffect(() => {
    //listen for success messages from our sub-components
    const listenForSuccessMessages = () => {
      let successMsgSub = messagingContext.subscribeTo(MessageFactory.userModifySuccess(), (successMsg: string) => {
        const successMsgIndex = successMessages.findIndex((s) => s === successMsg);

        if (successMsgIndex > -1) {
          //we already have this message, don't add it again
          return;
        }

        setSuccessMessages([...successMessages, successMsg]);
      });

      messagingSubs.current.push(successMsgSub);
    };

    listenForSuccessMessages();

    return () => {
      //clean up our success message subscriptions
      let removeSubsIndexes: number[] = [];

      messagingSubs.current.forEach((messagingSub) => {
        let removalIndex = tryRemoveSubscription(messagingSub, MessageFactory.userModifySuccess());

        if (removalIndex && removalIndex > -1) {
          removeSubsIndexes.push(removalIndex);
        }
      });

      removeSubsIndexes.forEach((subIndex) => messagingSubs.current.splice(subIndex));
    };
  }, [successMessages, messagingContext, tryRemoveSubscription]);

  useEffect(() => {
    const retrieveUser = async () => {
      if (!userOrg || !userId) {
        return;
      }

      const orgUser = userOrg.findUserWithId(userId);

      if (orgUser) {
        setUserDisplayName(orgUser.displayName());
        setOrgUser(orgUser);

        setFirstName(orgUser.firstName);
        setLastName(orgUser.lastName);

        if (orgUser.middleName) {
          setMiddleName(orgUser.middleName);
        }

        if (orgUser.namePrefix) {
          setNamePrefix(orgUser.namePrefix);
        }

        if (orgUser.nameSuffix) {
          setNameSuffix(orgUser.nameSuffix);
        }

        setEmail(orgUser.email);

        orgUser.reachableByPhoneAt((phoneNumber) => setPhoneNumber(phoneNumber));

        setUserActive(false);
        orgUser.loginAllowed(() => setUserActive(true));

        setUsername(orgUser.username);

        setAssignedPermGroup(orgUser.flatPermissionGroups);
        let assignedTeams = Array.from(orgUser.teams);

        setAvailableTeams(
          userOrg.teams.filter((orgTeam) => !!!assignedTeams.some((userTeam) => userTeam.id === orgTeam.id))
        );
        setAssignedTeams(assignedTeams);

        setRequestBuilder(ChangeUserRequestBuilder.initializeFromUser(orgUser));
      }
    };

    if (editing) {
      setShowUsername(true);
      retrieveUser();
    }
  }, [userId, editing, userOrg]);

  useEffect(() => {
    var demographics = new UserDemographics();

    demographics.firstName = firstName ?? "";
    demographics.middleName = middleName ?? "";
    demographics.lastName = lastName ?? "";
    demographics.emailAddress = email ?? "";
    demographics.phoneNumber = phoneNumber ?? "";
    demographics.prefix = namePrefix ?? "";
    demographics.suffix = nameSuffix ?? "";

    setUserDemographics(demographics);
  }, [firstName, middleName, lastName, namePrefix, nameSuffix, email, phoneNumber]);

  const onFirstNameChange = (newFirstName: string) => {
    setFirstName(newFirstName);
  };

  const onMiddleNameChange = (newMiddleName: string) => {
    setMiddleName(newMiddleName);
  };

  const onLastNameChange = (newLastName: string) => {
    setLastName(newLastName);
  };

  const onEmailAddressChange = (newEmailAddress: string) => {
    setEmail(newEmailAddress);
    setRequestBuilder(requestBuilder.userContactedAt(newEmailAddress));
  };

  const onPrefixChange = (selectedPrefix?: DemographicPrefix) => {
    if (!!!selectedPrefix) {
      setNamePrefix(DemographicPrefix.empty().descriptor);
      setRequestBuilder(requestBuilder.removePrefix());

      return;
    }

    if (selectedPrefix === DemographicPrefix.empty()) {
      setRequestBuilder(requestBuilder.removePrefix());
    } else {
      setRequestBuilder(requestBuilder.namePrefixed(selectedPrefix!.descriptor));
    }

    setNamePrefix(selectedPrefix!.descriptor);
  };

  const onSuffixChange = (selectedSuffix?: DemographicSuffix) => {
    if (!!!selectedSuffix) {
      setNameSuffix(DemographicSuffix.empty().descriptor);
      setRequestBuilder(requestBuilder.removeSuffix());

      return;
    }

    if (selectedSuffix === DemographicSuffix.empty()) {
      setNameSuffix(DemographicSuffix.empty().descriptor);
      setRequestBuilder(requestBuilder.removeSuffix());
    } else {
      setRequestBuilder(requestBuilder.nameSuffixed(selectedSuffix?.descriptor));
    }

    setNameSuffix(selectedSuffix?.descriptor);
  };

  const onPhoneNumberChange = (newPhoneNumber: string) => {
    setPhoneNumber(newPhoneNumber);
    setRequestBuilder(requestBuilder.userReachedAt(newPhoneNumber));
  };

  const onUsernameChange = (newUsername: string) => {
    setUsername(newUsername);
    setRequestBuilder(requestBuilder.logsInWith(newUsername));
  };

  const onPasswordChange = (newPassword?: string) => {
    setPassword(newPassword ?? "");
    setRequestBuilder(requestBuilder.securesAccount(newPassword));
  };

  const onPasswordConfirmationChange = (confirmedPassword?: string) => {
    setPasswordConfirmation(confirmedPassword ?? "");
    setRequestBuilder(requestBuilder.passwordMatches(confirmedPassword));
  };

  const onTeamAssignmentsChange = (teams: Iterable<Team>) => {
    setAssignedTeams(teams);
  };

  const onTeamsAvailableChange = (teams: Iterable<Team>) => {
    setAvailableTeams(teams);
  };

  const onPermissionGroupAssigned = (permGroup: string) => {
    setAssignedPermGroup(permGroup);
    setRequestBuilder(requestBuilder.inSecurityGroup(permGroup));
  };

  const configureEmailAsUsername = () => {
    setShowUsername(false);
    setRequestBuilder(requestBuilder.useEmailUsername());
  };

  const requireUsername = () => {
    setShowUsername(true);
    setRequestBuilder(requestBuilder.requireUsername());
  };

  const activateUser = () => {
    setUserActive(true);
    setRequestBuilder(requestBuilder.activateUser());
  };

  const deactivateUser = () => {
    setUserActive(false);
    setRequestBuilder(requestBuilder.deactivateUser());
  };

  const onPasswordResetToggle = (required: boolean) => {
    if (required) {
      setPasswordReset(true);
      setRequestBuilder(requestBuilder.requirePasswordReset());
    } else {
      setPasswordReset(false);
      setRequestBuilder(requestBuilder.passwordResetNotNeeded());
    }
  };

  const modifyUser = async (onSaveComplete: () => void) => {
    if (!userOrg) {
      return;
    }

    if (editing) {
      setRequestBuilder(requestBuilder.withEditingUserConfigured());
    }

    setRequestBuilder(requestBuilder.userHasName(firstName!, lastName!, middleName));
    setRequestBuilder(requestBuilder.onTeams(assignedTeams));

    const request = requestBuilder.build();

    const updateOrgRequest = new UpdateOrganizationRequest(userOrg);

    if (editing) {
      updateOrgRequest.editUser(request);
      updateOrgRequest.editedUser!.id = user!.id;
    } else {
      updateOrgRequest.addNewUser(request);
    }

    const orgService = new OrganizationService();
    const updateResponse = await orgService.updateOrg(updateOrgRequest);

    updateResponse.successful((responseData: Organization) => {
      if (editing) {
        for (let user of responseData.users) {
          if (user.id === request.id) {
            onUserChanged(user);
            break;
          }
        }
      } else {
        var search = request.useEmailForUsername ? request.emailAddress : request.username!;
        //find the updated user and callback with it
        for (let user of responseData.users) {
          if (user.username === search) {
            onUserChanged(user);
            break;
          }
        }
      }

      onSaveComplete();
    });

    updateResponse.failure(() => {
      const addedErrors: string[] = [];

      for (let message of updateResponse.errorMessages()) {
        addedErrors.push(message);
      }

      setErrors([...errors, ...addedErrors]);
    });
  };

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setMiddleName("");
    setNamePrefix("");
    setNameSuffix("");
    setEmail("");
    setPhoneNumber("");

    setShowUsername(true);
    setUsername("");
    setPassword("");
    setPasswordConfirmation("");
    setPasswordReset(undefined);
    setUserActive(true);

    setAssignedPermGroup("");
    setAssignedTeams([]);
    setAvailableTeams(userOrg.teams);

    setRequestBuilder(new ChangeUserRequestBuilder());
  };

  const clearMessages = () => {
    setErrors([]);
    setSuccessMessages([]);
  };

  const saveUser = async () => {
    //this method is called when the Save User button is clicked, so we want to close the form, which is why
    //we pass the onCancel function to modifyUser.
    await modifyUser(() => {
      clearForm();
      clearMessages();
      onCancel();
    });
  };

  const saveUserAndAddMore = async () => {
    //this function is called when the Save and Add Another button is clicked; we don't want to do anything
    //with the display of the form since the user wants to add another user.

    await modifyUser(() => {
      const successMessage = `${firstName} ${lastName} was added`;
      setSuccessMessages([...successMessages, successMessage]);
      clearForm();
    });
  };

  return (
    <>
      <Row className="upper-content">
        <Col md={{ size: 6 }}>
          <h1>{editing ? userDisplayName : "Add New User"}</h1>
        </Col>
        <Col className="pt-2" md={{ size: 6 }}>
          <Button color="green" className="float-right mb-3" type="button" onClick={() => saveUser()}>
            <i className="fas fa-save fa-lg"></i> Save User
          </Button>
          {!!!editing ? (
            <Button color="orange" type="button" className="float-right mr-3" onClick={() => saveUserAndAddMore()}>
              <i className="fas fa-plus fa-lg"></i> Save and Add Another
            </Button>
          ) : null}
          <Button color="white" className="float-right mr-3" type="button" onClick={() => onCancel()}>
            <i className="fas fa-times fa-lg"></i>
            Cancel
          </Button>
        </Col>
      </Row>
      <div className="lower-content">
        <Row>
          <Col className="mt-4 mx-auto" xs={{ size: 10 }} md={{ size: 6 }}>
            {
              <Messages
                successDismissible={true}
                errorsDismissible={true}
                errorMessages={errors}
                successMessages={successMessages}
              />
            }
          </Col>
          <Col md={12}>
            <UserDemographicsCard
              demographics={userDemographics ?? UserDemographics.noDemographics()}
              editMode={editing}
              onPrefixSelected={(prefixChosen) => onPrefixChange(prefixChosen)}
              onSuffixSelected={(suffixChosen) => onSuffixChange(suffixChosen)}
              onFirstNameChange={(firstName) => onFirstNameChange(firstName)}
              onMiddleNameChange={(middleName) => onMiddleNameChange(middleName)}
              onLastNameChange={(lastName) => onLastNameChange(lastName)}
              onEmailAddressChange={(emailAddress) => onEmailAddressChange(emailAddress)}
              onPhoneNumberChange={(phoneNumber) => onPhoneNumberChange(phoneNumber)}
            />
          </Col>
        </Row>
        <Row>
          <Col size={12}>
            <UserAccountCard
              onPasswordChange={(password) => onPasswordChange(password)}
              onPasswordConfirmationChange={(passwordConfirmation) =>
                onPasswordConfirmationChange(passwordConfirmation)
              }
              onUsernameChange={(username) => onUsernameChange(username)}
              showUsernameField={showUsername}
              editMode={editing}
              notifyUsernameOptional={() => configureEmailAsUsername()}
              notifyUsernameNeeded={() => requireUsername()}
              notifyUserActive={() => activateUser()}
              notifyUserInactive={() => deactivateUser()}
              username={username}
              userActive={userActive}
              password={password}
              passwordConfirmation={passwordConfirmation}
              resetRequired={passwordResetRequired ?? false}
              notifyPasswordReset={(resetNeeded) => onPasswordResetToggle(resetNeeded)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }}>
            <UserPermissionsCard
              permissionGroup={permGroup}
              organizationTeams={userOrg?.teams}
              assignedOrganizationTeams={assignedTeams}
              availableTeams={availableTeams}
              editMode={editing}
              onTeamsAssigned={(teams) => onTeamAssignmentsChange(teams)}
              onPermissionGroupChosen={(permissionGroup) => onPermissionGroupAssigned(permissionGroup)}
              onTeamsAvailableChange={(availableTeams) => onTeamsAvailableChange(availableTeams)}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
