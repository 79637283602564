import { Claim } from './Claim';

export class Claims {
	private _claims: Iterable<Claim>;

	constructor(claims: Iterable<Claim>) {
		if(!!!claims) {
			throw new Error("claims were not provided");
		}

		this._claims = claims;
	}

	findClaimsByType(ofType: string): Claim | undefined {
		let foundClaim = undefined;

		for(let claim of this._claims) {
			if(claim.type === ofType) {
				foundClaim = claim;
				break;
			}
		}
		
		return foundClaim;
	}
}