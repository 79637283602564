import React, { PropsWithChildren }  from 'react';
import { AuthContext, defaultAuthContextProvider } from './AuthContext';

const AuthContextProvider = ({children}: PropsWithChildren<{}>) => {
    return (
        <AuthContext.Provider value={defaultAuthContextProvider}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;