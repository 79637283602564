import React, { useEffect, useState } from "react";
import { Slushbox } from "../Common/Slushbox";
import { Person } from "../../Person";
import { Team, TeamStatus } from "../../Team";
import { UpdateOrganizationRequest } from "../../organization/UpdateOrganizationRequest";
import { UpdateTeamRequest } from "../../organization/UpdateTeamRequest";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Organization } from "../../Organization";
import OrganizationService from "../../organization/OrganizationService";

interface EditTeamProps {
  teamEditing?: Team;
  org: Organization;
  onUpdated: (team: Team) => void;
  onExit: () => void;
}

export const EditTeam = ({ teamEditing, org, onUpdated, onExit }: EditTeamProps) => {
  const [availableMembers, setAvailableMembers] = useState<Person[]>();
  const [assignedMembers, setAssignedMembers] = useState<Person[]>(teamEditing?.members ?? []);

  const orgService = new OrganizationService();

  useEffect(() => {
    let orgUsers = Array.from(org.users ?? []);

    let availableUsers = orgUsers.filter(
      (orgUser) => !!!assignedMembers.some((assigned) => assigned.id === orgUser.id)
    );

    setAvailableMembers(availableUsers);
  }, [org.users, assignedMembers]);

  const findMemberFrom = (option: HTMLOptionElement): Person => {
    let foundUser = undefined;

    for (let orgUser of org.users) {
      let memberId = option.value;

      if (orgUser.id === memberId) {
        foundUser = orgUser;
        break;
      }
    }

    return (foundUser as Person) ?? new Person("0", "Organization User", "orguser@email.com");
  };

  const onMembersAssigned = (members: Person[]) => {
    setAssignedMembers(members);
  };

  const onMembersAvailable = (members: Person[]) => {
    setAvailableMembers(members);
  };

  const onUpdateComplete = (updatedOrg: Organization) => {
    if (teamEditing) {
      let updatedTeam = updatedOrg.teams.find((t) => t.id === teamEditing.id);

      onUpdated(updatedTeam!);
    }
  };

  const onAddComplete = (updatedOrg: Organization, addedTeamName: string) => {
    let newTeam = updatedOrg.teams.find((t) => t.name === addedTeamName);

    onUpdated(newTeam!);
  };

  return (
    <>
      <Formik
        initialValues={{
          name: teamEditing?.name ?? "",
          status: teamEditing?.status,
          assignedUsers: teamEditing?.members ?? [],
        }}
        onSubmit={async (values, options) => {
          let editTeamRequest = new UpdateTeamRequest(values.name);

          if (teamEditing) {
            editTeamRequest.teamId = teamEditing.id;
          }

          if (values.status === TeamStatus.Active) {
            editTeamRequest.activate();
          } else {
            editTeamRequest.deactivate();
          }

          editTeamRequest.assignedUsers = assignedMembers.map((member) => member.id);

          const orgModifying = new Organization(org.id, org.name);
          const modifyOrgRequest = new UpdateOrganizationRequest(orgModifying);

          if (teamEditing) {
            modifyOrgRequest.editTeam(editTeamRequest);
          } else {
            modifyOrgRequest.addNewTeam(editTeamRequest);
          }

          let response = await orgService.updateOrg(modifyOrgRequest);

          //TODO retrieve errors
          response.failure(() => console.log("An error occurred"));

          response.successful((org) => {
            if (teamEditing) {
              onUpdateComplete(org);
            } else {
              onAddComplete(org, values.name);
            }
          });
        }}
      >
        <Form>
          <Row className="upper-content">
            <Col>
              <h1>{teamEditing ? teamEditing.name : "Add Team"}</h1>
            </Col>
            <Col className="pt-2">
              <Button color="green" className="float-right" type="submit">
                <i className="fas fa-save fa-lg"></i> Save Updates
              </Button>
              <Button color="orange" className="float-right mr-3" type="button" onClick={() => onExit()}>
                <i className="fas fa-times fa-lg"></i>
                Cancel
              </Button>
            </Col>
          </Row>
          <div className="lower-content card-body">
            <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Field type="text" className="form-control" name="name" id="name" placeholder="Team Name" />
                  <ErrorMessage name="name"></ErrorMessage>
                </FormGroup>
              </Col>
              {teamEditing ? (
                <Col md={2}>
                  <p>Date Created</p>
                  <p>{teamEditing?.created ?? new Date().toDateString}</p>
                </Col>
              ) : null}
              <Col md={3}>
                <FormGroup tag="fieldset">
                  Status
                  <FormGroup check>
                    <Label check>
                      <Field name="status" type="radio" value="Active" /> Active
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Field name="status" type="radio" value="Inactive" /> Inactive
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <p>Users</p>
                <Slushbox
                  availableItems={availableMembers ?? []}
                  assignedItems={assignedMembers}
                  displayText={(member) => member.name()}
                  mapOptionFrom={(opt) => findMemberFrom(opt)}
                  valueFactory={(u) => u.id.toString()}
                  onAssignedItemsChanged={(assigned) => onMembersAssigned(assigned)}
                  onAvailableItemsChanged={(available) => onMembersAvailable(available)}
                  itemsEqual={(first, second) => first.id === second.id}
                />
              </Col>
            </Row>
          </div>
        </Form>
      </Formik>
    </>
  );
};
