import { CallResponse } from "./CallResponse";
import CallLog from 'callCenter/CallLog';
import { DateTime } from "luxon";
import { CallNotification } from "telehealth-abstractions";

export default class CallMappers {
    callLogFrom(callResponse: CallResponse): CallLog {
        let recipient = callResponse.recipientTeam ?? callResponse.recipient ?? "Unknown Caller";

        let callLog = new CallLog(callResponse.callId,
            callResponse.caller, 
            callResponse.callerAgency, 
            recipient,
            callResponse.dateReceived ? DateTime.fromISO(callResponse.dateReceived) : DateTime.now(), 
            callResponse.callerUnitNumber);

            if(callResponse.answeredBy) {
                callLog.answer(callResponse.answeredBy)
            }

            callLog.inRoom(callResponse.roomId)

        return callLog;
    }

    callLogFromNotification(ofCall: CallNotification, toRecipient?: string) {
        const callLog = new CallLog(ofCall.CallId, 
            ofCall.From ?? 'Unknown Caller',
            ofCall.AgencyName ?? "", 
            toRecipient ?? 'Unknown Recipient', 
            DateTime.now(), 
            ofCall.UnitNumber ? parseInt(ofCall.UnitNumber) : undefined);

            if(ofCall.RoomId) {
                callLog.inRoom(ofCall.RoomId);
            }
            
        return callLog;
    }
}