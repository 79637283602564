import React, { Component } from "react";
import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { AuthChangeCheck } from "../../security/AuthChangeCheck";
import { UserManagerContext } from "../ApiAuthorization/UserManagerContext";

interface AuthorizeNavItemState {
  to: string;
  display: string;
  authenticated: boolean;
}

interface AuthorizeNavItemProps {
  to: string;
  display: string;
}

export class AuthorizeNavItem extends Component<Readonly<AuthorizeNavItemProps>, AuthorizeNavItemState> {
  private _subscription: number | null = null;
  private _mounted = false;
  private _ready = false;
  private _authChangeChecker!: AuthChangeCheck;

  static contextType: typeof AuthContext;
  context!: UserManagerContext;

  constructor(props: Readonly<AuthorizeNavItemProps>) {
    super(props);

    this.state = {
      to: props.to,
      display: props.display,
      authenticated: false,
    };
  }

  async componentDidMount() {
    this._authChangeChecker = new AuthChangeCheck(this.context.provider);
    this._ready = true;
    this._mounted = true;
    this._subscription = this.context.onAuthenticationChange(async () => await this.authenticationChanged());

    await this.populateAuthenticationState();
  }

  // async componentDidUpdate() {
  //   this.checkAuthChanged(() => this.populateAuthenticationState);
  // }

  componentWillUnmount() {
    if (this._subscription) {
      this.context.cancelAuthenticationChange(this._subscription);
      this._subscription = null;
    }

    if (this._mounted) {
      this._mounted = false;
    }

    this._ready = false;
  }

  async populateAuthenticationState() {
    // if (!this._mounted) {
    //   return;
    // }

    this._ready = true;
    this.checkAuthChanged((authStatus) => this.setState({ authenticated: authStatus }));
  }

  async authenticationChanged() {
    this._ready = false;

    this.checkAuthChanged((authStatus) => this.setState({ authenticated: authStatus }));
    await this.populateAuthenticationState();
    this._ready = true;
  }

  private async checkAuthChanged(onAuthChanged: (userAuthenticated: boolean) => void, onNoAuthChange?: () => void) {
    await this._authChangeChecker.checkAuthChanged(this.state.authenticated, onAuthChanged, onNoAuthChange);
  }

  render() {
    const { authenticated, to, display } = this.state;
    if (!this._ready || !authenticated) {
      return null;
    }
    return (
      <NavItem>
        <NavLink tag={Link} to={to}>
          {display}
        </NavLink>
      </NavItem>
    );
  }
}

AuthorizeNavItem.contextType = AuthContext;
