import React, { Component } from 'react';
import { RoomManager } from './RoomManager';

export interface ToggleChatButtonProps {
	roomManager: RoomManager
}

export class ToggleChatButton extends React.Component<Readonly<ToggleChatButtonProps>, Readonly<{}>> {

	constructor(props: Readonly<ToggleChatButtonProps>) {
		super(props);
	}

    showOrHideChat() {
        if (this.props.roomManager.getShowChat()) {
            this.props.roomManager.hideChat();
        } else {
            this.props.roomManager.showChat();
        }
    }

	render() {
        return (
            <button type="button" className="btn" onClick={this.showOrHideChat.bind(this)}>
                <i className="fas fa-comments fa-fw"></i>
            </button>
        );
	}
}