import React, { Component, Fragment } from "react";
import { Route, RouteComponentProps, StaticContext } from "react-router";
import { Login } from "./Login";
import { Logout } from "./Logout";
import { ApplicationPaths, LoginActions, LogoutActions } from "./ApiAuthorizationConstants";

export default class ApiAuthorizationRoutes extends Component<
  RouteComponentProps<{}, StaticContext, { action: string }>
> {
  render() {
    return (
      <Fragment>
        <Route path={ApplicationPaths.Login} render={() => this.loginAction(LoginActions.Login)} />
        <Route path={ApplicationPaths.LoginFailed} render={() => this.loginAction(LoginActions.LoginFailed)} />
        <Route path={ApplicationPaths.LoginCallback} render={() => this.loginAction(LoginActions.LoginCallback)} />
        <Route path={ApplicationPaths.Profile} render={() => this.loginAction(LoginActions.Profile)} />
        <Route path={ApplicationPaths.Register} render={() => this.loginAction(LoginActions.Register)} />
        <Route path={ApplicationPaths.SilentRenewCallback} render={() => this.loginAction(LoginActions.SilentLogin)} />
        <Route path={ApplicationPaths.LogOut} render={() => this.logoutAction(LogoutActions.Logout)} />
        <Route path={ApplicationPaths.LogOutCallback} render={() => this.logoutAction(LogoutActions.LogoutCallback)} />
        <Route path={ApplicationPaths.LoggedOut} render={() => this.logoutAction(LogoutActions.LoggedOut)} />
      </Fragment>
    );
  }

  loginAction(name: LoginActions) {
    return <Login {...this.props} action={name}></Login>;
  }

  logoutAction(name: LogoutActions) {
    return <Logout action={name}></Logout>;
  }
}
