import React from "react";

export interface INavMenuContext {
  navVisible: boolean;
  showNav: () => void;
  hideNav: () => void;
}

const NavMenuContext = React.createContext<INavMenuContext>({
  navVisible: true,
  showNav: () => {},
  hideNav: () => {},
});

export default NavMenuContext;
