import React, { Component } from 'react';
import './LeaveRoomButton.css';

export interface LeaveRoomButtonProps {
	showLeaveRoomModal: () => void
}

export class LeaveRoomButton extends React.Component<Readonly<LeaveRoomButtonProps>, Readonly<{}>> {
	constructor(props: Readonly<LeaveRoomButtonProps>) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<button type="button" className="btn btn-danger btn-circle leave-room-btn" onClick={this.props.showLeaveRoomModal}>
				<i className="fas fa-phone-slash"></i>
				<span className="d-none d-sm-none d-md-inline">&nbsp;&nbsp;Leave Call</span>
			</button>	
		);
	}
}