import React, { useEffect, useState } from 'react';
import { Alert, Col, Row } from 'reactstrap';

import './Messages.scss'

export class MessageProps {
	successMessages: Array<string> = [];
	errorMessages: Array<string> = [];
	successDismissible?: boolean = false;
	errorsDismissible?: boolean = false;
}

export const Messages = ({successMessages, errorMessages, successDismissible, errorsDismissible}: MessageProps) => {
	const [displaySuccess, setShowSuccess] = useState<boolean>(false);
	const [displayErrors, setShowErrors] = useState<boolean>(false);

	useEffect(() => {
		if(successMessages.length > 0) {
			setShowSuccess(true);
		} else {
			setShowSuccess(false)
		}
	}, [successMessages]);

	useEffect(() => {
		if(errorMessages.length > 0) {
			setShowErrors(true);
		} else {
			setShowErrors(false);
		}
	}, [errorMessages])

	const hideSuccessMessages = () => {
		setShowSuccess(false);
	}

	const hideErrorMessages = () => {
		setShowErrors(false);
	}
	
	return <>	
			<Alert color="success" isOpen={displaySuccess} {...(successDismissible ? {toggle: () => hideSuccessMessages()} : {})}>
				<Row>
					<Col>
						<ul>
							{ successMessages.map(message => <li>{message}</li>) }
						</ul>
					</Col>
				</Row>
			</Alert>
			<Alert color="danger" isOpen={displayErrors} {...(errorsDismissible ? {toggle: () => hideErrorMessages()} : {})}>
				<Row>
					<Col xs={{size: 1}} className="mr-0 pr-0">
						<i className="fas fa-exclamation"></i>
					</Col>
					<Col>
						<ul>
							{ errorMessages.map(message => <li>{message}</li>) }
						</ul>
					</Col>
				</Row>
			</Alert>
			</>
}
