import React, { useEffect, useState, Fragment } from 'react';
import { Input, Label } from 'reactstrap';

import './SelectInput.css';

interface SelectInputProps<T> {
	options: Iterable<T>
	controlId: string;
	labelText?: string;
	selected?: string;
	onSelected: (choosenOption?: T) => void;
	optionText: (option: T) => string;
	optionValue: (option: T) => string;
	icon?: string;
}

export const SelectInput = <T,>({ options, controlId, labelText, selected, onSelected, optionText, optionValue, icon }: SelectInputProps<T>) => {
	const [selectOptions, setOptions] = useState([] as JSX.Element[]);
	const [selectedOption, setSelected] = useState<T>();

	useEffect(() => {
		const buildOptions = () => {
			let builtOptions = [];

			for (let option of options) {
				builtOptions.push(option);
			}

			setOptions(builtOptions.map(builtOpt => <option key={optionValue(builtOpt)} value={optionValue(builtOpt)}>{optionText(builtOpt)}</option>));
		}

		buildOptions();
	}, []);

	useEffect(() => {
		onSelected(selectedOption);
	},[selectedOption]);

	const onChange = (o: string) => {
		if(!o){
			setSelected(undefined);
		} else {
			for(let option of options) {
				if(optionValue(option) === o) {
					setSelected(option);
					break;
				}
			}
		}
	}

	let iconFragment = null;
	if (icon) {
		iconFragment = (
			<i className={`fas fa-fw ${icon} select-input-icon`}></i>
		);
	}

	return (
		<Fragment>
			<Label className="text-muted" for={controlId}>{ labelText }</Label>
			<div className="select-input-icon-container">
				{iconFragment}
			</div>
			<Input type="select" id={controlId} value={selected} onChange={event => onChange(event.target.value)} role="option" className="select-input">
				{selectOptions}
			</Input>
		</Fragment>
	)
}