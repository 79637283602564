export interface UserPhoneState {
  withPhoneNumber(onAddingNumber: () => void): UserPhoneState;
  withoutPhoneNumber(onRemovingNumber: () => void): UserPhoneState;
  numberProvided(onPhoneNumberProvided: () => void): void;
}

export class PhoneNumberState implements UserPhoneState {
  withPhoneNumber(onAddingNumber: () => void) {
    return this;
  }

  withoutPhoneNumber(onRemovingNumber: () => void) {
    if (!!!onRemovingNumber) {
      throw new Error("onRemovingNumber callback not provided");
    }

    onRemovingNumber();

    return new NoPhoneNumberState() as UserPhoneState;
  }

  numberProvided(onPhoneNumberProvided: () => void) {
    if (!!!onPhoneNumberProvided) {
      throw new Error("onPhoneNumberProvided was not provided");
    }

    onPhoneNumberProvided();
  }
}

export class NoPhoneNumberState implements UserPhoneState {
  withPhoneNumber(onAddingNumber: () => void) {
    if (!!!onAddingNumber) {
      throw new Error("onAddingNumber callback not provided");
    }

    onAddingNumber();

    return new PhoneNumberState() as UserPhoneState;
  }

  withoutPhoneNumber(onRemovingNumber: () => void) {
    return this;
  }

  numberProvided(onPhoneNumberProvided: () => void) {
    //NO-OP
  }
}
