import React from 'react';
import { MessageSubscription } from '../Messaging';
import Message from '../Message';

export interface IMessagePublisherContext {
    publish: <T,>(message: Message, value: T) => void,
    subscribeTo: <T,>(message: Message,  callback: (value: T) => void) => MessageSubscription;
    unsubscribeFrom: (subscription: MessageSubscription) => void;
}

const messagingContext = React.createContext<IMessagePublisherContext>({
    publish: <T,>(message: Message, value: T) => {},
    subscribeTo: <T,>(message: Message,  callback: (value: T) => void) => new MessageSubscription(-1, message, () => {}),
    unsubscribeFrom: () => {}
});

export default messagingContext;