import { StatusService } from "telehealth-api";
import authService from "../ApiAuthorization/AuthorizeService";

// Default singleton of StatusService that uses the default singleton IAuthService
const statusService = new StatusService(authService);

authService.afterSignIn(async () => {
  await statusService.makeUserAvailable();
});

export { statusService };
