export interface IResetPasswordHandlerState
{
	withCredentials(onHandlingWithCredentials: () => void ): IResetPasswordHandlerState;
	withResetToken(onHandlingWithToken: () => void): IResetPasswordHandlerState;
}

export class UninitializedPasswordHandlerState implements IResetPasswordHandlerState
{
	private login: string;
	private password: string;
	private resetToken: string;

	constructor(login: string, password: string, resetToken: string) {
		this.login = login;
		this.password = password;
		this.resetToken = resetToken;

		if(!this.login && !this.password && !this.resetToken) {
			throw new Error("No login credentials or reset token was provided. Provide either to continue.");
		}

		if(this.login && !this.password && !this.resetToken) {
			throw new Error("No password was provided with a login. Provide a password to continue.")
		}

		if(this.password && !this.login && !this.resetToken) {
			throw new Error("No login was provided with a password. Provide a password to continue.")
		}
	}
	
	withCredentials(onHandlingWithCredentials: () => void): IResetPasswordHandlerState {
		if(this.resetToken) {
			return new InitializedWithTokenState();
		}

		if(this.login && this.password) {
			onHandlingWithCredentials();
			return new InitializedWithCredentialsState();
		}

		return this;
	}

	withResetToken(onHandlingWithToken: () => void): IResetPasswordHandlerState {
		if(this.resetToken) {
			onHandlingWithToken();
			return new InitializedWithTokenState();
		}

		if(this.login && this.password) {
			return new InitializedWithCredentialsState();
		}

		return this;
	}
}

export class InitializedWithCredentialsState implements IResetPasswordHandlerState {
	withCredentials(onHandlingWithCredentials: () => void): IResetPasswordHandlerState {
		onHandlingWithCredentials();

		return this;
	}

	withResetToken(onHandlingWithToken: () => void): IResetPasswordHandlerState {
		return this;
	}
}

export class InitializedWithTokenState implements IResetPasswordHandlerState {
	withCredentials(onHandlingWithPassword: () => void): IResetPasswordHandlerState {
		return this;
	}

	withResetToken(onHandlingWithToken: () => void): IResetPasswordHandlerState {
		onHandlingWithToken();

		return this;
	}
}