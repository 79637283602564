import React, { useContext, useEffect, useState } from "react";
import { Button, CardBody, CardTitle, Col, Form, FormGroup, Input, Label } from "reactstrap";
import UserService from "user/UserService";
import MessagingContext from "common/messaging/MessagingContext";
import { Messages } from "common/Messaging";
import UserStrings from "user/UserStrings";

interface UserAccountCardProps {
  onUsernameChange: (username: string) => void;
  onPasswordChange: (password: string) => void;
  onPasswordConfirmationChange: (confirmPassword: string) => void;
  showUsernameField: boolean;
  editMode: boolean;
  notifyUsernameNeeded: () => void;
  notifyUsernameOptional: () => void;
  notifyUserActive: () => void;
  notifyUserInactive: () => void;
  username?: string;
  userActive: boolean;
  password?: string;
  passwordConfirmation?: string;
  resetRequired: boolean;
  notifyPasswordReset: (resetNeeded: boolean) => void;
}

const userService = new UserService();

export const UserAccountCard = ({
  onUsernameChange,
  onPasswordChange,
  onPasswordConfirmationChange,
  showUsernameField,
  editMode,
  notifyUsernameNeeded,
  notifyUsernameOptional,
  notifyUserActive,
  notifyUserInactive,
  username,
  userActive,
  password,
  passwordConfirmation,
  resetRequired,
  notifyPasswordReset,
}: UserAccountCardProps) => {
  const [requireReset, setResetNeeded] = useState<boolean>();
  const [inviteExpired, setInviteExpired] = useState(false);

  const messagingContext = useContext(MessagingContext);

  useEffect(() => {
    const lookupUser = async () => {
      const user = await userService.lookupByUsername(username!);

      if (user.inviteExpired) {
        setInviteExpired(true);
      }
    };

    if (username) {
      lookupUser();
    }
  }, [username]);

  useEffect(() => {
    notifyPasswordReset(requireReset ?? false);
  }, [requireReset, notifyPasswordReset]);

  const emailForUsernameChanged = (value: string) => {
    if (value === "1") {
      notifyUsernameOptional();
    } else {
      notifyUsernameNeeded();
    }
  };

  const onActiveStatusChange = (value: string) => {
    if (value === "1") {
      notifyUserActive();
    } else {
      notifyUserInactive();
    }
  };

  const resendInvite = async () => {
    if (!username) {
      return;
    }

    let inviteResponse = await userService.invite(username);

    inviteResponse.successful((account) => {
      messagingContext.publish(Messages.userModifySuccess(), UserStrings.invitationSent);
      setInviteExpired(false);
    });

    inviteResponse.failure(() => {
      messagingContext.publish(Messages.userModifyFail(), UserStrings.resendInviteError);
    });
  };

  const emailAddressForUserName = () => {
    return (
      <FormGroup className="col-6" role="radiogroup">
        Use Email Address for User Name?
        <div className="pl-4">
          <Label>
            <Input
              type="radio"
              name="emailForUsername"
              value="1"
              checked={!!!showUsernameField}
              onChange={(event) => emailForUsernameChanged(event.target.value)}
            />
            Yes
          </Label>
        </div>
        <div className="pl-4">
          <Label>
            <Input
              type="radio"
              name="emailForUsername"
              value="0"
              {...(showUsernameField !== undefined ? { checked: showUsernameField } : {})}
              onChange={(event) => emailForUsernameChanged(event.target.value)}
            />
            No
          </Label>
        </div>
      </FormGroup>
    );
  };

  const accountStatus = () => {
    return (
      <FormGroup className="col-6">
        Status
        <div className="pl-4">
          <Label>
            <Input
              type="radio"
              name="status"
              value="1"
              checked={userActive}
              onChange={(event) => onActiveStatusChange(event.target.value)}
            />
            Active
          </Label>
        </div>
        <div className="pl-4">
          <Label>
            <Input
              type="radio"
              name="status"
              value="2"
              checked={!!!userActive}
              onChange={(event) => onActiveStatusChange(event.target.value)}
            />
            Inactive
          </Label>
        </div>
      </FormGroup>
    );
  };

  const accountDetailsElement = () => {
    return (
      <FormGroup className="col-6">
        <Label for="userName">User Name</Label>
        <Input
          id="userName"
          type="text"
          name="username"
          value={username}
          onChange={(event) => onUsernameChange(event.target.value)}
        />
      </FormGroup>
    );
  };

  return (
    <>
      <CardBody>
        <CardTitle tag="h5">Account Details</CardTitle>
        <Form>
          <div className="form-row">
            {!editMode && emailAddressForUserName()}
            {editMode && accountStatus()}
          </div>
          <div className="form-row">
            {showUsernameField ? accountDetailsElement() : null}
            {editMode ? (
              <>
                <FormGroup className="col-md-3 col-xs-12">
                  <Label for="password">Password</Label>
                  <Input
                    id="password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(event) => onPasswordChange(event.target.value)}
                    placeholder="********"
                  />
                </FormGroup>
                <FormGroup className="col-3 col-xs-12">
                  <Label for="confirmPassword">Confirm Password</Label>
                  <Input
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    value={passwordConfirmation}
                    onChange={(event) => onPasswordConfirmationChange(event.target.value)}
                    placeholder="********"
                  />
                </FormGroup>
                {!inviteExpired && (
                  <FormGroup className="col-3 col-xs-12 ml-2">
                    <p>Reset Password</p>
                    <Label className="pl-4">
                      <Input type="checkbox" checked={resetRequired} onChange={() => setResetNeeded(!requireReset)} />
                      Prompt at next login
                    </Label>
                  </FormGroup>
                )}
                {inviteExpired && (
                  <Col md={3} xs={12}>
                    <Button className="btn-table" type="button" color="white" onClick={() => resendInvite()}>
                      Resend Invite
                    </Button>
                  </Col>
                )}
              </>
            ) : null}
          </div>
        </Form>
        <hr />
      </CardBody>
    </>
  );
};
