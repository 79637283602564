import React from 'react';
import { Modal as BootstrapModal } from 'bootstrap';

interface ModalProps {
}

export interface BaseModalState {
	show: boolean
}

export class Modal extends React.Component<Readonly<ModalProps>, Readonly<{}>> {
	private modal: BootstrapModal | null = null;

	constructor(props: Readonly<ModalProps>) {
		super(props);
		this.state = {};
	}

	componentWillUnmount() {
		this.cleanupModal();
	}

	cleanupModal() {
		if (this.modal) {
			this.modal.hide();
			this.modal.dispose();
			this.modal = null;
		}
	}

	private showModal = (element: HTMLDivElement | null) => {
		if (!element) {
			this.cleanupModal();
			return;
		}
		this.modal = new BootstrapModal(element);
		this.modal.show();
	}

	render() {
		return (
			<div className="modal" ref={this.showModal} tabIndex={-1} role="dialog" data-bs-backdrop="static">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}