import * as React from "react";
import { RouteComponentProps, Redirect } from "react-router";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { ResetPasswordResponse, SecurityManager } from "../SecurityManager";
import { LoginStatus, ResetPasswordStatus } from "../SecurityStatus";
import { Login } from "./ApiAuthorization/Login";
import { LoginActions } from "./ApiAuthorization/ApiAuthorizationConstants";
import { Messages } from "components/Common/Messages";
import { MessageState } from "./MessageState";
import { IResetPasswordHandlerState, UninitializedPasswordHandlerState } from "./ResetPasswordHandlerState";
import { LoginUserService } from "../services/LoginUserService";
import { LoginResponse } from "../SecurityManager";
import AnonymousApiRequest from "../common/AnonymousApiRequest";
import { ApiUrl } from "ApiConstants";
import { ApiResponseHandler } from "../common/ApiResponseHandler";
import authService from "./ApiAuthorization/AuthorizeService";
import { ApplicationPaths } from "./ApiAuthorization/ApiAuthorizationConstants";

type ExpirationCheck = {
  username: string;
  inviteExpired: boolean;
};

export interface IResetPasswordRouteProps {
  action: string;
  resetToken?: string;
}

export interface IResetPasswordProps
  extends RouteComponentProps<IResetPasswordRouteProps, any, ResetPasswordLocationState> {}

export class ResetPasswordState extends MessageState {
  currentPassword: string = "";
  newPassword: string = "";
  confirmPassword: string = "";
  loginSucceeded: boolean = false;
  redirectToLogin: boolean = false;
  firstTimeLogin: boolean = false;
  username: string = "";
  inviteExpired: boolean = false;
  shouldRender: boolean = false;
  forceLogoff: boolean = false;
  registrationUrl: string = "";
}

//used to specify the Location state shape for react-route so that we may pass username from the UserLogin component
interface ResetPasswordLocationState {
  username: string;
  action?: string;
}

export class ResetPassword extends React.Component<Readonly<IResetPasswordProps>, ResetPasswordState> {
  private handlerState?: IResetPasswordHandlerState;

  constructor(props: Readonly<IResetPasswordProps>) {
    super(props);

    let state = new ResetPasswordState();

    if (props.location.state?.username) {
      state.username = props.location.state.username;
    }

    if (!this.props.match.params.resetToken) {
      state.firstTimeLogin = false;
    } else {
      state.firstTimeLogin = true;
    }

    this.state = state;
  }

  async componentDidMount() {
    let request = new AnonymousApiRequest();

    if (this.props.match.params.resetToken) {
      //if signed in, we need to logout and redirect back here
      if (await authService.isAuthenticated()) {
        //build the registration url to redirect back to after signing out
        let regUrl = encodeURIComponent(
          `${window.location.origin}${ApplicationPaths.RegistrationInvite}/${this.props.match.params.resetToken}`
        );

        this.setState((s) => ({
          ...s,
          forceLogoff: true,
          registrationUrl: regUrl,
        }));
      } else {
        //check for expired invitation
        let response = await request.get(`${ApiUrl.UserUrl}/lookupByToken/${this.props.match.params.resetToken}`);

        let expiredInviteCheck = new ApiResponseHandler<ExpirationCheck, ExpirationCheck>();

        let user = await expiredInviteCheck.handle(
          response,
          (json) => json,
          () => ({ username: "", inviteExpired: false })
        );

        user.successful((result) =>
          this.setState((s) => ({
            ...s,
            inviteExpired: result.inviteExpired,
            errorMessages: result.inviteExpired ? ["Your invite has expired"] : [],
          }))
        );
      }
    }

    this.setState((s) => ({
      ...s,
      shouldRender: true,
    }));
  }

  private handleCurrentPasswordChange(currentPassword: string) {
    this.setState((s) => ({
      ...s,
      currentPassword: currentPassword,
    }));
  }

  private handlePasswordChange(password: string) {
    this.setState((s) => ({
      ...s,
      newPassword: password,
    }));
  }

  private handleConfirmPasswordChange(confirmPassword: string) {
    this.setState((s) => ({
      ...s,
      confirmPassword: confirmPassword,
    }));
  }

  private async submitPasswordChange(): Promise<void> {
    if (this.state.username) {
      this.attemptPasswordReset(this.state.username);
    } else {
      let userService = new LoginUserService((lookedUpUser) => this.attemptPasswordReset(lookedUpUser));

      await userService.lookupAnonymousUserByToken(this.props.match.params.resetToken ?? "");
    }
  }

  private attemptPasswordReset(username: string) {
    this.handlerState = new UninitializedPasswordHandlerState(
      username,
      this.state.newPassword,
      this.props.match.params.resetToken ?? ""
    );

    //attempt both resets, the callback will only be invoked for the correct state, determined by what was provided to the initial handler state.
    this.handlerState = this.handlerState.withCredentials(async () => await this.resetWithCredentialsAsync());
    this.handlerState = this.handlerState.withResetToken(
      async () => await this.resetWithTokenAsync(this.props.match.params.resetToken ?? "")
    );
  }

  private async resetWithTokenAsync(resetToken: string): Promise<ResetPasswordResponse> {
    let securityManager = new SecurityManager();

    let passwordResetResponse = await securityManager.resetUserPasswordWithTokenAsync(
      this.state.newPassword,
      this.state.confirmPassword,
      resetToken
    );

    this.updateStateFromResetPasswordResponseAsync(passwordResetResponse);

    return passwordResetResponse;
  }

  private async resetWithCredentialsAsync(): Promise<ResetPasswordResponse> {
    var securityManager = new SecurityManager();

    let resetPasswordResponse = await securityManager.resetUserPassword(
      this.state.username,
      this.state.currentPassword,
      this.state.newPassword,
      this.state.confirmPassword
    );

    this.updateStateFromResetPasswordResponseAsync(resetPasswordResponse);

    return resetPasswordResponse;
  }

  private async updateStateFromResetPasswordResponseAsync(passwordResetResponse: ResetPasswordResponse): Promise<void> {
    if (passwordResetResponse.status === ResetPasswordStatus.Success) {
      var securityManager = new SecurityManager();
      let loginResponse = await securityManager.loginUser(passwordResetResponse.userName, this.state.newPassword);

      this.updateStateFromLoginResponse(loginResponse);
    } else if (passwordResetResponse.status === ResetPasswordStatus.Failure) {
      let errors: string[] = [];
      passwordResetResponse.errorMessages.forEach((msg) => errors.push(msg));

      this.setState((s) => ({
        ...s,
        errorMessages: errors,
      }));
    }
  }

  private updateStateFromLoginResponse(loginResponse: LoginResponse) {
    let errors: string[] = [];

    switch (loginResponse.status) {
      case LoginStatus.Success:
      case LoginStatus.FirstTimeLogin:
        this.setState((s) => ({
          ...s,
          loginSucceeded: true,
        }));
        break;
      case LoginStatus.Failure:
        loginResponse.errorMessages.forEach((msg) => errors.push(msg));
        this.setState((s) => ({
          ...s,
          errorMessages: errors,
        }));
        break;
      default:
        this.setState((s) => ({
          ...s,
          redirectToLogin: true,
        }));
    }
  }

  render() {
    return (
      <>
        {this.state.shouldRender && (
          <>
            <div className="upper-content"></div>
            <div className="lower-content card-body">
              <Row>
                <Col>
                  <Messages
                    errorMessages={this.state.errorMessages}
                    successMessages={this.state.successMessages}
                  ></Messages>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col>
                  {!this.state.inviteExpired && (
                    <Form className="rounded">
                      <div className="form-info">
                        <p className="text-center">Your password needs to be updated.</p>
                      </div>
                      <div className="form-fields">
                        {!this.state.firstTimeLogin && (
                          <FormGroup>
                            <Label for="currentPassword">Current Password</Label>
                            <Input
                              type="password"
                              name="currentPassword"
                              value={this.state.currentPassword}
                              onChange={(event) => this.handleCurrentPasswordChange(event.target.value)}
                            />
                          </FormGroup>
                        )}
                        <FormGroup>
                          <Label for="password">New Password</Label>
                          <Input
                            type="password"
                            name="password"
                            value={this.state.newPassword}
                            onChange={(event) => this.handlePasswordChange(event.target.value)}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="confirmPassword">Confirm New Password</Label>
                          <Input
                            type="password"
                            name="confirmPassword"
                            value={this.state.confirmPassword}
                            onChange={(event) => this.handleConfirmPasswordChange(event.target.value)}
                          />
                        </FormGroup>
                        <div className="text-center">
                          <Button type="button" className="green" onClick={(event) => this.submitPasswordChange()}>
                            Log In with New Password
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                  {this.state.loginSucceeded && <Login {...this.props} action={LoginActions.Login} />}
                </Col>
              </Row>
            </div>{" "}
            {this.state.forceLogoff && (
              <Redirect
                to={{
                  pathname: `${ApplicationPaths.LogOut}`,
                  state: { local: true },
                  search: `?returnUrl=${this.state.registrationUrl}`,
                }}
              />
            )}
          </>
        )}
      </>
    );
  }
}
