import React, { Component } from 'react';
import { RoomManager } from './RoomManager';

export interface ToggleSettingsButtonProps {
	roomManager: RoomManager
}

export class ToggleSettingsButton extends React.Component<Readonly<ToggleSettingsButtonProps>, Readonly<{}>> {

	constructor(props: Readonly<ToggleSettingsButtonProps>) {
		super(props);
	}

    showOrHideSettings() {
        if (this.props.roomManager.getShowSettings()) {
            this.props.roomManager.hideSettings();
        } else {
            this.props.roomManager.showSettings();
        }
    }

	render() {
        return (
            <button type="button" className="btn" onClick={this.showOrHideSettings.bind(this)}>
                <i className="fas fa-cog fa-fw"></i>
            </button>
        );
	}
}