import authService from "components/ApiAuthorization/AuthorizeService";

export class ApiRequest {
	async get (id: string, endpoint: string, resourceIdentifier?: string) {
		const token = await this.getToken();
		
		this.validateToken(token);

		let dest = endpoint;
		if(resourceIdentifier) {
			if(dest.endsWith('/')) {
				dest = dest.substr(0, dest.length - 2);
			}

			dest = `${endpoint}?${resourceIdentifier}=${id}`
		} else {
			if(!dest.endsWith('/')){
				dest = dest + '/';
			}

			dest = dest + id;
		}

		return await fetch(dest, {
			headers: this.commonRequestHeaders(token!)
		});
	}

	async send(endpoint: string) {
		const token = await this.getToken();

		this.validateToken(token);

		var response = await fetch(endpoint, {
			headers: this.commonRequestHeaders(token!),
		});

		return response;
	}

	async patch<T>(endpoint: string, request: T) {
		const token = await this.getToken();

		this.validateToken(token);

		return await fetch(endpoint, {
				headers: this.commonRequestHeaders(token!),
				method: "PATCH",
				body: JSON.stringify(request),
			}
		);
	}

	async post<T>(endpoint: string, request: T) {
		const token = await this.getToken();

		this.validateToken(token);

		return await fetch(endpoint, {
			headers: this.commonRequestHeaders(token!),
			method: "POST",
			body: JSON.stringify(request)
		});
	 }

	 async put<T>(endpoint: string, request: T) {		 
		const token = await this.getToken();

		this.validateToken(token);

		return await fetch(endpoint, {
			headers: this.commonRequestHeaders(token!),
			method: "PUT",
			body: JSON.stringify(request)
		});
	 }
	

	 private validateToken(bearerToken?: string) {
		 if(!bearerToken) {
			throw new Error('unable to retrieve bearer token from the oidc manager');
		 }
	 }

	 private commonRequestHeaders(bearerToken: string) {
		 return {
			"Content-Type": "application/json",
			"Authorization": `Bearer ${bearerToken}`
		 };
	 }

	 private async getToken() { 
		if(!await authService.isAuthenticated()){
			await authService.signIn({});
		}

		if(await authService.accessTokenExpired()) {
			await authService.signIn({});
		}

		var token = await authService.getAccessToken();

		return token ?? undefined;
	 }
}

export enum PatchOperation {
	Add = "add",
	Remove = "remove",
}

export class PatchRequest<TValue> extends ApiRequest {
	specifications: {operation: string, path: string, value: TValue}[] = []

	constructor(op: string, path: string, value: TValue) {
		super();

		this.specifications.push({
			operation: op,
		 	path: path,
			value: value
		})
	}

	async patch(endpoint: string) {
		return super.patch<{operation: string, path: string, value: TValue}[]>(endpoint, this.specifications);
	}
}
