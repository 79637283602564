import CallLog from "callCenter/CallLog";
import { ApiRequest } from "common/ApiRequest";
import { ApiResponseHandler } from "common/ApiResponseHandler";
import { Messages, MessageSubscription } from "common/Messaging";
import { ImageTrendTable, ImageTrendTableHeader } from "components/Common/ImageTrendTable";
import { SecuredComponent } from "components/SecuredComponent";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Column } from "react-table";
import { Granted } from "security/Permission/Granted";
import { RenderPermission } from "security/Permission/RenderPermission";
import { CallResponse } from "common/CallResponse";
import DeclinedCallLog from "common/messaging/DeclinedCallLog";
import { LoadingIndicator } from "components/Common/LoadingIndicator";
import MessagingContext from "common/messaging/MessagingContext";

export const CallHistory = () => {
  const [callHistoryRetreived, setCallHistoryRetrieved] = useState(false);
  const [priorCalls, setCallHistory] = useState<CallLog[]>([]);
  const [viewCallsPermission, setViewPermission] = useState(new RenderPermission(new Granted()));
  const messagingContext = useContext(MessagingContext);

  let messageSubs = useRef([] as MessageSubscription[]);

  useEffect(() => {
    const retrieveCalls = async () => {
      let callHistoryRequest = new ApiRequest();

      var callTask = callHistoryRequest.send("/api/v1/call");

      var responseHandler = new ApiResponseHandler<Iterable<CallLog>, CallResponse[]>();

      const callsResponse = await responseHandler.handle(
        await callTask,
        (calls) => {
          const sortByReceivedDate = (calls: CallResponse[]) => {
            return calls.sort((a, b) => {
              if (!a.dateReceived && !b.dateReceived) {
                return 0;
              }

              if (!b.dateReceived) {
                return -1;
              }

              if (!a.dateReceived) {
                return 1;
              }

              const firstDate = DateTime.fromISO(a.dateReceived);
              const secondDate = DateTime.fromISO(b.dateReceived);

              if (firstDate < secondDate) {
                return -1;
              }

              if (firstDate > secondDate) {
                return 1;
              }

              return 0;
            });
          };

          const recentCalls = sortByReceivedDate(calls).reverse().slice(0, 14);

          const callLog: Array<CallLog> = [];
          let filteredCalls = recentCalls.filter((c) => c.endDate !== null || c.declinedByMe);
          for (let call of filteredCalls) {
            let recipient = call.recipientTeam ?? call.recipient ?? "";

            if (call.recipientTeam && call.answeredBy) {
              recipient = recipient.concat(` (${call.answeredBy})`);
            }

            let log = new CallLog(
              call.callId,
              call.caller,
              call.callerAgency,
              recipient,
              DateTime.fromISO(call.dateReceived!),
              call.callerUnitNumber
            );

            if (call.answeredBy) {
              log.answer(call.answeredBy);
            }

            callLog.push(log);
          }

          return callLog;
        },
        () => []
      );

      callsResponse.successful((callHistory) => {
        setCallHistory(Array.from(callHistory));

        const grantedPermission = new RenderPermission(new Granted());
        setViewPermission(grantedPermission.enableRendering());
        setCallHistoryRetrieved(true);
      });
    };

    retrieveCalls();
  }, []);

  useEffect(() => {
    let declinedMessage = Messages.userDeclinedCall();
    let sub = messagingContext.subscribeTo(declinedMessage, (msg: DeclinedCallLog) => {
      setCallHistory([msg.declinedCallLog, ...priorCalls]);
    });

    messageSubs.current.push(sub);

    return () => {
      for (let messageSub of messageSubs.current) {
        messagingContext.unsubscribeFrom(messageSub);
      }

      messageSubs.current = [];
    };
  }, [priorCalls, setCallHistory, messageSubs, messagingContext]);

  const callHistoryColumns: Column<CallLog>[] = [
    new ImageTrendTableHeader<CallLog>(
      "Date Received",
      (callLog) => callLog.callReceivedDateOnlyFormatted
    ) as Column<CallLog>,
    new ImageTrendTableHeader<CallLog>(
      "Start Time",
      (callLog) => callLog.callReceivedTimeOnlyFormatted
    ) as Column<CallLog>,
    new ImageTrendTableHeader<CallLog>("Status", (callLog) => callLog.answeredState) as Column<CallLog>,
    new ImageTrendTableHeader<CallLog>("Recipient (Answered by)", (callLog) => callLog.recipient) as Column<CallLog>,
    new ImageTrendTableHeader<CallLog>("Caller", (callLog) => callLog.displayCaller()) as Column<CallLog>,
  ];

  return (
    <>
      {!callHistoryRetreived && <LoadingIndicator loadingMessage="Loading Previous Calls..." />}
      {callHistoryRetreived && (
        <SecuredComponent permission={viewCallsPermission}>
          <ImageTrendTable renderableData={priorCalls} headers={callHistoryColumns} title="Past Calls" />
        </SecuredComponent>
      )}
    </>
  );
};
