export abstract class DemographicPrefix {
	descriptor: string;
	private static _empty: DemographicPrefix;

	constructor(descriptor: string) {
		if(!descriptor) {
			throw new Error("descriptor was not provided");
		}

		this.descriptor = descriptor;
	}

	static empty() {
		if(!this._empty) {
			this._empty = new EmptyPrefix();
		}

		return this._empty;
	}

	static parse(description: string) {
		const prefixChooser: any = {
			["Mr."]: () => new MrDemographicPrefix(),
			["Mrs."]: () => new MrsDemographicPrefix(),
			["Ms."]: () => new MsDemographicPrefix(),
			["Dr."]: () => new DrDemographicPrefix};

		let creator = prefixChooser[description];
		
		if(creator){
			return creator();
		}

		return DemographicPrefix.empty();
	}
}

class EmptyPrefix extends DemographicPrefix{
	constructor() {
		super("Please Select");
	}
}

export class MrDemographicPrefix extends DemographicPrefix {
	constructor() {
		super("Mr.")
	}
}

export class MrsDemographicPrefix extends DemographicPrefix {
	constructor() {
		super("Mrs.")
	}
}

export class MsDemographicPrefix extends DemographicPrefix {
	constructor() {
		super("Ms.");
	}
}

export class DrDemographicPrefix extends DemographicPrefix {
	constructor() {
		super("Dr.");
	}
}