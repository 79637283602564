import { ApiUrl } from '../ApiConstants';
import authService from '../components/ApiAuthorization/AuthorizeService';

interface IUserLookupState {
	lookup: (username: string) => IUserLookupState;
	dontLookup: () => IUserLookupState;
}


class UserNotLookedUpState implements IUserLookupState {
	private _onLookingUp: (username: string) => void; 

	constructor(onUserLookingUp: (username: string) => void){
		if(!!!onUserLookingUp) {
			throw new Error('onLookingUp callback was not provided');
		}

		this._onLookingUp = onUserLookingUp;
	}

	lookup(username: string): IUserLookupState {
		if(!!!username) {
			throw new Error("username was not provided");
		}
		
		this._onLookingUp(username);

		return new UserLookedUpState(this._onLookingUp)
	}

	dontLookup(): IUserLookupState {
		return this;
	}
}

class UserLookedUpState implements IUserLookupState {
	private _onLookingUp: (username: string) => void; 

	constructor(onLookingUp: (username: string) => void){
		if(!!!onLookingUp) {
			throw new Error('onLookingUp callback was not provided');
		}

		this._onLookingUp = onLookingUp;
	}

	lookup(username: string): IUserLookupState {
		return this;
	}

	dontLookup(): IUserLookupState {
		return new UserNotLookedUpState(this._onLookingUp);
	}
}

export class LoginUserService {
	private _userLookupResponse: IUserLookupState;

	constructor(onLookingUp: (username: string) => void) {
		this._userLookupResponse = new UserNotLookedUpState(onLookingUp);
	}

	async lookupSignedInUserAsync() {
		const accessToken = await authService.getAccessToken();

		if(!!!accessToken) {
			return;
		}

		var lookupResponse = await fetch(`${ApiUrl.UserUrl}/lookupSignedInUser`, {
			method: "GET",
			headers: {
				"Authorization": `Bearer ${accessToken}`
			}
		});

		if(lookupResponse.redirected){
			this._userLookupResponse = this._userLookupResponse.dontLookup();
		}

		if(lookupResponse.status === 200) {
			let userLookup = await lookupResponse.json();
			
			this._userLookupResponse = this._userLookupResponse.lookup(userLookup.username);
		} 
	}

	async lookupAnonymousUserByToken(resetToken: string) {
		if(!resetToken) {
			return;
		}

		var lookupResponse = await fetch(`${ApiUrl.UserUrl}/lookupByToken/${resetToken}`);

		if(lookupResponse.status === 200) {
			let lookup = await lookupResponse.json();

			this._userLookupResponse = this._userLookupResponse.lookup(lookup.username);
		} 
	}
}