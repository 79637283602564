import React, { useEffect, useRef, useState } from 'react';
import { StaticContext } from 'react-router';
import { useParams, RouteComponentProps } from 'react-router-dom';
import { Login } from 'components/ApiAuthorization/Login';
import { LoginActions } from './ApiAuthorization/ApiAuthorizationConstants';

export const AcceptCall = (props: RouteComponentProps<{}, StaticContext, {action: string}>) => {
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const redirectUrl = useRef<string>();

    let { userId, roomId, signature } = useParams<{userId: string, roomId: string, signature: string}>();
  
    useEffect(() => {
        if(!userId) {
            return;
        }

        if(!roomId) {
            return;
        }

        const joinCall = async () => {
            let joinCallResponse = await fetch(`/api/v1/call/join/${userId}/${roomId}/${signature}`, {
                method: "GET"
            });

            redirectUrl.current = `${window.location.protocol}//${window.location.host}/room?roomId=${roomId}`;

            if(joinCallResponse.ok) {
                setRedirectToLogin(true);              
            }
        }

        joinCall();

    }, [userId, roomId, signature])

    return  <>
                {redirectToLogin && <Login {...props} action={LoginActions.Login} redirectUrl={redirectUrl.current}  />}
            </>
}