export class PatchStrings {
    static readonly opAdd = "add";
    static readonly opRemove = "remove";
}

export default abstract class PatchRequest { 
    private patchPayload: [{
        op: string,
        path: string,
        value: string | number | object
    }];

    constructor(op: string, path: string, value: string | number | object) {
        this.patchPayload = [{
            op: op,
            path: path,
            value: value
        }];
    }

    get payload() {
        return this.patchPayload;
    }

    addOp(op: string, path: string, value: string | number | object) {
        this.patchPayload.push({
            op: op,
            path: path,
            value: value
        });
    }

    removeOp(op: string, path: string, value: string | number | object) {
        const patchIndex = this.patchPayload.findIndex(r => r.op === op && r.path === path && r.value === value);

        if(patchIndex > -1) {
            this.patchPayload.splice(patchIndex, 1);
        }
    }
}