export interface NameState {
	hasMiddleName: () => NameState;
	hasPrefix:() => NameState;
	hasSuffix:() => NameState;
	includeMiddleName: (onHasMiddleName: () => void) => void;
	includePrefix: (onHasPrefix: () => void) => void;
	includeSuffix: (onHasSuffix: () => void) => void;
}

export class FirstLastState implements NameState {
	hasMiddleName() {
		return new FirstMiddleLastState() as NameState;
	}

	hasPrefix() {
		return new PrefixFirstLastState() as NameState;
	}

	hasSuffix() {
		return new FirstLastSuffixState() as NameState;
	}

	includeMiddleName(onHasMiddleName: () => void) {
		//NO-OP
	}

	includePrefix(onHasPrefix: () => void) {
		//NO-OP
	}

	includeSuffix(onHasSuffix: () => void) {
		//NO-OP
	}
}

export class FirstMiddleLastState implements NameState {
	hasMiddleName() {
		return this as NameState;
	}

	hasPrefix() {
		return new PrefixFirstMiddleLastState() as NameState;
	}

	hasSuffix() {
		return new FirstMiddleLastSuffixState() as NameState;
	}

	includePrefix(onHasPrefix: () => void) {
		//NO-OP
	}

	includeMiddleName(onHasMiddleName: () => void) {
		if(!!!onHasMiddleName) {
			throw new Error("onHasMiddleName callback was not provided");
		}

		onHasMiddleName();
	}

	includeSuffix(onHasSuffix: () => void) {
		//NO-OP
	}
}

export class PrefixFirstLastState implements NameState {
	hasMiddleName() {
		return new PrefixFirstMiddleLastState() as NameState;
	}

	hasPrefix() {
		return this;
	}

	hasSuffix() {
		return new PrefixFirstLastSuffixState() as NameState;
	};

	includeMiddleName(onHasMiddleName: () => void) {
		//NO-OP
	};

	includePrefix(onHasPrefix: () => void) {
		if(!onHasPrefix) {
			throw new Error("onHasPrefix callback was not provided");
		}

		onHasPrefix();
	}

	includeSuffix(onHasSuffix: () => void) {
		//NO-OP
	}

}

export class FirstLastSuffixState implements NameState {
	hasMiddleName() {
		return new FirstMiddleLastSuffixState() as NameState;
	}

	hasPrefix() {
		return new PrefixFirstLastSuffixState() as NameState;
	}

	hasSuffix() {
		return this;
	}

	includeMiddleName(onHasMiddleName: () => void) {
		//NO-OP
	}

	includePrefix(onHasPrefix: () => void) {
		//NO-OP
	}

	includeSuffix(onHasSuffix: () => void) {
		if(!!!onHasSuffix) {
			throw new Error("onHasSuffix callback was not provided.");
		}

		onHasSuffix();
	}
}

export class PrefixFirstMiddleLastState implements NameState {
	hasMiddleName() {
		return this;
	}

	hasPrefix() {
		return this;
	}

	hasSuffix() {
		return new PrefixFirstMiddleLastSuffixState() as NameState;
	}

	includeMiddleName(onHasMiddleName: () => void) {
		if(!!!onHasMiddleName) {
			throw new Error("onHasMiddleName callback was not provided.");
		}

		onHasMiddleName();
	}

	includePrefix(onHasPrefix: () => void) {
		if(!!!onHasPrefix) {
			throw new Error("onHasPrefix callback was not provided.");
		}

		onHasPrefix();
	}

	includeSuffix(onHasSuffix: () => void) {
		//NO-OP
	}
}

export class FirstMiddleLastSuffixState implements NameState {
	hasMiddleName() {
		return this;
	}

	hasPrefix() {
		return new PrefixFirstMiddleLastSuffixState() as NameState;
	}

	hasSuffix() {
		return this;
	}

	includeMiddleName(onHasMiddleName: () => void) {
		if(!!!onHasMiddleName) {
			throw new Error("onHasMiddleName callback was not provided.");
		}

		onHasMiddleName();
	}

	includePrefix(onHasPrefix: () => void) {
		//NO-OP
	}

	includeSuffix(onHasSuffix: () => void) {
		if(!!!onHasSuffix) {
			throw new Error("onHasSuffix callback was not provided.");
		}

		onHasSuffix();
	}
}

export class PrefixFirstLastSuffixState implements NameState {
	hasMiddleName() {
		return new PrefixFirstMiddleLastSuffixState() as NameState;
	}

	hasPrefix() {
		return this;
	}

	hasSuffix() {
		return this;
	}

	includeMiddleName(onHasMiddleName: () => void) {
		//NO-OP
	}

	includePrefix(onHasPrefix: () => void) {
		if(!!!onHasPrefix) {
			throw new Error("onHasPrefix callback was not provided.");
		}

		onHasPrefix();
	}

	includeSuffix(onHasSuffix: () => void) {
		if(!!!onHasSuffix) {
			throw new Error("onHasSuffix callback was not provided.");
		}

		onHasSuffix();
	}
}

export class PrefixFirstMiddleLastSuffixState implements NameState {
	hasMiddleName() {
		return this;
	}

	hasPrefix() {
		return this;
	}

	hasSuffix() {
		return this;
	}

	includeMiddleName(onHasMiddleName: () => void) {
		if(!!!onHasMiddleName) {
			throw new Error("onHasMiddleName callback was not provided.");
		}

		onHasMiddleName();
	}

	includePrefix(onHasPrefix: () => void) {
		if(!!!onHasPrefix) {
			throw new Error("onHasPrefix callback was not provided.");
		}

		onHasPrefix();
	}

	includeSuffix(onHasSuffix: () => void) {
		if(!!!onHasSuffix) {
			throw new Error("onHasSuffix callback was not provided.");
		}

		onHasSuffix();
	}
}
