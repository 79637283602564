import { AccountStatusDescriptions } from './user/AccountStatusConstants';
import { AccountStatusState, InactiveStatusState } from './user/AccountStatusState';
import { Claim } from './security/Claim';
import { FirstLastState, NameState } from './user/NameState';
import { NameSplitter } from './common/NameSplitter';
import { NoPhoneNumberState, UserPhoneState } from './user/UserPhoneState';

export class Person {
	id: string;
	readonly firstName: string;
	middleName?: string;
	readonly lastName: string;
	lastLogin: string = "";
	readonly email: string;

	private _name: string;
	private _namePrefix?: string;
	private _nameSuffix?: string;

	private _phoneNumber?: string;
	
	private _state: NameState = new FirstLastState();
	private _phoneState: UserPhoneState = new NoPhoneNumberState();

	constructor(id: string, name: string, email: string) {
		if(!!!name) {
			throw new Error("name was not provided");
		}

		if(!!!email) {
			throw new Error("email not provided");
		}

		this.id = id;
		this._name = name;
		this.email = email;

		let nameSplit = new NameSplitter();
		let nameParts = nameSplit.split(this._name);

		this.firstName = nameParts.firstName;
		this.lastName = nameParts.lastName;

		this._nameSuffix = nameParts.suffix;

		if(nameParts.middleName) {
			this.middleName = nameParts.middleName;
			this._state = this._state.hasMiddleName();
		}

		if(nameParts.prefix) {
			this._namePrefix = nameParts.prefix;
			this._state = this._state.hasPrefix();
		}

		if(nameParts.suffix) {
			this._nameSuffix = nameParts.suffix;
			this._state = this._state.hasSuffix();
		}
	}

	name() {
		return `${this.firstName} ${this.lastName}`;
	}
	
	displayName(): string {
		let name = "";

		this._state.includePrefix(() => name = `${this._namePrefix} `)

		name = `${name}${this.firstName}`;
		
		this._state.includeMiddleName(() => name = `${name} ${this.middleName}`)
		
		name = `${name} ${this.lastName}`

		this._state.includeSuffix(() => name = `${name} ${this._nameSuffix}`)

		return name;
	}

	get namePrefix() {
		return this._namePrefix
	}

	get nameSuffix() {
		return this._nameSuffix
	}

	onHasMiddleName(onMiddleName: () => void) {
		if(!!!onMiddleName) {
			throw new Error("onMiddleName callback was not provided.")
		}

		this._state.includeMiddleName(() => onMiddleName());
	}

	namePrefixedWith(namePrefix: string) {
		this._state = this._state.hasPrefix();

		this._namePrefix = namePrefix;
	}

	nameSuffixedWith(nameSuffix: string) {
		this._state = this._state.hasSuffix();

		this._nameSuffix = nameSuffix;
	}

	onNamePrefixed(onPrefix: () => void) {
		if(!!!onPrefix) {
			throw new Error("onPrefix callback was not provided.")
		}

		this._state.includePrefix(() => onPrefix());
	}

	onNameSuffixed(onSuffix: () => void) {
		if(!!!onSuffix) {
			throw new Error("onSuffix callback was not provided.");
		}
	}

	reachableByPhoneAt(withPhoneNumber: (number: string) => void) {
		if(!!!withPhoneNumber) {
			throw new Error("withPhoneNumber callback was not provided.");
		}

		this._phoneState.numberProvided(() => withPhoneNumber(this._phoneNumber!));
	}

	removePhoneNumber() {
		this._phoneState = this._phoneState.withoutPhoneNumber(() => this.clearPhoneNumber());
	}

	withPhoneNumber(phoneNumber: string) {
		if(!!!phoneNumber) {
			return;
		}

		this._phoneState = this._phoneState.withPhoneNumber(() => this._phoneNumber = phoneNumber);
	}

	private clearPhoneNumber() {
		this._phoneNumber = undefined;
	}
}

export class SecurePerson extends Person {
	flatPermissionGroups: string = "";
	private _status: string = AccountStatusDescriptions.Inactive;
	private readonly _username: string;

	claims: Claim[] = []

	private _accountStatusState: AccountStatusState = new InactiveStatusState();

	//a function another function as a parameter which will be invoked
	//when the user is active.
	//defaults to do nothing since we default the user to the inactive state.
	private _whenPersonActive: (invoke: () => void) => void = this.doNothing;

	constructor(id: string, name: string, email: string, username: string, claims: Claim[]) {
		super(id, name, email);

		if(!!!username) {
			throw new Error("username was not provided");
		}

		this._username = username;
		this.claims = claims;
	}

	get status(): string {
		return this._status;
	}

	set status(status: string) {
		if(!!!status) {
			this._whenPersonActive = () => {};

			this._accountStatusState.inactive(() => this._status = AccountStatusDescriptions.Active);
		}

		if(status === AccountStatusDescriptions.Active) {
			this._accountStatusState = this._accountStatusState.active(() => {
				this._status = AccountStatusDescriptions.Active;

				this._whenPersonActive = this.invokeActiveCallback;
			});
		} else if(status === AccountStatusDescriptions.Inactive) {
			this._accountStatusState = this._accountStatusState.inactive(() => {
				this._status = AccountStatusDescriptions.Inactive;

				this._whenPersonActive = this.doNothing;
			});
		}
	}

	get username() {
		return this._username
	}

	activate() {
		this._accountStatusState = this._accountStatusState.active(() => {
			this._status = AccountStatusDescriptions.Active
			this._whenPersonActive = this.invokeActiveCallback;
		});
	}

	deactivate() {
		this._accountStatusState = this._accountStatusState.inactive(() => {
			this._status = AccountStatusDescriptions.Inactive;
			this._whenPersonActive = this.doNothing;
		});
	}

	loginAllowed(onLoginAllowed: () => void) {
		this._whenPersonActive(onLoginAllowed)
	}

	private invokeActiveCallback(activeCallback: () => void) {
		activeCallback();
	}

	private doNothing(activeCallback: () => void) {
		//NO-OP 
	}
}