import { SecuredPersonService } from '../services/SecuredPersonService';
import { Claim } from './Claim';
import { Claims } from './Claims';
import { SecurityStrings } from '../ApiConstants';
import { UserRoleClaim } from './UserRoleClaim';

export class RoleRetriever {
	private _securedUserService: SecuredPersonService;

	constructor(securedUserService: SecuredPersonService) {
		if(!securedUserService) {
			throw new Error("securedUserService was not provided");
		}

		this._securedUserService = securedUserService;
	}

	async ofLoggedInUser(): Promise<Claim> {
		let loggedInUser = await this._securedUserService.lookupLoggedInUser();

		let claims = new Claims(loggedInUser.claims);

		return claims.findClaimsByType(SecurityStrings.RoleClaimType) ?? new UserRoleClaim();
	}
}