import React from "react";
import { Component } from "react";
import { RouteProps, RouteComponentProps } from "react-router";
import { Route, Redirect } from "react-router-dom";
import { ApplicationPaths, QueryParameterNames } from "./ApiAuthorizationConstants";
import { AuthContext } from "./AuthContext";

interface AuthorizeRouteState {
  //ready: boolean;
  authenticated: boolean;
}

export default class AuthorizeRoute extends Component<RouteProps, AuthorizeRouteState> {
  _subscription: number | null = null;
  _ready = false;

  constructor(props: RouteProps) {
    super(props);

    this.state = {
      //ready: false,
      authenticated: false,
    };
  }

  componentDidMount() {
    this._subscription = this.context.onAuthenticationChange(() => this.authenticationChanged());
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    if (this._subscription) {
      this.context.cancelAuthenticationChange(this._subscription);
    }
  }

  render() {
    const { authenticated } = this.state;
    var link = document.createElement("a");
    if (this.props?.path) {
      link.href = Array.isArray(this.props.path) ? this.props.path[0] : this.props.path;
    }
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    let redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`;
    if (!this._ready) {
      return <div></div>;
    } else {
      return this.renderComponent(this.props, authenticated, redirectUrl);
    }
  }

  renderRoute(authenticated: boolean, redirectUrl: string, props: RouteProps, routeProps: RouteComponentProps<any>) {
    if (!authenticated) {
      return <Redirect to={redirectUrl}></Redirect>;
    }
    if (props.children) {
      if (typeof props.children === "function") {
        return props.children(routeProps);
      }
      return props.children;
    }
    if (props.component) {
      return React.createElement(props.component, routeProps);
    }
    if (props.render) {
      return props.render(routeProps);
    }
    return null;
  }

  renderComponent(props: RouteProps, authenticated: boolean, redirectUrl: string) {
    let { component, children, render, ...routeProps } = props;
    return <Route {...routeProps} render={this.renderRoute.bind(this, authenticated, redirectUrl, props)} />;
  }

  async populateAuthenticationState() {
    console.debug("AuthorizeRoute:populateAuthenticationState called");
    const authenticated = await this.context.isAuthenticated();
    this._ready = true;
    this.setState((prevState: AuthorizeRouteState) => {
      if (prevState.authenticated === authenticated) {
        //no change, no need to rerender
        return prevState;
      }

      return { authenticated };
    });
  }

  async authenticationChanged() {
    this._ready = false;
    //this.setState({ authenticated: false });
    await this.populateAuthenticationState();
  }
}

AuthorizeRoute.contextType = AuthContext;
