import authService from "components/ApiAuthorization/AuthorizeService";
import logger from "Logging";
import React, { Component } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { IRoomAdapter } from "telehealth-abstractions";
import { Room } from "telehealth-client";
import { statusService } from "components/SignalR/StatusService";
import { SecuredPersonService } from "services/SecuredPersonService";
import TelehealthPortalPermission from "security/Permission/TelehealthPortalPermission";
import { Claims } from "security/Claims";

export interface RoomRouterProps extends RouteComponentProps {
  showNavMenu: (show: boolean) => void;
  userService: SecuredPersonService;
}

interface RoomRouterState {
  invalidRoom: boolean;
  returnToCallCenter: boolean;
}

export class RoomRouter extends Component<Readonly<RoomRouterProps>, RoomRouterState> implements IRoomAdapter {
  roomId: string | null;

  constructor(props: Readonly<RoomRouterProps>) {
    super(props);
    const query = new URLSearchParams(props.location.search);
    this.roomId = query.get("roomId");

    this.state = {
      invalidRoom: false,
      returnToCallCenter: false,
    };
  }

  componentDidMount() {
    statusService.disableCallNotifications();
    this.props.showNavMenu(false);

    //handle non-existent rooms
    window.addEventListener("unhandledrejection", (event) => this.handleRejectedPromise(event));
  }

  componentWillUnmount() {
    this.props.showNavMenu(true);
    statusService.enableCallNotifications();

    window.removeEventListener("unhandledrejection", (event) => this.handleRejectedPromise(event));
  }

  leaveRoom = async () => {
    //external user? sign them out
    const user = await this.props.userService.lookupLoggedInUser();

    let portalPermission = new TelehealthPortalPermission(new Claims(user.claims));

    portalPermission.granted(() =>
      this.setState((state) => {
        return {
          returnToCallCenter: true,
        };
      })
    );

    await portalPermission.deniedAsync(async () => {
      await authService.signOut({});
    });
  };

  endCallCallback = (roomId: string) => {
    return Promise.resolve();
  };

  callAnsweredCallback = (roomId: string, answeredName: string, answeredUserId: string) => {
    return Promise.resolve();
  };

  startCallCallback = (roomId: string) => {
    return Promise.resolve();
  };

  private handleRejectedPromise = (event: PromiseRejectionEvent) => {
    if (typeof event.reason === "object") {
      if (event.reason.name && event.reason.name === "TwilioError")
        if (event.reason.message === "Room not found") {
          this.setState((state) => ({
            invalidRoom: true,
          }));
        }
    }
  };

  render() {
    if (this.state.returnToCallCenter) {
      return <Redirect to={`/`} />;
    }
    if (!this.roomId) {
      logger.error("No roomId specified in RoomRouter");
      return <p>Unable to make call.</p>;
    }

    if (this.state.invalidRoom) {
      logger.error("Invalid roomid");
      return <p>Unable to answer call.</p>;
    }

    let modalContents: JSX.Element | null = null;
    return (
      <React.Fragment>
        <Room authService={authService} roomId={this.roomId} roomAdapter={this} />
        {modalContents}
      </React.Fragment>
    );
  }
}
