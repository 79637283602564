import React, { Component } from 'react';
import { VideoManager, VideoManagerEvents } from './VideoManager';

export interface ToggleVideoButtonProps {
	videoManager: VideoManager | null
}

interface ToggleVideoButtonState {
	isVideoEnabled: boolean
}

export class ToggleVideoButton extends React.Component<Readonly<ToggleVideoButtonProps>, Readonly<ToggleVideoButtonState>> {

	constructor(props: Readonly<ToggleVideoButtonProps>) {
		super(props);
		let videoStatus = true;
		if (props.videoManager) {
			videoStatus = props.videoManager.getVideoStatus();
		}
		this.state = {
			isVideoEnabled: true
		};
	}

	componentDidMount() {
		if (this.props.videoManager) {
			this.props.videoManager.on(VideoManagerEvents.VIDEO_STATUS, this.updateVideoState);
		}
	}

	componentWillUnmount() {
		if (this.props.videoManager) {
			this.props.videoManager.off(VideoManagerEvents.VIDEO_STATUS, this.updateVideoState);
		}
	}

	updateVideoState = (videoEnabled: boolean) => {
		this.setState({
			isVideoEnabled: videoEnabled
		});
	}

	switchVideo = () => {
		if (this.props.videoManager) {
			this.props.videoManager.pauseOrUnpauseVideo();
		}
	}

	render() {
		if (!this.props.videoManager) {
			return null;
		}
		let videoIcon = (
			<i className="fas fa-video fa-fw"></i>
		);
		if (!this.state.isVideoEnabled) {
			videoIcon = (
				<i className="fas fa-video-slash fa-fw"></i>
			);
		}
		return (
			<button type="button" className="btn" onClick={this.switchVideo}>
				{videoIcon}
			</button>
		);
	}
}