import React from 'react';
import { BaseModalState, Modal } from './common/modal/Modal';
import { ModalFooter } from './common/modal/ModalFooter';
import { ModalBody } from './common/modal/ModalBody';
import { ModalHeader } from './common/modal/ModalHeader';

export interface LeaveRoomModalProps {
	leaveRoomCallback(leaveRoom: boolean): Promise<any>
}

export class LeaveRoomModal extends React.Component<Readonly<LeaveRoomModalProps>, Readonly<BaseModalState>> {

	constructor(props: Readonly<LeaveRoomModalProps>) {
		super(props);
		this.state = {
			show: true
		};
	}

	leave = () => {
		this.setState({ show: false });
		this.props.leaveRoomCallback(true);
	}

	stay = () => {
		this.setState({ show: false });
		this.props.leaveRoomCallback(false);
	}

	render() {
		if (!this.state.show) {
			return null;
		}
		return (
			<Modal>
				<ModalHeader title="Leave Call" close={this.stay}></ModalHeader>
				<ModalBody>
					<p>Are you sure you want to leave the call?</p>
				</ModalBody>
				<ModalFooter>
					<a className="mr-auto" role="button" onClick={this.stay}>No, stay connected</a>
					<button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.leave}>
						<i className="fas fa-phone-slash"></i>
						&nbsp;Yes, Leave Call
					</button>
				</ModalFooter>
			</Modal>
		);
	}
}