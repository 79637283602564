import { SettingRequestPayload } from "common/SettingRequestPayload";
import { DeleteUserCallSmsNotificationSetting, UserCallNotificationSetting, UserCallSmsNotificationSetting } from "common/settings/UserCallNotificationSetting";

export class ApiRequestFactories {
	static createSettingRequest(system: string, category: string, action: string, value: string | boolean, name: string, subSystem?: string) {
		return new SettingRequestPayload(system, category, action ,value, name, subSystem);
	}

	static createUserIncomingCallSettingRequest(value: string | boolean) {
		return new UserCallNotificationSetting(value);
	}

	static createUserIncomingCallSmsSetting(value: string) {
		return new UserCallSmsNotificationSetting(value);
	}

	static createDeleteUserIncomingCallSmsSetting() {
		return new DeleteUserCallSmsNotificationSetting();
	}
}
