import React from 'react';
import { Col, Row, Spinner } from 'reactstrap';

interface LoadingIndicatorProps {
    loadingMessage?: string;
}

export const LoadingIndicator = ({loadingMessage}: LoadingIndicatorProps) => {
    return (
        <Row>
            <Col className="d-flex justify-content-center">
                <Spinner>
                    {loadingMessage ?? 'Loading...'}
                </Spinner>
            </Col>
        </Row>
    )
}