import { datadogLogs } from "@datadog/browser-logs";

export interface LogContext {
  [x: string]: LogContextValue;
}
export declare type LogContextValue = string | number | boolean | LogContext | LogContextArray | undefined;
export interface LogContextArray extends Array<LogContextValue> {}

datadogLogs.init({
  // clientToken is public so it is alright to store here directly
  // if we ever create a runtime config, this could be moved into it
  clientToken: "pubd706080d8d57385d27d0ecc91f4fc31c",
  site: "datadoghq.com",
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: "Telehealth-ClientApp",
});

// set service as an attribute
datadogLogs.setLoggerGlobalContext({ service: "TeleHealth-ClientApp" });

export class Logger {
  debug(message: string, context?: LogContext | undefined) {
    datadogLogs.logger.debug(message, context);
  }

  error(message: string, context?: LogContext | undefined) {
    datadogLogs.logger.error(message, context);
  }

  info(message: string, context?: LogContext | undefined) {
    datadogLogs.logger.info(message, context);
  }

  warn(message: string, context?: LogContext | undefined) {
    datadogLogs.logger.warn(message, context);
  }
}

const logger = new Logger();

export default logger;
