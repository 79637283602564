import { ApiUrl } from "ApiConstants";

export default class AnonymousApiRequest {
	async get(url: string) {
		let response = await fetch(url);

		return response;
	}
	
	async post<TRequest>(url: string, request: TRequest) {
		if(!url) {
			throw new Error('URL was not provided');
		}

		if(!request) {
			throw new Error("request was not provided");
		}

		let response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(request),
			headers: {
				"Content-Type": "application/json"
			}
		});

		return response;
	}
}