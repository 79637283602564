import React, { PropsWithChildren } from 'react'

export interface ClickableTextProps {
	classes?: string;
	onClicked: () => void;		//The callback which will be invoked when this is clicked
}

/**********************
 * Creates a div styled to look like an anchor. Primarily used since the HTML spec says that 
 * anchors with href's need to go somewhere which can be navigated via the address bar in the
 * browser
 ****************/
export const ClickableText = ({classes, onClicked, children}: PropsWithChildren<ClickableTextProps>) => {
	return (
		<div className={classes ? `clickable-text ${classes}` : 'clickable-text'} onClick={() => onClicked()} role="link">{children}</div>
	)
}