import { UserManager, WebStorageStateStore, User } from "oidc-client";
import { ApplicationName } from "./ApiAuthorizationConstants";
import { IAuthService } from "telehealth-abstractions";

interface Callback {
  callback: Function;
  subscription: number;
}

export enum AuthenticationResultStatus {
  Redirect = "redirect",
  Success = "success",
  Fail = "fail",
}

interface AuthorizeSuccessResult {
  status: AuthenticationResultStatus.Success;
  state: any;
}

interface AuthorizeFailureResult {
  status: AuthenticationResultStatus.Fail;
  message: string;
}

interface AuthorizeRedirectResult {
  status: AuthenticationResultStatus.Redirect;
}

export type AuthorizeResult = AuthorizeSuccessResult | AuthorizeFailureResult;

export class AuthorizeService implements IAuthService {
  private _callbacks: Array<Callback> = [];
  private _nextSubscriptionId = 0;
  private _user: User | null | undefined = null;

  private _onSignedInCallbacks: Array<Callback> = [];
  private _onSignedInSubId = 0;
  private _beforeSignOutCallbacks: Array<Callback> = [];
  private _beforeSignOutSubId = 0;

  _isAuthenticated = false;

  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  _popUpDisabled = true;

  userManager: UserManager | undefined;

  async isAuthenticated() {
    await this.ensureUserManagerInitialized();

    const user = await this.userManager!.getUser();
    return !!user && !user!.expired;
  }

  async getUser() {
    if (this._user && this._user.profile) {
      return this._user.profile;
    }

    await this.ensureUserManagerInitialized();

    const user = await this.userManager!.getUser();
    return user && user.profile;
  }

  async getUserId() {
    const user = await this.getUser();
    if (!user) {
      return null;
    }
    return user.sub;
  }

  async getUserPreferredName() {
    const user = await this.getUser();
    if (!user) {
      return "";
    }
    return user.preferred_username || "";
  }

  async getAccessToken() {
    await this.ensureUserManagerInitialized();
    const user = await this.userManager!.getUser();
    return user && user.access_token;
  }

  // We try to authenticate the user in three different ways:
  // 1) We try to see if we can authenticate the user silently. This happens
  //    when the user is already logged in on the IdP and is done using a hidden iframe
  //    on the client.
  // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
  //    redirect flow.
  async signIn(state: any): Promise<AuthorizeResult | AuthorizeRedirectResult> {
    await this.ensureUserManagerInitialized();
    try {
      const silentUser = await this.userManager!.signinSilent(this.createArguments());
      this.updateState(silentUser);
      return this.success(state);
    } catch (silentError) {
      // User might not be authenticated, fallback to popup authentication
      console.log("Silent authentication error: ", silentError);

      try {
        if (this._popUpDisabled) {
          throw new Error(
            "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
          );
        }

        const popUpUser = await this.userManager!.signinPopup(this.createArguments());
        this.updateState(popUpUser);
        return this.success(state);
      } catch (popUpError) {
        if (typeof popUpError === "object" && popUpError?.message) {
          if (popUpError.message === "Popup window closed") {
            // The user explicitly cancelled the login action by closing an opened popup.
            return this.error("The user closed the window.");
          } else if (!this._popUpDisabled) {
            console.log("Popup authentication error: ", popUpError);
          }
        }

        // PopUps might be blocked by the user, fallback to redirect
        try {
          await this.userManager!.signinRedirect(this.createArguments(state));
          return this.redirect();
        } catch (redirectError) {
          console.log("Redirect authentication error: ", redirectError);
          return this.error(redirectError);
        }
      }
    }
  }

  async completeSignIn(url: string): Promise<AuthorizeResult> {
    try {
      if (!this.userManager) await this.ensureUserManagerInitialized();
      const user = await this.userManager!.signinCallback(url);

      for (let signInCompletion of this._onSignedInCallbacks) {
        await signInCompletion.callback();
      }

      this.updateState(user);
      return this.success(user && user.state);
    } catch (error) {
      console.log("There was an error signing in: ", error);
      return this.error("There was an error signing in.");
    }
  }

  // We try to sign out the user in two different ways:
  // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
  //    post logout redirect flow.
  async signOut(state: any): Promise<AuthorizeResult | AuthorizeRedirectResult> {
    await this.ensureUserManagerInitialized();
    try {
      if (this._popUpDisabled) {
        throw new Error(
          "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
        );
      }

      for (let beforeSignOut of this._beforeSignOutCallbacks) {
        await beforeSignOut.callback();
      }

      await this.userManager!.signoutPopup(this.createArguments());
      this.updateState(undefined);
      return this.success(state);
    } catch (popupSignOutError) {
      console.log("Popup signout error: ", popupSignOutError);
      try {
        await this.userManager!.signoutRedirect(this.createArguments(state));
        return this.redirect();
      } catch (redirectSignOutError) {
        console.log("Redirect signout error: ", redirectSignOutError);
        return this.error(redirectSignOutError);
      }
    }
  }

  async completeSignOut(url: string): Promise<AuthorizeResult> {
    await this.ensureUserManagerInitialized();
    try {
      const response = await this.userManager!.signoutCallback(url);
      this.updateState(null);

      this._beforeSignOutCallbacks = [];
      this._beforeSignOutSubId = 0;
      this._onSignedInCallbacks = [];
      this._onSignedInSubId = 0;

      return this.success(response && response.state);
    } catch (error) {
      console.log(`There was an error trying to log out '${error}'.`);
      return this.error(error);
    }
  }

  async loginSilently(url: string) {
    await this.ensureUserManagerInitialized();
    this.userManager!.signinSilentCallback(url);
  }

  updateState(user: User | undefined | null) {
    this._user = user;
    this._isAuthenticated = !!this._user;
    this.notifySubscribers();
  }

  subscribe(callback: Function) {
    this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
    return this._nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId: number) {
    const subscriptionIndex = this._callbacks
      .map((element, index) => (element.subscription === subscriptionId ? { found: true, index } : { found: false }))
      .filter((element) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
    }

    this._callbacks.splice(subscriptionIndex[0].index!, 1);
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback;
      callback();
    }
  }

  createArguments(state?: any) {
    return { useReplaceToNavigate: true, data: state };
  }

  error(message: string): AuthorizeFailureResult {
    return { status: AuthenticationResultStatus.Fail, message };
  }

  success(state: any): AuthorizeSuccessResult {
    return { status: AuthenticationResultStatus.Success, state };
  }

  redirect(): AuthorizeRedirectResult {
    return { status: AuthenticationResultStatus.Redirect };
  }

  async ensureUserManagerInitialized() {
    if (this.userManager) {
      return;
    }

    //hard coding the OIDC settings now since multiple calls are made to our reactauth configuration endpoint
    //during rerenders
    const host = window.location.origin;
    const settings = {
      authority: host,
      client_id: "reactauth",
      redirect_uri: `${host}/authentication/login-callback`,
      automaticSilentRenew: true,
      silent_redirect_uri: `${host}/authentication/silent-callback`,
      includeIdTokenInSilentRenew: true,
      post_logout_redirect_uri: `${host}/authentication/logout-callback`,
      response_type: "code",
      scope: "email name preferred_username role __tenant__ ImageTrend.Telemedicine.WebAppAPI openid profile",
      userStore: new WebStorageStateStore({
        prefix: ApplicationName,
      }),
    };

    this.userManager = new UserManager(settings);

    this.userManager.events.addUserSignedOut(async () => {
      await this.userManager!.removeUser();
      this.updateState(undefined);
    });
  }

  async accessTokenExpired() {
    if (!!!this.userManager) {
      return true;
    }

    const user = await this.userManager!.getUser();

    return user?.expired ?? true;
  }

  static get instance() {
    return authService;
  }

  afterSignIn(callback: () => Promise<void>) {
    this._onSignedInCallbacks.push({
      callback: callback,
      subscription: ++this._onSignedInSubId,
    });
  }

  onSignOut(callback: () => Promise<void>) {
    this._beforeSignOutCallbacks.push({ callback: callback, subscription: ++this._beforeSignOutSubId });
  }
}

const authService = new AuthorizeService();

export default authService;
