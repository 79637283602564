export interface AccountStatusState {
	active(onActivating: () => void): AccountStatusState;
	inactive(onDeactivating: () => void): AccountStatusState;
}

export class ActiveStatusState implements AccountStatusState {
	active(onActivating: () => void) {
		return this;
	}

	inactive(onDeactivating: () => void) {
		if(!!!onDeactivating) {
			throw new Error("onDeactivating callback not provided");
		}

		onDeactivating();

		return new InactiveStatusState();
	}
}

export class InactiveStatusState implements AccountStatusState {
	active(onActivating: () => void) {
		if(!!!onActivating) {
			throw new Error("onActivating callback not provided");
		}

		onActivating();

		return new ActiveStatusState();
	}

	inactive(onDeactivating: () => void) {
		return this;
	}
}