import { SecurePerson } from "../Person";

export class UserDemographics {
  prefix?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  suffix?: string;
  emailAddress?: string;
  phoneNumber?: string;

  static noDemographics() {
    const demos = new UserDemographics();
    demos.prefix = "";
    demos.firstName = "";
    demos.lastName = "";
    demos.middleName = "";
    demos.suffix = "";
    demos.emailAddress = "";
    demos.phoneNumber = "";

    return demos;
  }

  static fromUser(person: SecurePerson) {
    let demographics = new UserDemographics();

    demographics.firstName = person.firstName;
    demographics.middleName = person.middleName;
    demographics.lastName = person.lastName;
    demographics.prefix = person.namePrefix;
    demographics.suffix = person.nameSuffix;
    demographics.emailAddress = person.email;

    person.reachableByPhoneAt((phoneNumber) => (demographics.phoneNumber = phoneNumber));
  }
}
