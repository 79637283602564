import Message from 'common/Message';
import { MessageId }  from 'common/Messaging';

class VoidMessage extends Message {
    constructor () {
        super(MessageId.voidMessage);
    }
}

//singleton instance
const instance = new VoidMessage();
export default instance;