import React, { useState, ReactNode } from "react";
import NavMenuContext, { INavMenuContext } from "./NavMenuContext";

interface NavMenuContextProviderProps {
  children?: ((ctx: INavMenuContext) => ReactNode) | ReactNode;
}

export const NavMenuContextProvider = ({ children }: NavMenuContextProviderProps) => {
  const [showNav, setShowNav] = useState<boolean>(true);

  const navMenuProvider = {
    navVisible: showNav,
    showNav: () => setShowNav(true),
    hideNav: () => setShowNav(false),
  } as INavMenuContext;

  if (typeof children === "function") {
    return <NavMenuContext.Provider value={navMenuProvider}>{children(navMenuProvider)}</NavMenuContext.Provider>;
  }

  return <NavMenuContext.Provider value={navMenuProvider}>{children}</NavMenuContext.Provider>;
};
