export default class SettingStrings {
    static readonly userIncomingCallAudioNotification = 'User Incoming Call Notification';
    static readonly userIncomingCallSmsNotification = "User Incoming Call SMS Notification";

    static readonly callSystem = 'Call';
    static readonly notificationSubSystem = 'Notification';
    static readonly userCategory = 'User';

    static readonly deleteAction = 'delete';
    static readonly modifyAction = 'modify';
}