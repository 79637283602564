import React, { Component } from 'react';
import { AudioTrackPublication, LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import { Track } from './TrackSelector';

type TwilioAudioTrack = RemoteAudioTrack | LocalAudioTrack | null | undefined;

interface AudioCardProps {
	isLocal: boolean,
	track: Track
}

interface AudioCardState {
	audioTrack: TwilioAudioTrack
}

export class AudioCard extends Component<Readonly<AudioCardProps>, Readonly<AudioCardState>> {
	audioRef: HTMLAudioElement | null;

	constructor(props: Readonly<AudioCardProps>) {
		super(props);
		this.state = {
			audioTrack: props.track as TwilioAudioTrack
		};
		this.audioRef = null;
	}

	componentWillUnmount() {
		this.detachTrack(this.state.audioTrack);
	}

	componentDidUpdate(prevProps: AudioCardProps) {
		const newAudioTrack = this.props.track as TwilioAudioTrack;
		if (newAudioTrack !== this.state.audioTrack) {
			this.setState((state) => {
				this.detachTrack(state.audioTrack);
				this.attachTrack(newAudioTrack);
				return {
					audioTrack: newAudioTrack
				};
			});
		}
	}

	detachTrack(audioTrack: TwilioAudioTrack) {
		if (this.audioRef) {
			this.audioRef.removeAttribute('data-cy-audio-track-name');
		}
		if (audioTrack && this.audioRef) {
			audioTrack.detach(this.audioRef);
		}
	}

	attachTrack(audioTrack: TwilioAudioTrack) {
		if (!this.audioRef) {
			return;
		}
		if (!audioTrack) {
			return;
		}
		audioTrack.attach(this.audioRef);
		this.audioRef.setAttribute('data-cy-audio-track-name', audioTrack.name);
	}

	updateAudioRef = (audioRef: HTMLAudioElement) => {
		this.audioRef = audioRef;
		this.attachTrack(this.state.audioTrack);
	}

	render() {
		if (!this.state.audioTrack) {
			return (<p>No Audio!</p>);
		}
		return (
			<audio autoPlay muted={this.props.isLocal} ref={this.updateAudioRef}></audio>
		);
	}
}