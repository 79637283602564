import { FontAwesome } from 'components/Common/FontAwesome';
import React, { HTMLAttributes, PropsWithChildren } from 'react';
import './AnswerCallButton.scss';

export interface AnswerCallButtonProps extends HTMLAttributes<{}> {
    onClick: () => Promise<void>;
}

export const AnswerCallButton = ({children, className, onClick, title}: PropsWithChildren<AnswerCallButtonProps>) => {
    return (
        <div className={`clickable btn-call-center btn-answer-call${className ?  ' ' + className : ''}`} onClick={async () => await onClick()} title={title}>
            <FontAwesome icon="fa-phone"/>
            { children }
        </div>
    )
}