import { IAuthService } from "telehealth-abstractions/build";

export class AuthChangeCheck {
  private _authService: IAuthService;

  constructor(authService: IAuthService) {
    if (!authService) {
      throw new Error("An authService argument was not provided");
    }

    this._authService = authService;
  }

  async checkAuthChanged(
    currentlyAuthenticated: boolean,
    authChangedCallback: (authenticated: boolean) => void,
    authSameCallback?: () => void
  ) {
    const userAuthenticated = await this._authService.isAuthenticated();

    if (userAuthenticated === currentlyAuthenticated) {
      if (authSameCallback) {
        authSameCallback();
      }
    } else {
      authChangedCallback(userAuthenticated);
    }
  }
}
