import Queue from './queue';
import Tree from './tree';
import TreeNode from './treeNode';

export interface ITreeParseFactoryOptions<T> {
	/** 
	 * Defines the name of the property on the object containing the name of the root object which will be held in a [[`TreeNode<T>``]].
	 * For example, given an object 
	 * ```
	 * {
	 * 	 root: {data: 'A'},
	 * 	 children: []
	 * }
	 * ```
	 * 
	 * The nodeDataName would be 'data'.
	 */
	rootNodeName: string;

	/** 
	 * Defines the name of the property on the object containing the data which will be held in a [[`TreeNode<T>``]].
	 * For example, given an object 
	 * ```
	 * {
	 * 	 root: {data: 'A'},
	 * 	 children: []
	 * }
	 * ```
	 * 
	 * The nodeDataName would be 'data'.
	 */
	nodeDataName: string;

	/** 
	 * Defines the name of the property on the object containing the child nodes of the current node.
	 * For example, given an object 
	 * ```
	 * {
	 * 	 root: {data: 'A',
	 * 	 children: [
	 * 		{
	 * 		 data: 'B',
	 * 		 children: []
	 * 		}
	 * 	 ]}
	 * }
	 * ```
	 * 
	 * The childrenCollectionName would be 'children'.
	 */
	childrenCollectionName: string;

	/**
	 * Defines a factory function which is used to create the data which will be stored at each node.
	 */
	dataFactory: ((source: any) => T);

	nodesEqual: ((first: TreeNode<T>, second: TreeNode<T>) => boolean);
}

/**
 * An instance of TreeBuilder<T> represents an item that knows how to parse an arbitrary JavaScript object
 * that has a tree-like shape and create a Tree<T>. It uses the provided [[`ITreeParseOptions`]] to configure the 
 * root object, the data entry, and children from the source so that the builder knows how to parse the 
 * object to create the tree.
 */
export default class TreeBuilder<T> {
	private readonly unparsedTree: any;
	private readonly parseOptions: ITreeParseFactoryOptions<T>;

	/**
	 * 
	 * @param unparsedTree the object that will be parsed to construct the tree.
	 * @param parseOptions an instance of ITreeParseOptions defining the structure of the unparsedTree provided.
	 */
	constructor(unparsedTree: any, parseOptions: ITreeParseFactoryOptions<T>) {
		if(!unparsedTree) {
			throw new Error('An undefined object was passed to this treeBuilder. Provide an initializedJson object to use this treeBuilder.');
		}

		if(!parseOptions) {
			throw new Error('Uninitialized parse options were passed to this treeBuilder. Provide initialized options to use this treeBuilder.');
		}

		this.unparsedTree = unparsedTree;
		this.parseOptions = parseOptions;
	}

	/**
	 * Creates the tree from the unparsed object provided to the constructor.
	 * 
	 * @returns a Tree<T> resulting from parsing, or null if the object provided to the
	 * 			constructor could not be parsed.
	 */
	parse(): Tree<T> | null {
		var queue = new Queue<T>();

		var current: any | null = this.unparsedTree[this.parseOptions.rootNodeName];
		var tree: Tree<T> | null = null;

		while(current) {
			var data = this.parseOptions.dataFactory(current)

			//Have we processed this node as a child in the previous iteration?
			var processedChild: TreeNode<T> = new TreeNode<T>(data);
			var treeNode: TreeNode<T> | null = null;

			/*
			* The tree is not initialized; this has to be the root which means we
			* haven't processed this node previously.
			*/
			if(!tree) {
				tree = new Tree<T>(processedChild);
				treeNode = processedChild;
			} else {
				treeNode = tree.find(processedChild, this.parseOptions.nodesEqual);
				if(!treeNode) {
					//This shouldn't ever happen, but erring on the side of caution
					throw new Error('A previously processed child node was not found in the tree being built. Ensure the tree json being parsed is well formed.');
				}
			}

			var children = current[this.parseOptions.childrenCollectionName] || [];

			for(let child of children) {
				var childData = this.parseOptions.dataFactory(child);
				var childNode = new TreeNode<T>(childData);

				if(treeNode) {
					treeNode.addChild(childNode);
				}

				queue.enqueue(child);
			}
			
			if(queue.peek()) {
				current = queue.dequeue();
			} else {
				current = null;
			}
		}

		return tree;
	}

	parseModel(dataFactory: (source: any) => T): Tree<T> | null {
		var queue = new Queue<T>();

		var current: any | null = this.unparsedTree[this.parseOptions.rootNodeName];
		var tree: Tree<T> | null = null;

		while(current) {
			var data = dataFactory(current[this.parseOptions.nodeDataName]);

			//Have we processed this node as a child in the previous iteration?
			var processedChild: TreeNode<T> = new TreeNode<T>(data);
			var treeNode: TreeNode<T> | null = null;

			/*
			* The tree is not initialized; this has to be the root which means we
			* haven't processed this node previously.
			*/
			if(!tree) {
				tree = new Tree<T>(processedChild);
				treeNode = processedChild;
			} else {
				treeNode = tree.find(processedChild, this.parseOptions.nodesEqual);
				if(!treeNode) {
					//This shouldn't ever happen, but erring on the side of caution
					throw new Error('A previously processed child node was not found in the tree being built. Ensure the tree json being parsed is well formed.');
				}
			}

			var children = current[this.parseOptions.childrenCollectionName] || [];

			for(let child of children) {
				var childData = dataFactory(child[this.parseOptions.nodeDataName])
				var childNode = new TreeNode<T>(childData);

				if(treeNode) {
					treeNode.addChild(childNode);
				}

				queue.enqueue(child);
			}

			if(queue.peek()) {
				current = queue.dequeue();
			} else {
				current = null;
			}
		}

		return tree;
	}

}