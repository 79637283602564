import React from "react";
import { Component } from "react";
import authService from "./AuthorizeService";
import { AuthenticationResultStatus } from "./AuthorizeService";
import { LoginActions, QueryParameterNames, ApplicationPaths } from "./ApiAuthorizationConstants";
import { ApiUrl } from "../../ApiConstants";
import { Claims } from "./ApiAuthorizationConstants";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";

interface LoginProps extends RouteComponentProps<{}, StaticContext, { action?: string }> {
  action: string;
  afterLogin?: Iterable<() => Promise<void>>;
  redirectUrl?: string;
}

interface LoginState {
  message: string | undefined | null;
}

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export class Login extends Component<Readonly<LoginProps>, LoginState> {
  constructor(props: Readonly<LoginProps>) {
    super(props);

    this.state = {
      message: undefined,
    };
  }

  componentDidMount() {
    const action = this.props.action ?? this.props.location.state.action;
    const returnUrlState = this.props.redirectUrl ? { returnUrl: this.props.redirectUrl! } : undefined;

    switch (action) {
      case LoginActions.Login:
        this.login(this.getReturnUrl(returnUrlState));
        break;
      case LoginActions.LoginCallback:
        this.processLoginCallback();
        break;
      case LoginActions.LoginFailed:
        const params = new URLSearchParams(window.location.search);
        const error = params.get(QueryParameterNames.Message);
        this.setState({ message: error });
        break;
      case LoginActions.Profile:
        this.redirectToProfile();
        break;
      case LoginActions.Register:
        this.redirectToRegister();
        break;
      case LoginActions.SilentLogin:
        this.loginSilently();
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  }

  render() {
    const action = this.props.action;
    const { message } = this.state;

    if (!!message) {
      return <div>{message}</div>;
    } else {
      switch (action) {
        case LoginActions.Login:
          return <div>Processing login</div>;
        case LoginActions.LoginCallback:
          return <div>Processing login callback</div>;
        case LoginActions.Profile:
        case LoginActions.Register:
        case LoginActions.SilentLogin:
          return <div></div>;
        default:
          throw new Error(`Invalid action '${action}'`);
      }
    }
  }

  async login(returnUrl: string) {
    const state = { returnUrl };
    const result = await authService.signIn(state);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;
      case AuthenticationResultStatus.Success:
        /* Ideally, the userinfo endpoint of idsrv would return the organization claim.
         * But, we're using the MS Convention based implementation of Identity Server, which
         * does not allow identity resource scopes, only api resources. This should be changed
         * but requires configuration changes (and testing), so just lookup the logged in user
         * and grab their org claim that way for now.
         */
        await this.lookupUserOrg((orgId) => localStorage.setItem("orgId", orgId));
        this.navigateToReturnUrl(returnUrl);
        break;
      case AuthenticationResultStatus.Fail:
        this.setState({ message: result.message });
        break;
      default:
        throw new Error("Invalid status result.");
    }
  }

  async processLoginCallback() {
    const url = window.location.href;
    const result = await authService.completeSignIn(url);
    switch (result.status) {
      case AuthenticationResultStatus.Success:
        if (this.props.afterLogin) {
          for (let runAfterLogin of this.props.afterLogin) {
            await runAfterLogin();
          }
        }
        this.navigateToReturnUrl(this.getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        this.setState({ message: result.message });
        break;
      default:
        throw new Error("Invalid authentication result status.");
    }
  }

  getReturnUrl(state?: any) {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error("Invalid return url. The return url needs to have the same origin as the current page.");
    }
    return (state && state.returnUrl) || fromQuery || `${window.location.origin}/home`;
  }

  redirectToRegister() {
    this.redirectToApiAuthorizationPath(
      `${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`
    );
  }

  redirectToProfile() {
    this.redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
  }

  redirectToApiAuthorizationPath(apiAuthorizationPath: string) {
    const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
    // It's important that we do a replace here so that when the user hits the back arrow on the
    // browser he gets sent back to where it was on the app instead of to an endpoint on this
    // component.
    window.location.replace(redirectUrl);
  }

  navigateToReturnUrl(returnUrl: string) {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    window.location.replace(returnUrl);
  }

  private async lookupUserOrg(onIdRetrieved: (orgId: string) => void) {
    const bearerToken = await authService.getAccessToken();

    if (!bearerToken) {
      //something is wrong, this is only called after successful login
      return;
    }

    var lookupResponse = await fetch(`${ApiUrl.UserUrl}/lookupSignedInUser`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    });

    if (lookupResponse.redirected) {
      return;
    }

    if (lookupResponse.status === 200) {
      let userLookup = await lookupResponse.json();

      let orgClaim = userLookup.claims.filter((x: any) => x.type === Claims.Organization);

      if (orgClaim && orgClaim[0]) {
        onIdRetrieved(orgClaim[0].value);
      }
    }
  }

  private async loginSilently() {
    const url = window.location.href;

    authService.loginSilently(url);
  }
}
