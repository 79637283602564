import React, { Component } from 'react';
import { connect, createLocalVideoTrack, Room as TwilioRoom, LocalVideoTrack, Participant as TwilioParticipant } from 'twilio-video';
import { ParticipantInfo } from './ParticipantInfo';
import { deviceManager, DeviceManagerEvents, DeviceInfo } from './DeviceManager';
import { AudioManager } from './AudioManager';
import { ChatManager } from './ChatManager';
import { VideoManager } from './VideoManager';
import { RoomManager, RoomManagerEvents } from './RoomManager';
import { LeaveRoomModal } from './LeaveRoomModal';
import { RoomHeader } from './RoomHeader';
import { RoomBody } from './RoomBody';
import { ChatSlider } from './ChatSlider';
import { SettingsSlider } from './SettingsSlider';
import './CallHeader.css';
import './Room.css';
import { IAuthService, IRoomAdapter, IUserService } from 'telehealth-abstractions';
import { urlHelper, UserService } from 'telehealth-api';

enum CallState {
	LOADING = 0,
	GET_USER_INFO = 1,
	POPULATE_INPUT_DEVICES = 2,
	CHAT_CONNECTING = 4,
	GET_TOKEN = 5,
	CONNECTING = 6,
	CONNECTED = 7,
	DISCONNECTING = 8,
	DISCONNECTED = 9,
}

interface RoomProps {
	authService: IAuthService,
	roomAdapter: IRoomAdapter,
	roomId: string
}

interface RoomState {
	token: string | null,
	callState: CallState,
	roomId: string | null,
	userId: string | null | undefined,
	audioDevices: Array<DeviceInfo>,
	videoDevices: Array<DeviceInfo>,
	participants: Array<ParticipantInfo>,
	showLeaveRoomModal: boolean
}

interface EventHandler {
	target: EventTarget,
	eventName: string,
	listener: EventListener
}

export class Room extends Component<Readonly<RoomProps>, Readonly<RoomState>> {
	selectedAudioDeviceId: string | null = null;
	selectedVideoDeviceId: string | null = null;
	room: TwilioRoom | null = null;
	eventHandlers: Array<EventHandler> = [];
	videoManager: VideoManager | null = null;
	audioManager: AudioManager | null = null;
	chatManager: ChatManager;
	roomManager: RoomManager;
	authService: IAuthService;
	callHasEnded: boolean = false;
	callHasBeenAnswered: boolean = false;
	userService: IUserService;

	constructor(props: Readonly<RoomProps>) {
		super(props);
		this.state = {
			token: null,
			callState: CallState.LOADING,
			roomId: props.roomId,
			userId: null,
			audioDevices: [],
			videoDevices: [],
			participants: [],
			showLeaveRoomModal: false
		};
		this.authService = props.authService;
		this.userService = new UserService(this.authService);
		this.chatManager = new ChatManager(this.authService);
		this.roomManager = new RoomManager();
	}

	componentDidMount() {
		this.setState({ callState: CallState.GET_USER_INFO });
		this.getUserInfo();
	}

	componentWillUnmount() {
		if (this.chatManager) {
			this.chatManager.disconnect();
		}
		deviceManager.off(DeviceManagerEvents.SELECTED_CAMERA_CHANGED, this.cameraChanged);
		deviceManager.off(DeviceManagerEvents.SELECTED_MICROPHONE_CHANGED, this.microphoneChanged);
	}

	isInIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	showLeaveRoomModal = () => {
		this.setState({
			showLeaveRoomModal: true
		});
	}

	leaveRoomCallback = async (leaveRoom: boolean) => {
		this.setState({
			showLeaveRoomModal: false
		});
		if (!leaveRoom) {
			return;
		}
		this.disconnect();
		await this.endCall();
		this.props.roomAdapter.leaveRoom();
	}

	async endCall() {
		if (!this.callHasEnded) {
			await this.props.roomAdapter.endCallCallback(this.props.roomId);
			this.callHasEnded = true;
		}
	}

	async initializeChat() {
		await this.chatManager.connectAsync();
		this.setState({ callState: CallState.GET_TOKEN });
		this.populateToken();
	}

	renderRoom() {
		let modalContents: JSX.Element | null = null;
		if (this.state.showLeaveRoomModal) {
			modalContents = (<LeaveRoomModal leaveRoomCallback={this.leaveRoomCallback} />);
		}
		return (
			<>
				<div className="room">
					<RoomHeader audioManager={this.audioManager} roomManager={this.roomManager} videoManager={this.videoManager} showLeaveRoomModal={this.showLeaveRoomModal}></RoomHeader>
					<RoomBody participants={this.state.participants}></RoomBody>
					<ChatSlider roomManager={this.roomManager} chatManager={this.chatManager} currentUserId={this.state.userId} roomId={this.state.roomId}></ChatSlider>
					<SettingsSlider roomManager={this.roomManager} deviceManager={deviceManager}></SettingsSlider>
				</div>
				{modalContents}
			</>
		);
	}

	render() {
		switch (this.state.callState) {
			default:
			case CallState.LOADING:
				return (<p><em>Loading...</em></p>);
			case CallState.GET_USER_INFO:
				return (<p><em>Getting user info...</em></p>);
			case CallState.POPULATE_INPUT_DEVICES:
				return (<p><em>Getting input devices...</em></p>);
			case CallState.CHAT_CONNECTING:
				return (<p><em>Connecting to chat...</em></p>);
			case CallState.GET_TOKEN:
				return (<p><em>Getting token...</em></p>);
			case CallState.CONNECTING:
				return (<p><em>Connecting...</em></p>);
			case CallState.CONNECTED:
				return this.renderRoom();
			case CallState.DISCONNECTING:
				return (<p><em>Disconnecting...</em></p>);
			case CallState.DISCONNECTED:
				return (<p><em>Disconnected...</em></p>);
		}
	}

	async getUserInfo() {
		const userId = await this.authService.getUserId();
		this.setState({ userId: userId, callState: CallState.POPULATE_INPUT_DEVICES });
		this.populateInputDevices();
	}

	async populateInputDevices() {
		let devices = await deviceManager.getDevicesAsync();
		if (devices.selectedAudioDevice && devices.selectedVideoDevice) {
			// we have default devices, lets use them
			this.selectedAudioDeviceId = devices.selectedAudioDevice?.deviceId;
			this.selectedVideoDeviceId = devices.selectedVideoDevice?.deviceId;
		}
		deviceManager.on(DeviceManagerEvents.SELECTED_CAMERA_CHANGED, this.cameraChanged);
		deviceManager.on(DeviceManagerEvents.SELECTED_MICROPHONE_CHANGED, this.microphoneChanged);
		this.setState({ audioDevices: devices.audioDevices, videoDevices: devices.videoDevices, callState: CallState.CHAT_CONNECTING });
		this.initializeChat();
	}

	cameraChanged = async (deviceId: string) => {
		await this.videoManager?.changeCamera(deviceId);
	}

	microphoneChanged = async (deviceId: string) => {
		await this.audioManager?.changeMicrophone(deviceId);
	}

	async populateToken() {
		let apiToken = await this.authService.getAccessToken();
		let response = await fetch(urlHelper.resolveUrl(`api/v1.0/token?roomName=${this.state.roomId}`), {
			headers: !apiToken ? {} : { 'Authorization': `Bearer ${apiToken}` },
			method: 'POST'
		});
		let data = await response.json();
		this.setState({ token: data.token, callState: CallState.CONNECTING });
		this.selectAndJoinRoom();
	}

	disconnect() {
		if (this.videoManager) {
			this.videoManager.pauseVideo();
		}
		if (!this.room) {
			return;
		}
		this.room.disconnect();
	}

	onDisconnected(room: TwilioRoom, error: Error) {
		this.removeAllEventListeners();
		// TODO: reset participants and room state
		this.setState({
			token: null,
			callState: CallState.DISCONNECTED,
			roomId: this.state.roomId,
			userId: null,
			audioDevices: [],
			videoDevices: [],
			participants: []
		});
	}

	async handleVisibilityChange() {
		if (!this.videoManager) {
			return;
		}
		if (document.visibilityState === 'hidden') {
			this.videoManager.pauseVideo();
		} else {
			await this.videoManager.resumeVideo();
		}
	}

	isMobile = (() => {
		if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
			return false;
		}
		return /Mobile/.test(navigator.userAgent);
	})();

	addEventListener(target: EventTarget, eventName: string, listener: EventListener) {
		this.eventHandlers.push({
			target,
			eventName,
			listener
		});
		target.addEventListener(eventName, listener);
	}

	removeAllEventListeners() {
		this.eventHandlers.forEach(e => e.target.removeEventListener(e.eventName, e.listener));
	}

	async callAnswered(userId: string) {
		let userInfo = await this.userService.getUserInfo(userId);
		this.props.roomAdapter.callAnsweredCallback(this.props.roomId, userInfo.name, userId);
	}

	/* Participant */
	participantConnected(participant: TwilioParticipant) {
		if (!this.room) {
			return;
		}
		let isLocalParticipant = participant === this.room.localParticipant;
		if (this.state.participants.some(p => participant.sid === p.sid)) {
			console.log('Participant reconnected ' + participant.sid);
			// they are already in here
			let updatedParticipants = this.state.participants.concat([]);
			updatedParticipants[updatedParticipants.findIndex(p => p.sid === participant.sid)] = this.createParticipant(participant);
			this.setState({ participants: updatedParticipants });
			return;
		}
		if (!isLocalParticipant && !this.callHasBeenAnswered) {
			this.callAnswered(participant.identity);
			this.callHasBeenAnswered = true;
		}
		console.log('Participant connected ' + participant.sid);
		// handle any tracks that are added later
		this.setState({ participants: this.state.participants.concat([this.createParticipant(participant)]) });
	}

	participantDisconnected(participant: TwilioParticipant) {
		if (this.state.participants.some(p => participant.sid === p.sid)) {
			// they are already in here
			let updatedParticipants = this.state.participants.concat([]);
			updatedParticipants.splice(updatedParticipants.findIndex(p => p.sid === participant.sid), 1);
			this.setState({ participants: updatedParticipants });
			this.endCall();
			return;
		}
	}

	setCurrentActiveParticipant() {
		//TODO
	}
	/* END Participant */

	async selectAndJoinRoom() {
		// select microphone
		// select camera
		// get a token
		// setup the connect options
		if (!this.selectedAudioDeviceId) {
			throw new Error('No audio device selected when trying to connect to a room.');
		}
		if (!this.selectedVideoDeviceId) {
			throw new Error('No video device selected when trying to connect to a room.');
		}
		let connectOptions = {
			audio: {
				deviceId: {
					exact: this.selectedAudioDeviceId
				}
			},
			name: this.state.roomId,
			video: {
				deviceId: {
					exact: this.selectedVideoDeviceId
				}
			}
		};
		if (!this.state.token) {
			throw new Error('Missing token when trying to connect to a room.');
		}
		// connect to the room
		this.room = await connect(this.state.token, connectOptions);
		this.videoManager = new VideoManager(this.room.localParticipant, this.selectedVideoDeviceId);
		this.audioManager = new AudioManager(this.room.localParticipant);
		// subscribe to participants
		this.participantConnected(this.room.localParticipant);
		this.room.participants.forEach(this.participantConnected.bind(this));
		this.room.on('participantConnected', this.participantConnected.bind(this));
		this.room.on('participantDisconnected', this.participantDisconnected.bind(this));
		// setup dominant speaker
		this.setCurrentActiveParticipant();
		this.room.on('dominantSpeakerChanged', this.setCurrentActiveParticipant.bind(this));
		// setup disconnect
		this.addEventListener(window, 'beforeunload', this.disconnect.bind(this));
		if (this.isMobile) {
			this.addEventListener(window, 'onpagehide', this.disconnect.bind(this));
			this.addEventListener(window.document, 'visibilitychange', this.handleVisibilityChange.bind(this));
		}
		this.room.once('disconnected', this.onDisconnected.bind(this));
		this.setState({ callState: CallState.CONNECTED });
		this.props.roomAdapter.startCallCallback(this.props.roomId);
	}

	private createParticipant(participant: TwilioParticipant): ParticipantInfo {
		//createParticipant is only called from participantConnected, so we know room is defined
		let isLocalParticipant = participant === this.room!.localParticipant;

		let p = new ParticipantInfo(participant.identity, participant.sid, isLocalParticipant, participant);
		
		return p;
	}
}