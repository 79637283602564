import React, { Component } from 'react';
import { TeamMember } from 'telehealth-abstractions';

import './CallTreeTeamMember.css';

export interface CallTreeTeamMemberProps {
	createTeamMemberCall: (teamMember: TeamMember) => Promise<void>,
	teamMember: TeamMember
}

export class CallTreeTeamMember extends Component<Readonly<CallTreeTeamMemberProps>, {}> {
	createTeamMemberCall: (teamMember: TeamMember) => Promise<void>;
	teamMember: TeamMember;

	constructor(props: Readonly<CallTreeTeamMemberProps>) {
		super(props);
		this.createTeamMemberCall = props.createTeamMemberCall;
		this.teamMember = props.teamMember;
	}

	render() {
		let statusIcon: JSX.Element | null = null;
		let callButton: JSX.Element | null = null;
		if (this.teamMember.status) {
			statusIcon = (
				<span className="fa-stack status-available status-icon">
					<i className="fas fa-circle fa-stack-2x"></i>
					<i className="fas fa-check fa-stack-1x fa-inverse"></i>
				</span>
				);
			callButton = (<button type="button" className="btn" onClick={this.createTeamMemberCall.bind(this, this.teamMember)}><i className="fa fa-phone"></i>Start Call</button>);
		} else {
			statusIcon = (
				<span className="fa-stack status-unavailable status-icon">
					<i className="fas fa-circle fa-stack-2x fa-inverse"></i>
					<i className="fas fa-clock fa-stack-2x"></i>
				</span>
				);
			callButton = (<button type="button" className="btn btn-disabled">Unavailable</button>);
		}
		return (
			<div className="call-tree-team-member">
				{statusIcon}
				<span className="call-tree-team-member-name">{this.teamMember.firstName} {this.teamMember.lastName}</span>
				{callButton}
			</div>
			);
	}
}