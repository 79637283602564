import React, { Component, Fragment } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink, UncontrolledDropdown } from "reactstrap";
import { Link } from "react-router-dom";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import { AuthContext } from "./AuthContext";
import { AuthChangeCheck } from "../../security/AuthChangeCheck";

interface LoginMenuState {
  isAuthenticated: boolean;
  userName: string | null;
}

interface ToLinkParam {
  pathname: string;
  state: any;
}

export class LoginMenu extends Component<Readonly<{}>, Readonly<LoginMenuState>> {
  context!: React.ContextType<typeof AuthContext>;

  private _subscription: number | undefined;
  private _mounted: boolean = false;
  private _authChangeCheck!: AuthChangeCheck;

  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      isAuthenticated: false,
      userName: null,
    };
  }

  async componentDidMount() {
    this._mounted = true;
    this._authChangeCheck = new AuthChangeCheck(this.context.provider);
    this._subscription = this.context.onAuthenticationChange(() => this.populateState());
    await this.populateState();
  }

  componentWillUnmount() {
    if (this._subscription) {
      this.context.cancelAuthenticationChange(this._subscription);
      this._subscription = undefined;
    }

    this._mounted = false;
  }

  async populateState() {
    if (!this._mounted) {
      return;
    }

    const user = await this.context.user();
    this._authChangeCheck.checkAuthChanged(this.state.isAuthenticated, (updatedAuth: boolean) =>
      this.setState({ isAuthenticated: updatedAuth, userName: user?.name ?? null })
    );
  }

  render() {
    const { isAuthenticated, userName } = this.state;
    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
      return this.authenticatedView(userName, profilePath, logoutPath);
    }
  }

  authenticatedView(userName: string | null, profilePath: string, logoutPath: string | ToLinkParam) {
    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          {userName}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>
            <NavItem>
              <NavLink tag={Link} className="text-dark" to={logoutPath}>
                Logout
              </NavLink>
            </NavItem>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  anonymousView(registerPath: string, loginPath: string) {
    return (
      <Fragment>
        <NavItem>
          <NavLink tag={Link} className="text-dark" to={loginPath}>
            Login
          </NavLink>
        </NavItem>
      </Fragment>
    );
  }
}

LoginMenu.contextType = AuthContext;
