import React, { PropsWithChildren, useContext, useEffect } from "react";
import { NavMenu } from "./Navigation/NavMenu";
import { AuthContext } from "./ApiAuthorization/AuthContext";
import useNavMenu from "./Navigation/useNavMenu";

import "Layout.scss";

interface LayoutProps extends PropsWithChildren<{}> {
  className: string;
}

const Layout = ({ className, children }: LayoutProps) => {
  const { initialize } = useContext(AuthContext);
  const { navVisible } = useNavMenu();

  useEffect(() => {
    const initializeAuth = async () => {
      await initialize();
    };

    initializeAuth();
  }, []);

  return (
    <>
      {navVisible && <NavMenu />}
      <div className={`layout-container ${className}`}>{children}</div>
    </>
  );
};

export default Layout;
