import { ActiveCalls } from 'components/CallCenter/ActiveCalls';
import { CallHistory } from 'components/CallCenter/CallHistory';
import { ConfigureCallSettings } from 'components/CallCenter/ConfigureCallSettings';
import { Panel } from 'components/Common/Panel';
import { UpperContent } from 'components/Common/UpperContent';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Col, Row } from 'reactstrap';
import { CallService } from 'telehealth-api';
import { InviteCallParticipant } from './InviteCallParticipant';
 import useAuthContext from 'components/ApiAuthorization/useAuthContext';

export const CallCenter = (props: RouteComponentProps) => {
	const [externalCallingEnabled, setExternalCallingEnabled] = useState(false);
	const { isAuthenticated, provider } = useAuthContext();

	useEffect(() => {
		const checkExternalCallsFeature = async () => {
			const authed = await isAuthenticated();

			if(!authed) {
				return;
			}

			const token = await provider.getAccessToken();

			let featureResponse = await fetch('/api/v1/feature/externalCalling', {
				headers: {
					'Authorization': `Bearer ${token!}`
				}
			});

			if(featureResponse.ok && (featureResponse.headers.get('Content-Type')?.indexOf('json') ?? -1) > -1) {
				let externalCallingFeature = (await featureResponse.json()) as {enabled: boolean};

				if(externalCallingEnabled !== externalCallingFeature.enabled) {
					setExternalCallingEnabled(externalCallingFeature.enabled);
				}
			}
		}

		checkExternalCallsFeature();
	});

	return (
			<>
				<UpperContent page="Call Center" >
					<ConfigureCallSettings />
				</UpperContent>
				<Row>
				{ externalCallingEnabled && (	
					<>
						<Col xs={12} lg={8}>
							<Panel name="Active Calls">
								<Col className="table-responsive">
									<ActiveCalls callService={new CallService(provider)} {...props}/>
								</Col>
							</Panel>
						</Col>
						<Col className="mt-4 mt-lg-0" xs={12} lg={4}>
							<InviteCallParticipant />
						</Col>
					</>
					 )}
				{ !externalCallingEnabled && (
					<Col xs={12}>
						<Panel name="Active Calls">
							<Col className="table-responsive">
								<ActiveCalls callService={new CallService(provider)} {...props}/>
							</Col>
						</Panel>
					</Col>
				)}	 
				</Row>
				<Row className="mt-5">
					<Col md={12}>
						<Panel name="Past Calls" infoText="Showing the 15 most recent calls">
							<Col className="table-responsive">						
								<CallHistory />					
							</Col>
						</Panel>
					</Col>
				</Row>
			</>
	)
}