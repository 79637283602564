import { ApiUrl } from 'ApiConstants';
import { ApiRequest } from 'common/ApiRequest';
import { ApiRequestFactories } from 'common/ApiRequestFactories';
import { ApiResponse } from 'common/ApiResponse';
import { ApiResponseHandler } from 'common/ApiResponseHandler';
import PhoneNumber from 'common/PhoneNumber';
import { SettingRequestPayload } from 'common/SettingRequestPayload';
import SettingStrings from 'common/settings/SettingStrings';
import { EnabledUserCallNotificationSetting, UserCallNotificationSetting } from 'common/settings/UserCallNotificationSetting';
import { DefaultErrorMessage } from 'components/Common/DefaultErrorMessage';
import { ErrorMessages } from 'components/Common/ErrorMessages';
import { FontAwesome } from 'components/Common/FontAwesome';
import ImageTrendToggle from 'components/Common/ImageTrendToggle';
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Messages } from 'common/Messaging';
import MessagingContext from 'common/messaging/MessagingContext';

import './ConfigureCallSettings.scss';

export const ConfigureCallSettings = () => {
	const [showModal, setShowModal] = useState(false);
	const [playSound, setPlaySound] = useState<boolean>();
	const [sendSms, setSendSms] = useState<boolean>();
	const [settingsSuccessMsgs, setSettingsSuccessMsgs] = useState<string[]>([]);
	const [settingsErrors, setSettingsErrors] = useState<ReactNode[]>([]);
	const [smsNumber, setSmsNumber] = useState<string>();
	const messagingContext = useContext(MessagingContext);

	const settingsHandlers: { [id: string]: (setting:UserCallNotificationSetting) => void} = {
		'User Incoming Call Notification': (setting: UserCallNotificationSetting) => populateAudioAlertSetting(setting),
		'User Incoming Call SMS Notification': (setting: UserCallNotificationSetting) => populateSmsAlertSetting(setting)
	}

	const modifiedSettings = useRef<SettingRequestPayload[]>([]);

	const configureSettingsClicked = () => {
		setShowModal(true);
	}

	const toggleConfigurationModal = () => {
		setShowModal(!setShowModal);
	}

	const handlePlaySoundChange = (checked: boolean) => {
		setPlaySound(checked);

		const settingValue = checked ? "yes" : "no";
		const payload = ApiRequestFactories.createUserIncomingCallSettingRequest(settingValue);
		const audioAlertSettingIndex = modifiedSettings.current.findIndex(setting => setting.name === SettingStrings.userIncomingCallAudioNotification);

		//We have an existing audio alert
		if(audioAlertSettingIndex > -1)	{
			//So replace it
			modifiedSettings.current[audioAlertSettingIndex] = payload;
		} else {
			//Add the audio alert setting to the persist queue
			modifiedSettings.current.push(payload);
		}
	}
	
	const handleSendSmsSettingChange = (sendSms: boolean) => {
		setSendSms(sendSms);
		if (!sendSms) {
			// clear out the phone number if we disable sending via sms
			// this will also mark the sms setting to be deleted when we save
			handleSmsNumberChange(undefined);
	}
	}

	const handleSmsNumberChange = (alertSmsNumber?: string) => {
		const formattedPhone = PhoneNumber.createWithDefaultFormatters().format(alertSmsNumber);
		setSmsNumber(formattedPhone);

		const smsSettingIndex = modifiedSettings.current.findIndex(setting => setting.name === SettingStrings.userIncomingCallSmsNotification);
		
		if(!formattedPhone) {
			let modifiedSmsSetting = ApiRequestFactories.createDeleteUserIncomingCallSmsSetting();
			//remove the sms settings from the settings array if it's currently in it
			if(smsSettingIndex > -1) {
				modifiedSettings.current.splice(smsSettingIndex, 1);
			}
			modifiedSettings.current.push(modifiedSmsSetting);
		} else {
			let modifiedSmsSetting = ApiRequestFactories.createUserIncomingCallSmsSetting(formattedPhone);

			if(!sendSms) {
				modifiedSmsSetting = modifiedSmsSetting.delete();
			}

			modifiedSettings.current[smsSettingIndex] = modifiedSmsSetting;
			//an existing sms setting exists
			if(smsSettingIndex > -1) {
				//so replace it
				modifiedSettings.current[smsSettingIndex] = modifiedSmsSetting;			
			} else {
				modifiedSettings.current.push(modifiedSmsSetting);
			}
		}
	}

	const saveSettingsAsync = async () => {
		setSettingsErrors([]);
		setSettingsSuccessMsgs([]);

		let postSettingsResponse = await new ApiRequest().put<UserCallNotificationSetting[]>(`${ApiUrl.SettingUrl}`, modifiedSettings.current);
		let settingChangeResponse = await ApiResponse.create<{}, {}>(postSettingsResponse, () => {return {}}, () => {return {}});
		
		settingChangeResponse.successful(response => {
			messagingContext.publish(Messages.callAudioAlertChange(), playSound);
			setShowModal(false);
			// clear the modified settings only if we successfully saved the settings
			modifiedSettings.current = [];
		});

		settingChangeResponse.failure(() => setSettingsErrors([...settingsErrors, <DefaultErrorMessage />]));
	}

	useEffect(() => {
		const retrieveCallSettings = async () => {
			let userAlertPreferenceRequest = new ApiRequest();
			let callSettingsResponse = await userAlertPreferenceRequest.post(`${ApiUrl.SettingUrl}/user/search`, new EnabledUserCallNotificationSetting());

			let userAlertResponseHandler = new ApiResponseHandler<{}, UserCallNotificationSetting[]>();
			await userAlertResponseHandler.handle(callSettingsResponse, 
				(responseJson) => {
					for(const settingResponse of responseJson) {
						processSetting(settingResponse);
					}

					return {};
				},
				() => ({}));
		}

		retrieveCallSettings();
	}, []);

	const populateAudioAlertSetting = (settingResponse: UserCallNotificationSetting) => {
		const enableAudioAlert = settingResponse.value === 'yes' ? true : false;
		setPlaySound(enableAudioAlert);
	}

	const populateSmsAlertSetting = (settingResponse: UserCallNotificationSetting) => {
		if (settingResponse.value && settingResponse.value !== '') {
			setSendSms(true);
	}
		setSmsNumber(settingResponse.value as string);
	}

	const processSetting = (setting: UserCallNotificationSetting) => {
		let process = (callSetting: UserCallNotificationSetting) => {};
		if(settingsHandlers[setting.name]) {
			process = settingsHandlers[setting.name];
		}

		process(setting);
	}

	return (
		<>
			<Button className="float-right" color="orange" onClick={() => configureSettingsClicked()}>
				<FontAwesome icon="fa-music" className="mr-2" />Update Call Notifications
			</Button>
			<Modal className="call-settings-modal" isOpen={showModal} toggle={() => toggleConfigurationModal()} size="lg">
				<ModalHeader toggle={() => toggleConfigurationModal()}>
					Incoming Call Notifications
				</ModalHeader>
				<ModalBody>
					{settingsErrors.some(() => true) && <Row>
						<ErrorMessages>
							{settingsErrors}
						</ErrorMessages>
					</Row> }
					<Row>
						<Col sm={{size: 12}} md={{size: 6}}>
							<span id="toggle-sound-text">Play sound for incoming calls</span>
						</Col>
						<Col sm={{size: 12}} md={{size: 3}}>
							<ImageTrendToggle className="mx-auto" onChange={checked => handlePlaySoundChange(checked)} checked={playSound ?? false} aria-labelledby="toggle-sound-text" />
						</Col>
					</Row>
					<Row>
						<Col sm={{size: 12}} md={{size: 6}}>
							<span id="toggle-sms-notification-text">
								Send me a text message for incoming calls
							</span>
						</Col>
						<Col sm={{size: 12}} md={{size: 1}}>
							<ImageTrendToggle onChange={checked => handleSendSmsSettingChange(checked)} checked={sendSms ?? false} />
						</Col>
						{sendSms && <Col sm={{size: 12}} md={{size: 5}}>
							<input name="sms-phone-number" placeholder="(555) 555-5555" onChange={e => handleSmsNumberChange(e.target.value)} type="text" value={smsNumber} />
						</Col> }
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button className="mx-auto" color="green" type="button" onClick={async () => await saveSettingsAsync()}>Save Settings</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}