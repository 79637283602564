import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { NavItem, NavLink } from "reactstrap";
import ImageTrendToggle from "components/Common/ImageTrendToggle";
import { statusService } from "components/SignalR/StatusService";
import { HubStatusMethod } from "telehealth-api";
import { AuthContext } from "components/ApiAuthorization/AuthContext";
import { AuthChangeCheck } from "../../security/AuthChangeCheck";

export const NavPresenceToggle = () => {
  const [userAvailable, setUserAvailable] = useState(true);
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const authContext = useContext(AuthContext);

  const authConfig = useMemo(() => new AuthChangeCheck(authContext.provider), [authContext]);

  let userStatusSub = useRef([] as Array<number>);
  let authChangeSub = useRef([] as Array<number>);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    const refreshUserPresence = async () => {
      //send a status refresh message
      await statusService.refreshStatus();
    };

    const refreshUserAuthStatus = async () => {
      // component can potentially unmount in between the time we call refreshUserAuthStatus
      // and isAuthenticated returns
      if (mounted.current) {
        authConfig.checkAuthChanged(userAuthenticated, (updatedAuth) => setUserAuthenticated(updatedAuth));
      }
    };

    const updatePresenceToggle = async () => {
      const authenticatedUserId = await authContext.provider.getUserId();

      if (!authenticatedUserId) {
        return;
      }

      //listen for user status refresh messages so the presence toggle is set correctly upon page load
      let statusRefreshSub = statusService.subscribe((userId: string, status: string) => {
        if (authenticatedUserId.toUpperCase() !== userId.toUpperCase()) {
          return;
        }

        setUserAvailable((priorAvailability) => {
          const previousStatus = priorAvailability ? "Available" : "Unavailable";
          if (previousStatus.toUpperCase() !== status.toUpperCase()) {
            return status.toUpperCase() === "Available".toUpperCase();
          }

          return priorAvailability;
        });
      }, HubStatusMethod.GetStatus);

      userStatusSub.current.push(statusRefreshSub);

      let authSub = authContext.onAuthenticationChange(async () =>
        authConfig.checkAuthChanged(userAuthenticated, (updatedAuth) => setUserAuthenticated(updatedAuth))
      );

      authChangeSub.current.push(authSub);
    };

    refreshUserAuthStatus();
    updatePresenceToggle();
    refreshUserPresence();

    return () => {
      userStatusSub.current.forEach((statusSub) => statusService.unsubscribe(statusSub));
      authChangeSub.current.forEach((authenticationSub) => authContext.cancelAuthenticationChange(authenticationSub));

      userStatusSub.current = [];
      authChangeSub.current = [];
      mounted.current = false;
    };
  }, [authContext, userAuthenticated, authConfig, userAvailable]);

  const handleAvailabilityChange = async (available: boolean) => {
    //Broadcast user availability change.
    if (available) {
      await statusService.makeUserAvailable();
    } else {
      await statusService.makeUserUnavailable();
    }

    setUserAvailable(available);
  };

  return (
    <>
      {userAuthenticated && (
        <NavItem>
          <NavLink>
            <ImageTrendToggle
              checkedLabel="Available"
              uncheckedLabel="Unavailable"
              checked={userAvailable}
              onChange={async (checked: boolean) => await handleAvailabilityChange(checked)}
            />
          </NavLink>
        </NavItem>
      )}
    </>
  );
};
