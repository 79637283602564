export const ApplicationName = "reactauth";

export enum QueryParameterNames {
  ReturnUrl = "returnUrl",
  Message = "message",
}

export enum LogoutActions {
  LogoutCallback = "logout-callback",
  Logout = "logout",
  LoggedOut = "logged-out",
}

export enum LoginActions {
  Login = "login",
  LoginCallback = "login-callback",
  LoginFailed = "login-failed",
  Profile = "profile",
  Register = "register",
  SilentLogin = "silent-callback",
}

export enum ApplicationPaths {
  DefaultLoginRedirectPath = "/",
  ApiAuthorizationClientConfigurationUrl = "/_configuration/reactauth",
  ApiAuthorizationPrefix = "/authentication",
  ApiBaseUrl = "/api",
  Login = "/login",
  LoginFailed = "/authentication/login-failed",
  LoginCallback = "/authentication/login-callback",
  Register = "/authentication/register",
  Profile = "/authentication/profile",
  LogOut = "/authentication/logout",
  LoggedOut = "/authentication/logged-out",
  LogOutCallback = "/authentication/logout-callback",
  IdentityRegisterPath = "/Identity/Account/Register",
  IdentityManagePath = "/Identity/Account/Manage",
  SecurityApiUrl = "/api/security",
  OrganizationApiUrl = "/api/v1/organization",
  RegistrationInvite = "/first-login",
  SilentRenewCallback = "/authentication/silent-callback",
}

export enum Claims {
  Organization = "__tenant__",
}
