import React, { Component } from 'react';
import { RemoteVideoTrack, LocalVideoTrack } from 'twilio-video';
import { Track } from './TrackSelector';

import './VideoCard.css';

type TwilioVideoTrack = RemoteVideoTrack | LocalVideoTrack | null | undefined;

interface VideoCardProps {
	track: Track,
	isLocal: boolean,
	displayName: string,
	position: string
}

interface VideoCardState {
	videoTrack: TwilioVideoTrack
}

export class VideoCard extends Component<Readonly<VideoCardProps>, Readonly<VideoCardState>> {
	videoRef: HTMLVideoElement | null = null;

	constructor(props: Readonly<VideoCardProps>) {
		super(props);
		this.state = {
			videoTrack: this.props.track as TwilioVideoTrack
		}
	}

	componentDidMount() {
		this.attachTrack(this.state.videoTrack);
	}

	componentWillUnmount() {
		this.detachTrack(this.state.videoTrack);
	}

	detachTrack(videoTrack: TwilioVideoTrack) {
		if (!this.videoRef || !videoTrack) {
			return;
		}
		videoTrack.detach(this.videoRef);
	}

	attachTrack(videoTrack: TwilioVideoTrack) {
		if (!this.videoRef || !videoTrack) {
			return;
		}
		videoTrack.attach(this.videoRef);
	}

	componentDidUpdate(prevProps: VideoCardProps) {
		const newVideoTrack = this.props.track as TwilioVideoTrack;
		if (newVideoTrack !== this.state.videoTrack) {
			this.setState((state) => {
				this.detachTrack(state.videoTrack);
				this.attachTrack(newVideoTrack);
				return {
					videoTrack: newVideoTrack
				};
			})
		}
	}

	updateVideoRef = (videoRef: HTMLVideoElement) => {
		this.videoRef = videoRef;
		this.attachTrack(this.state.videoTrack);
	}

	render() {
		if (!this.state.videoTrack) {
			return (<p>No Video!</p>);
		}
		let containerCss = this.props.isLocal ? "video-card-local-container" : "video-card-remote-container";
		return (
			<div className={containerCss}>
				<video className="video-card" autoPlay muted playsInline ref={this.updateVideoRef}>
				</video>
			</div>
		);
	}

}