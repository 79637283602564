import { IPermission } from '../IPermission';

export class NotGranted implements IPermission {
	granted(onGranted: () => void) { }

	async grantedAsync(onGrantedAsync: () => Promise<void>) { }

	denied(onDenial: () => void) {
		if(!!!onDenial) {
			throw new Error("onDenial was not provided");
		}

		onDenial();
	}

	async deniedAsync(onDenialAsync: () => Promise<void>) {
		if(!onDenialAsync) {
			throw new Error("onDenialAsync was not provided");
		}

		await onDenialAsync();
	}
}