import { Team } from "../Team";

export enum AccountStatus {
	Active = 1,
	Inactive = 2,
}

export class ChangeUserRequestContact {
	readonly email: string;
	phoneNumber?: string;

	constructor(email: string) {
		if (!!!email) {
			throw new Error("Email was not provided");
		}

		this.email = email;
	}
}

export class ChangeUserRequestDemographics {
	readonly firstName: string;
	readonly lastName: string;
	middleName?: string;
	prefix?: string;
	suffix?: string;

	constructor(firstName: string, lastName: string) {
		if (!!!firstName) {
			throw new Error("firstName was not provided");
		}

		if (!!!lastName) {
			throw new Error("lastName was not provided");
		}

		this.firstName = firstName;
		this.lastName = lastName;
	}
}

export class ChangeUserRequestAccount {
	username: string;
	password?: string;
	confirmPassword?: string;
	useEmailForUsername: boolean = false;
	accountStatus: AccountStatus = AccountStatus.Inactive;
	requirePasswordReset: boolean = false;

	private constructor(username: string, password?: string, confirmPassword?: string) {
		this.username = username;
		this.password = password;
		this.confirmPassword = confirmPassword;
	}

	static forAddingUser(){
		let emptyRequest = new ChangeUserRequestAccount("empty");

		emptyRequest.username = "";
		emptyRequest.password = "";
		emptyRequest.confirmPassword = "";

		emptyRequest.useEmailForUsername = true;

		return emptyRequest;
	}

	static forEditingUser(username: string, password?: string, confirmPassword?: string) {
		if (!!!username) {
			throw new Error("username was not provided");
		}

		return new ChangeUserRequestAccount(username, password, confirmPassword);
	}
}

export class ChangeUserRequestAccountSecurity {
	readonly permissionGroup: string;
	readonly teams: Team[] = [];

	constructor(permissionGroup: string, teams?: Team[]) {
		if(!!!permissionGroup) {
			throw new Error("permissionGroup was not provided");
		}
		this.permissionGroup = permissionGroup;

		if(teams && teams.length > 0) {
			this.teams = teams;
		}
	}
}

export class ChangeUserRequest {
	prefix?: string;
	readonly firstName: string;
	readonly lastName: string;
	readonly middleName?: string;
	readonly emailAddress: string;
	phoneNumber?: string;
	suffix?: string;
	id?: string;

	readonly credentials?: ChangeUserRequestAccount

	accountStatus: AccountStatus = AccountStatus.Inactive;

	readonly permissionGroup: string;
	readonly useEmailForUsername: boolean;
	readonly username?: string;
	readonly teams: Team[] = [];

	constructor(
		demographics: ChangeUserRequestDemographics,
		contact: ChangeUserRequestContact,
		security: ChangeUserRequestAccountSecurity,
		account?: ChangeUserRequestAccount,
	) {
		if (!!!demographics) {
			throw new Error("demographics not provided");
		}

		if (!!!contact) {
			throw new Error("contact not provided");
		}

		if (!!!security) {
			throw new Error("security not provided");
		}

		if(!!!security.permissionGroup) {
			throw new Error("permissionGroup not provided");
		}

		this.firstName = demographics.firstName;
		this.lastName = demographics.lastName;
		this.middleName = demographics.middleName;
		this.emailAddress = contact.email;
		this.phoneNumber = contact.phoneNumber;

		this.credentials = account;
		
		this.permissionGroup = security.permissionGroup;

		if(security.teams) {
			this.teams = [...security.teams];
		}

		this.username = account?.username;
		this.useEmailForUsername = account?.useEmailForUsername ?? true;
		this.accountStatus = account?.accountStatus ?? AccountStatus.Inactive;
		this.prefix = demographics.prefix;
		this.suffix = demographics.suffix;
	}
}