import React from "react";
import { Component } from "react";
import authService from "./AuthorizeService";
import { AuthenticationResultStatus } from "./AuthorizeService";
import { QueryParameterNames, LogoutActions, ApplicationPaths } from "./ApiAuthorizationConstants";
import NavMenuContext from "../Navigation/NavMenuContext";

interface LogoutState {
  message: string | undefined;
  isReady: boolean;
  authenticated: boolean;
}

interface LogoutProps {
  action: LogoutActions;
}

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
export class Logout extends Component<Readonly<LogoutProps>, Readonly<LogoutState>> {
  constructor(props: Readonly<LogoutProps>) {
    super(props);

    this.state = {
      message: undefined,
      isReady: false,
      authenticated: false,
    };
  }

  componentDidMount() {
    const action = this.props.action;
    switch (action) {
      case LogoutActions.Logout:
        if (!!window.history.state.state.local) {
          localStorage.removeItem("orgId");
          this.logout(this.getReturnUrl());
        } else {
          // This prevents regular links to <app>/authentication/logout from triggering a logout
          this.setState({ isReady: true, message: "The logout was not initiated from within the page." });
        }
        break;
      case LogoutActions.LogoutCallback:
        this.processLogoutCallback();
        break;
      case LogoutActions.LoggedOut:
        this.context.showNav();
        this.setState({
          isReady: true,
          message: "You have been successfully logged out and your status has automatically been set to unavailable.",
        });
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }

    this.populateAuthenticationState();
  }

  render() {
    const { isReady, message } = this.state;
    if (!isReady) {
      return <div></div>;
    }
    if (!!message) {
      return <p className="text-center text-white mt-3">{message}</p>;
    } else {
      const action = this.props.action;
      switch (action) {
        case LogoutActions.Logout:
          return <p className="text-center text-white mt-3">Processing logout</p>;
        case LogoutActions.LogoutCallback:
          return <p className="text-center text-white mt-3">Processing logout callback</p>;
        case LogoutActions.LoggedOut:
          return <p className="text-center text-white mt-3">{message}</p>;
        default:
          throw new Error(`Invalid action '${action}'`);
      }
    }
  }

  async logout(returnUrl: string) {
    const state = { returnUrl };
    const isauthenticated = await authService.isAuthenticated();
    if (isauthenticated) {
      const result = await authService.signOut(state);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          this.navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          this.setState({ message: result.message });
          break;
        default:
          throw new Error("Invalid authentication result status.");
      }
    } else {
      this.setState({ message: "You successfully logged out!" });
    }
  }

  async processLogoutCallback() {
    const url = window.location.href;
    const result = await authService.completeSignOut(url);
    switch (result.status) {
      case AuthenticationResultStatus.Success:
        this.navigateToReturnUrl(this.getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        this.setState({ message: result.message });
        break;
      default:
        throw new Error("Invalid authentication result status.");
    }
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    this.setState({ isReady: true, authenticated });
  }

  getReturnUrl(state?: any) {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error("Invalid return url. The return url needs to have the same origin as the current page.");
    }
    return (state && state.returnUrl) || fromQuery || `${window.location.origin}${ApplicationPaths.LoggedOut}`;
  }

  navigateToReturnUrl(returnUrl: string) {
    return window.location.replace(returnUrl);
  }
}

Logout.contextType = NavMenuContext;
