import { AccountStatus, 
	ChangeUserRequest, 
	ChangeUserRequestAccount, 
	ChangeUserRequestAccountSecurity, 
	ChangeUserRequestContact, 
	ChangeUserRequestDemographics } from '../user/ChangeUserRequest';
import { NameParts } from '../common/NameParts';
import { OrgUser } from '../organization/OrgUser';
import { RequestBuilder } from '../common/RequestBuilder';
import { Team } from '../Team';
import { SecurityStrings } from '../ApiConstants';

export interface UserConfigurationState {
	adding: () => UserConfigurationState;
	editing: () => UserConfigurationState;
	requireUsername: () => UserConfigurationState;
	useEmailForUsername: () => UserConfigurationState;
	whenEditing: (onEditing: () => void) => void;
	whenAdding: (onAdding: () => void) => void;
	whenEmailUsernameConfigured: (onEmailUsernameConfigured: () => void) => void;
	whenUsernameRequired: (onRequired: () => void) => void;
}

export class EditingUserState implements UserConfigurationState {
	adding() {
		return new AddingUserState();
	} 

	editing() {
		return this;
	}

	useEmailForUsername() {
		return new UseEmailForUsernameState();
	}

	requireUsername() {
		return this;
	}

	whenAdding(onAdding: () => void) {
	}

	whenEditing(onEditing: () => void) {
		onEditing();
	}

	whenEmailUsernameConfigured(onUsingEmail: () => void) {
	}

	whenUsernameRequired(onRequired: () => void){
		onRequired();
	}
}

export class AddingUserState implements UserConfigurationState {
	adding() {
		return this;
	} 

	editing() {
		return new EditingUserState();
	}

	useEmailForUsername() {
		return new UseEmailForUsernameState();
	}

	requireUsername() {
		return new RequireUsernameState();
	}

	whenAdding(onAdding: () => void) {
		onAdding();
	}

	whenEditing(onEditing: () => void) {
	}

	whenEmailUsernameConfigured(onUsingEmail: () => void) {
		onUsingEmail();
	}

	whenUsernameRequired(onRequired: () => void) {		
	}
}

export class UseEmailForUsernameState extends AddingUserState {
	adding() {
		return this;
	}

	useEmailForUsername() {
		return this;
	}

	whenEmailUsernameConfigured(onUsingEmail: () => void) {
		onUsingEmail();
	}
}

export class RequireUsernameState extends AddingUserState{
	requireUsername() {
		return this;
	}

	whenEmailUsernameConfigured(onUsingEmail: () => void) {
	}

	whenUsernameRequired(onRequired: () => void) {		
		onRequired();
	}
}

export class ChangeUserRequestNameBuilder extends RequestBuilder<NameParts> {
	private _first?: string;
	private _last?: string;
	private _middle?: string;
	private _prefix?: string;
	private _suffix?: string

	withFirst(firstName: string) {
		this._first = firstName;

		return this;
	}

	withLast(lastName: string) {
		this._last = lastName;

		return this;
	}

	withMiddle(middleName: string) {
		this._middle = middleName;

		return this;
	}

	withPrefix(prefix?: string) {
		this._prefix = prefix;

		return this;
	}

	withSuffix(suffix: string) {
		this._suffix = suffix;

		return this;
	}

	removeMiddle() {
		this._middle = undefined;

		return this;
	}

	removePrefix() {
		this._prefix = undefined;

		return this;
	}

	canBuild() {
		if(!!!this._first) {
			return false;
		}

		if(!!!this._last) {
			return false;
		}

		return true;
	}

	build() {
		if (this.canBuild()) {
			return new NameParts(this._first!, this._last!, this._middle);
		}

		throw new Error("First and/or last name(s) are missing. Unable to build.");
	}
}

export class ChangeUserRequestContactBuilder extends RequestBuilder<ChangeUserRequestContact> {
	private _emailAddress?: string;
	private _phoneNumber?: string;

	emailAt(emailAddress: string) {
		this._emailAddress = emailAddress;

		return this;
	}

	callAt(phoneNumber: string) {
		this._phoneNumber = phoneNumber;

		return this;
	}

	canBuild() {
		if(!!!this._emailAddress) {
			return false;
		}

		return true;
	}

	build() {
		if(!this.canBuild()){
			throw new Error("Unable to build contact as not all required components have been provided. Before calling this, invoke canBuild() to check validity of this builder.");
		}

		let contact = new ChangeUserRequestContact(this._emailAddress!);
	
		contact.phoneNumber = this._phoneNumber;
		
		return contact;
	}
}

export class ChangeUserRequestDemographicsBuilder extends RequestBuilder<ChangeUserRequestDemographics> {
	private _nameBuilder = new ChangeUserRequestNameBuilder();
	private _prefix?: string;
	private _suffix?: string;

	userNamed(first: string, last: string, middle?: string) {
		this._nameBuilder = this._nameBuilder.withFirst(first).withLast(last);

		if (middle) {
			this._nameBuilder = this._nameBuilder.withMiddle(middle);
		} else {
			this._nameBuilder = this._nameBuilder.removeMiddle();
		}

		return this;
	}

	prefixName(prefix?: string) {
		this._nameBuilder = this._nameBuilder.withPrefix(prefix);
		
		this._prefix = prefix;

		return this;
	}

	suffixName(suffix?: string) {
		this._suffix = suffix;

		return this;
	}

	removePrefix() {
		if(!this._prefix){
			return this;
		}

		return this.prefixName(undefined);
	}

	removeSuffix() {
		if(!this._suffix) {
			return this;
		}

		return this.suffixName(undefined);
	}

	canBuild() {
		if(!!!this._nameBuilder.canBuild()) {
			return false;
		}

		return true;
	}

	build() {
		if(!this.canBuild()) {
			throw new Error("Unable to build demographics - required items are missing.");
		}

		let nameParts = this._nameBuilder.build()

		let demographics = new ChangeUserRequestDemographics(
			nameParts.firstName,
			nameParts.lastName
		);

		if(nameParts.middleName) {
			demographics.middleName = nameParts.middleName;
		}

		if(this._prefix) {
			demographics.prefix = this._prefix;
		}

		if(this._suffix) {
			demographics.suffix = this._suffix;
		}

		return demographics;
	}
}

export class ChangeUserRequestAccountBuilder extends RequestBuilder<ChangeUserRequestAccount> {
	private _username?: string;
	private _password?: string;
	private _confirmPassword?: string;
	private _active: AccountStatus = AccountStatus.Inactive;
	private _state: UserConfigurationState = new  AddingUserState();
	private _resetRequired: boolean = false;

	withUserName(username: string) {
		this._username = username;

		return this;
	}

	loginWithPassword(password?: string) {
		this._password = password;

		return this;
	}

	confirmPasswordWith(passwordConfirmation?: string) {
		this._confirmPassword = passwordConfirmation;

		return this;
	}

	configureEmailAsUsername() {
		this._state = this._state.useEmailForUsername();
		
		return this;
	}

	requireUsername() {
		this._state = this._state.requireUsername();

		return this;
	}
	 
	activateUser() {
		this._active = AccountStatus.Active;

		return this;
	}

	deactivateUser() {
		this._active = AccountStatus.Inactive;

		return this;
	}

	mustResetPassword() {
		this._resetRequired = true;

		return this;
	}

	resetPasswordNotRequired() {
		this._resetRequired = false;

		return this;
	}

	removePassword() {
		this._password = undefined;
		
		return this;
	}

	removePasswordConfirmation() {
		this._confirmPassword = undefined;

		return this;
	}

	build() {
		if(!this.canBuild()){
			let missingValues: string[] = [];

			if(!!!this._username) {
				missingValues.push("username");
			}

			let missingFields = "";
			missingValues.forEach((val, index) => {
					index === 0 ? missingFields = val : missingFields = `${missingFields}, ${val}`
			});

			throw new Error(`Unable to build. ${missingFields} are missing. Invoke the canBuild method before calling build() to check builder validity.`);
		}

		let builtRequest = ChangeUserRequestAccount.forAddingUser();
		this._state.whenEmailUsernameConfigured(() => builtRequest.useEmailForUsername = true);
		this._state.whenUsernameRequired(() => {
			builtRequest.useEmailForUsername = false;
			builtRequest.username = this._username!;
		});
		this._state.whenEditing(() => builtRequest = ChangeUserRequestAccount.forEditingUser(this._username!, this._password, this._confirmPassword));
		
		builtRequest.accountStatus = this._active;

		builtRequest.requirePasswordReset = this._resetRequired

		return builtRequest;
	}

	public canBuild() {
		let returns = false;
		
		//if the request is configured for adding, we do not need the account information
		//because we use the email address as a username and enforce a password reset.
		this._state.whenAdding(() => returns = true);	

		this._state.whenEditing(() => {
			if(!!!this._username) {
				returns = false;
			} else {
				returns = true
			}
		});

		return returns;
	}

	configureForEditing() {
		this._state = this._state.editing();

		return this;
	}

	configureForAdding() {
		this._state = this._state.adding();

		return this;
	}
}

export class ChangeUserRequestAccountSecurityBuilder extends RequestBuilder<ChangeUserRequestAccountSecurity> {
	private _permissionGroup?: string;
	private _teams: Team[] = [];

	inPermissionGroup(permissionGroup: string) {
		this._permissionGroup = permissionGroup;

		return this;
	}

	onTeam(team: Team) {
		if(this._teams.includes(team)) {
			return this;
		}

		this._teams.push(team);

		return this;
	}

	clearAssignedTeams() {
		this._teams = [];

		return this;
	}

	build() {
		if(!this.canBuild()) {
			throw new Error("Unable to build ChangeUserRequestAccountSecurity. Not all required items were provided. Invoke canBuild() before build() to check the validity of this builder.");
		}

		return new ChangeUserRequestAccountSecurity(this._permissionGroup!, this._teams);
	}

	public canBuild() {
		if(!!!this._permissionGroup){ 
			return false;
		}

		return true;
	}
}

export class ChangeUserRequestBuilder extends RequestBuilder<ChangeUserRequest> {
	private _demographicsBuilder = new ChangeUserRequestDemographicsBuilder();
	private _contactBuilder = new ChangeUserRequestContactBuilder();
	private _accountBuilder = new ChangeUserRequestAccountBuilder();
	private _securityBuilder = new ChangeUserRequestAccountSecurityBuilder();

	userHasName(firstName: string, lastName: string, middleName?: string) {
		if(!!!firstName) {
			this.throwBuildError("firstName");
		}

		if(!!!lastName) {
			this.throwBuildError("lastName");
		}

		this._demographicsBuilder = this._demographicsBuilder.userNamed(firstName, lastName, middleName);

		return this;
	}

	userContactedAt(emailAddress: string) {
		this._contactBuilder = this._contactBuilder.emailAt(emailAddress);

		return this;
	}

	userReachedAt(phoneNumber: string) {
		this._contactBuilder = this._contactBuilder.callAt(phoneNumber);

		return this;
	}

	logsInWith(username: string) {
		this._accountBuilder = this._accountBuilder.withUserName(username);

		return this;
	}

	securesAccount(withPassword?: string) {
		this._accountBuilder = this._accountBuilder.loginWithPassword(withPassword);

		return this;
	}

	passwordMatches(passwordConfirmation?: string) {
		this._accountBuilder = this._accountBuilder.confirmPasswordWith(passwordConfirmation);

		return this;
	}

	onTeam(team: Team) {
		if(!!!team) {
			this.throwBuildError("team");
		}

		this._securityBuilder = this._securityBuilder.onTeam(team);

		return this;
	}

	onTeams(teams: Iterable<Team>) {
		if(!!!teams) {
			this.throwBuildError("teams");
		}

		this._securityBuilder = this._securityBuilder.clearAssignedTeams();
		for(let team of teams) {
			this._securityBuilder = this._securityBuilder.onTeam(team);
		}
			
		return this;
	}

	inSecurityGroup(group: string) {
		this._securityBuilder = this._securityBuilder.inPermissionGroup(group);

		return this;
	}

	withEditingUserConfigured() {
		this._accountBuilder = this._accountBuilder.configureForEditing();

		return this;
	}

	withAddingUserConfigured() {
		this._accountBuilder = this._accountBuilder.configureForAdding();

		return this;
	}

	useEmailUsername() {
		this._accountBuilder = this._accountBuilder.configureEmailAsUsername();

		return this;
	}

	requireUsername() {
		this._accountBuilder = this._accountBuilder.requireUsername();

		return this;
	}

	activateUser() {
		this._accountBuilder = this._accountBuilder.activateUser();

		return this;
	}

	deactivateUser() {
		this._accountBuilder = this._accountBuilder.deactivateUser();

		return this;
	}

	namePrefixed(prefix?: string) {
		this._demographicsBuilder = this._demographicsBuilder.prefixName(prefix);

		return this;
	}

	nameSuffixed(suffix?: string) {
		this._demographicsBuilder = this._demographicsBuilder.suffixName(suffix);

		return this;
	}

	requirePasswordReset() {
		this._accountBuilder = this._accountBuilder.mustResetPassword();

		return this;
	}

	passwordResetNotNeeded() {
		this._accountBuilder = this._accountBuilder.resetPasswordNotRequired();

		return this;
	}

	removePrefix() {
		this._demographicsBuilder = this._demographicsBuilder.prefixName(undefined);

		return this;
	}

	removeSuffix() {
		this._demographicsBuilder = this._demographicsBuilder.suffixName(undefined);

		return this;
	}

	canBuild() {
		if(!!!this._demographicsBuilder.canBuild()) {
			return false;
		}

		if(!!!this._accountBuilder.canBuild()) {
			return false;
		}

		if(!!!this._contactBuilder.canBuild()) {
			return false;
		}

		if(!this._securityBuilder.canBuild()) {
			return false;
		}

		return true;
	}

	clearPassword() {
		this._accountBuilder = this._accountBuilder.removePassword();

		return this;
	}

	clearPasswordConfirmation() {
		this._accountBuilder = this._accountBuilder.removePasswordConfirmation();

		return this;
	}

	build() {
		const demographics = this._demographicsBuilder.build();
		const contact = this._contactBuilder.build();
		const account = this._accountBuilder.build();
		const security = this._securityBuilder.build();

		return new ChangeUserRequest(demographics,
			contact,
			security, 
			account);
	}

	static initializeFromUser(user: OrgUser) {
		let builder =  new ChangeUserRequestBuilder();

		builder = builder.withEditingUserConfigured();

		builder = builder.userHasName(user.firstName, user.lastName);
		
		user.onHasMiddleName(() => builder = builder.userHasName(user.firstName, user.lastName, user.middleName));

		user.onNamePrefixed(() => builder = builder.namePrefixed(user.namePrefix));
		user.onNameSuffixed(() => builder = builder.nameSuffixed(user.nameSuffix));

		builder = builder.userContactedAt(user.email);
		
		user.reachableByPhoneAt(phoneNumber => builder = builder.userReachedAt(phoneNumber));
		
		user.loginAllowed(() => builder = builder.activateUser());

		builder = builder.onTeams(user.teams);

		builder = builder.inSecurityGroup(SecurityStrings.UserRoleName);
		user.whenAdministrator(() => builder = builder.inSecurityGroup(SecurityStrings.AdminRoleName));

		builder.logsInWith(user.username);

		return builder;
	}
}