import { TeamStatus } from "../Team";

export class UpdateTeamRequest {
	teamId: number = 0;
	teamName: string;
	assignedUsers: string[] = []; //id's of the members assigned
	status = TeamStatus.Active;

	constructor(teamName: string) {
		if(!!!teamName) {
			throw new Error("teamName was not provided");
		}

		this.teamName = teamName;
	}

	activate() {
		this.status = TeamStatus.Active;
	}

	deactivate() {
		this.status = TeamStatus.Inactive
	}
}