import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router'; 
import { Button, Input, FormGroup, Label } from 'reactstrap';
import { Panel } from 'components/Common/Panel';
import { CallService } from 'telehealth-api';
import { AuthContext } from 'components/ApiAuthorization/AuthContext';

export const InviteCallParticipant = () => {

    const [destination, setDestination] = useState<string>();
    const authContext = useContext(AuthContext);

    const history = useHistory();

    let callService: CallService = new CallService(authContext.provider);

    useEffect(() => {
        callService = new CallService(authContext.provider)
    }, [authContext, authContext.provider]);

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.id) {
            case 'callDestination':
                setDestination(e.target.value);
                break;
        }
    }

    const createCallAndInvite = async () => {
        if (!destination) {
            return;
        }

        const authToken = await authContext.provider.getAccessToken();

        if (!authToken) {
            return;
        }

        let inviteUserResponse = await fetch('/api/v1/call/user', {
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                destination: destination,
            }),
            method: 'POST'
        });

        if(inviteUserResponse.ok && (inviteUserResponse.headers.get('Content-Type')?.indexOf('json') ?? -1) > -1) {
            var createdCall = await inviteUserResponse.json() as {roomId: string};
            //history.push(`/room?roomId=${createdCall.roomId}`);
            window.location.href = `${window.location.protocol}//${window.location.host}/room?roomId=${createdCall.roomId}`;
        }
    }

    return <Panel name="Send a Telehealth Invite">
        <FormGroup>
            <Label for="callDestination">
                Send a direct link via email or a text message to join a telehealth call.
            </Label>
            <Input id="callDestination" name="callDestination" onChange={(event) => onInputChange(event)} />
        </FormGroup>
        <Button className="mx-auto" color="green" onClick={(event) => createCallAndInvite()}>Send Link and Join Call</Button>
    </Panel>
}