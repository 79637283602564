import TreeBuilder from "common/treeBuilder";
import TreeNode from "common/treeNode";
import Tree from "common/tree";
import { Organization } from "Organization";
import { ApiUrl } from "ApiConstants";
import { Team } from "Team";
import { Person } from "Person";
import { ApiResponse, FailureApiResponse } from "common/ApiResponse";
import { UpdateOrganizationRequest } from "organization/UpdateOrganizationRequest";
import { OrgUser } from "organization/OrgUser";
import { Claim } from "security/Claim";
import { ApiRequest } from "common/ApiRequest";

export default class OrganizationService {
	defaultParseOptions = {
		rootNodeName: "orgTree",
		childrenCollectionName: "children",
		nodeDataName: "data",
		dataFactory: (source: any) => this.organizationFactory(source),
		nodesEqual: (
			first: TreeNode<Organization>,
			second: TreeNode<Organization>
		) => this.orgNodesEqual(first, second),
	};

	/*******************************
	 * There isn't a notion of an organization tree anymore... that concept has been moved to sites.
	 * Each organization may have multiple sites, and they may have a nested hierarchy. This code
	 * is left here for legacy reasons as it was called in many places in the front-end, and we
	 * made the decision to have an organization only have one site for now. The callers were
	 * adapted to make this work.
	 */
	async buildOrganizationTree() {
		const request = new ApiRequest();
		let response = await request.send(`${ApiUrl.OrgUrl}`)

		const emptyTree = new Tree<Organization>(new TreeNode(Organization.default));
		let treeBuilder: TreeBuilder<Organization>;
		let tree = ApiResponse.create<Tree<Organization>, any>(response, json => {
			treeBuilder = this.createTreeBuilder(json);

			var builtTree = treeBuilder.parse();

			if(!!!builtTree) {
				return emptyTree;
			}

			return builtTree;
		}, () => emptyTree);

		return tree;
	}

	private async retrieveOrganization() {
		let orgTree = await this.buildOrganizationTree();

		let fetchedOrg = Organization.default;

		orgTree.item.getRoot().visit((o) => (fetchedOrg = o));

		return fetchedOrg;
	}

	async updateOrg(orgUpdateRequest: UpdateOrganizationRequest): Promise<ApiResponse<Organization>> {
		try {
			var request = new ApiRequest();

			var updateResponse = await request.patch(`${ApiUrl.OrgUrl}/${orgUpdateRequest.organization.id}`, orgUpdateRequest);

			const createResponse = await ApiResponse.create(
				updateResponse,
				(orgJson: any) =>
					this.createOrganization(orgUpdateRequest, orgJson),
				() => orgUpdateRequest.organization
			);

			return createResponse;
		} catch (error) {
			console.error(
				`Something went wrong when updating organization ${error}`
			);
			return new FailureApiResponse<Organization>(
				500,
				orgUpdateRequest.organization
			);
		}
	}

	private createOrganization(
		fromRequest: UpdateOrganizationRequest,
		withOrgJson: any
	) {
		const treeBuilder = this.createTreeBuilder(withOrgJson);

		let orgTree = treeBuilder.parse();
		var searchNode = new TreeNode<Organization>(fromRequest.organization);

		let found = orgTree?.find(searchNode, this.orgNodesEqual);

		let foundOrg = new Organization("0", "");
		found?.visit((visitingOrg) => (foundOrg = visitingOrg));

		return foundOrg;
	}

	async updateTeam(
		orgId: string,
		team: Team,
		removedMembers: Person[],
		addedMembers: Person[]
	) {
		return fetch(`${ApiUrl.OrgUrl}/${orgId}/team/${team.id}/`, {
			headers: {
				"content-type": "application/json",
			},
			method: "PUT",
			body: JSON.stringify({
				teamName: team.name,
				removedTeamMembers: removedMembers,
				addedTeamMembers: addedMembers,
			}),
		}).then((response) => {
			if (response.status >= 400 && response.status <= 500) {
				return new Promise((resolve, fail) =>
					fail("Updating team failed.")
				);
			}

			return response.json();
		});
	}

	private createTreeBuilder(orgTreeJs: string) {
		return new TreeBuilder<Organization>(
			orgTreeJs,
			this.defaultParseOptions
		);
	}

	private organizationFactory(source: any): Organization {
		let teams: Team[] = source["teams"].map(
			(t: {
				id: number;
				name: string;
				status: string;
				createdOn: string;
				existingMembers: { id: string; name: string, email: string }[];
			}) => {
				var members = t.existingMembers.map((existingMember) =>	new Person(existingMember.id, existingMember.name, existingMember.email));
				var team = new Team(t.id, t.name);
				team.created = t.createdOn;

				team.withExisistingMembers(members);

				if(t.status === "Active") {
					team.activate();
				} else {
					team.deactivate();
				}

				return team;
			}
		);

		let users = source["users"].map(
			(u: {
				id: string;
				name: string;
				claim: Claim[];
				reducedTeamsName: string;
				flatPermissionGroups: string;
				lastLogin: string;
				status: string;
				email: string;
				namePrefix?: string;
				nameSuffix?: string;
				teams: string[];
				phoneNumber: string;
				username: string;
			}) => {
				let orgUser = new OrgUser(u.id, u.name, u.email, u.username, []);

				orgUser.flatTeams = u.reducedTeamsName;
				orgUser.flatPermissionGroups = u.flatPermissionGroups;

				orgUser.lastLogin = u.lastLogin;

				orgUser.status = u.status;

				if(u.namePrefix) {
					orgUser.namePrefixedWith(u.namePrefix);
				}

				if(u.nameSuffix) {
					orgUser.nameSuffixedWith(u.nameSuffix);
				}

				orgUser.withPhoneNumber(u.phoneNumber);

				for(var teamName of u.teams) { 
					let foundOrgTeam = teams.find((orgTeam) => orgTeam.name === teamName);

					if(foundOrgTeam) {
						orgUser.onTeam(foundOrgTeam);
					}
				}

				return orgUser;
			}
		);

		let org = new Organization(source["id"], source["data"]);

		org.manages(teams);
		org.accessibleBy(users);

		return org;
	}

	private orgNodesEqual(
		first: TreeNode<Organization>,
		second: TreeNode<Organization>
	): boolean {
		let firstId: string = "";
		let secondId: string = "";

		first.visit((a) => (firstId = a.id));
		second.visit((b) => (secondId = b.id));

		return firstId === secondId;
	}
}
