import React, { ChangeEvent } from "react";
import { CardBody, CardTitle, Form, FormGroup, Label } from "reactstrap";
import { Team } from "../../Team";
import { Slushbox } from "../Common/Slushbox";

interface UserPermissionCardProps {
  permissionGroup?: string;
  organizationTeams: Iterable<Team>;
  availableTeams: Iterable<Team>;
  assignedOrganizationTeams?: Iterable<Team>;
  editMode: boolean;
  onPermissionGroupChosen: (permissionGroup: string) => void;
  onTeamsAssigned: (assignedTeams: Iterable<Team>) => void;
  onTeamsAvailableChange: (availableTeams: Iterable<Team>) => void;
}

export const UserPermissionsCard = ({
  permissionGroup = "Please Select",
  organizationTeams,
  assignedOrganizationTeams,
  availableTeams,
  editMode,
  onPermissionGroupChosen,
  onTeamsAssigned,
  onTeamsAvailableChange,
}: UserPermissionCardProps) => {
  const orgTeams = Array.from(organizationTeams ?? []);

  const findTeamFrom = (option: HTMLOptionElement) => {
    const searchTeam = new Team(parseInt(option.value), option.innerText);
    return orgTeams.find((team) => searchTeam.equals(team));
  };

  const withPermissionGroup = (selectedPermGroup: ChangeEvent<HTMLSelectElement>) => {
    if (!!!selectedPermGroup) {
      console.error("withPermissionGroup called without selectedPermGroup");
    }

    const permissionGroup = selectedPermGroup.target.selectedOptions[0];

    onPermissionGroupChosen(permissionGroup.value);
  };

  return (
    <>
      <CardBody>
        <CardTitle tag="h5">Permissions</CardTitle>
        <Form>
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label for="permissionGroup">Permission Group</Label>
              <select
                className="form-control"
                id="permissionGroup"
                name="permissionGroup"
                value={permissionGroup}
                onChange={(event) => withPermissionGroup(event)}
                //defaultValue="Please Select"
                data-testid="permission-group"
              >
                <option value="Please Select">Please Select</option>
                <option value="User">User</option>
                <option value="Administrator">Administrator</option>
              </select>
            </FormGroup>
          </div>
          <div className="form-row">
            <FormGroup className="col-md-12">
              <p>Teams</p>
              <Slushbox
                availableItems={availableTeams}
                assignedItems={assignedOrganizationTeams}
                displayText={(team) => team.name}
                mapOptionFrom={(opt) => findTeamFrom(opt) ?? Team.unknown}
                valueFactory={(t) => t.id.toString()}
                onAssignedItemsChanged={(allAssigned) => onTeamsAssigned(allAssigned)}
                onAvailableItemsChanged={(allAvailable) => onTeamsAvailableChange(allAvailable)}
                itemsEqual={(first, second) => first.id === second.id}
              />
            </FormGroup>
          </div>
        </Form>
      </CardBody>
    </>
  );
};
