import React, { Component } from "react";
import { CallNotification } from "telehealth-abstractions";
import { Button, Col, Row } from "reactstrap";
import "./CallToast.scss";

export interface CallToastProps {
  call: CallNotification;
  handleAnswerCall: (call: CallNotification) => Promise<void>;
  handleIgnoreCall: (call: CallNotification) => void;
  handleRejectCall: (call: CallNotification) => void;
}

export class CallToast extends Component<Readonly<CallToastProps>, Readonly<{}>> {
  _subscriptionId: number | null = null;
  toastRef: HTMLDivElement | null = null;

  constructor(props: Readonly<CallToastProps>) {
    super(props);
    this.state = {
      incomingCallId: null,
      incomingCaller: null,
      redirectToRoom: false,
    };
  }

  render() {
    return (
      <div
        className="toast show"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        ref={(toastRef) => {
          this.toastRef = toastRef;
        }}
      >
        <div className="toast-header">
          <div>Incoming Call</div>
        </div>
        <div
          className="clickable toast-body justify-content-around d-flex flex-column"
          onClick={() => this.props.handleAnswerCall(this.props.call)}
        >
          <i className="fas fa-video fa-2x mx-auto toast-animate"></i>
          <strong className="mx-auto">{this.props.call.AgencyName ?? "Incoming Call"}</strong>
          {this.props.call.UnitNumber && <strong className="mx-auto">{this.props.call.UnitNumber}</strong>}
        </div>
        <Row className="toast-footer">
          <Col>
            <Row>
              <Col className="d-flex justify-content-center py-4">
                <strong>{this.props.call.From}</strong>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={8} className="d-flex justify-content-center">
                <Button
                  className="btn-ignore"
                  onClick={() => this.props.handleIgnoreCall(this.props.call)}
                  type="button"
                  color="orange"
                >
                  <i className="fas fa-times mr-2"></i>
                  Ignore
                </Button>
              </Col>
              <Col sm={12} md={4} className="d-flex justify-content-center mt-2 mt-md-0">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => this.props.handleRejectCall(this.props.call)}
                  title={`Decline call from ${this.props.call.From}`}
                >
                  <i className="fas fa-phone-slash"></i>
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
