export default class Message {
    readonly id: string;

    constructor(id: string) {
        if(!id) {
            throw new Error('A message id must be provided');
        }

        this.id = id;
    }

    equals(other: Message) {
        return this.id === other.id;
    }
}