import { ApiUrl } from './ApiConstants';
import { LoginStatus, ResetPasswordStatus } from './SecurityStatus';

export class LoginResponse {
	status: LoginStatus = LoginStatus.Failure;
	errorMessages: Array<string> = [];
	orgId: string = '';
	permissionGroups: string[] = []
} 

export class ResetPasswordResponse {
	email: string = '';
	userId: string = '';
	userName: string = '';
	status: ResetPasswordStatus = ResetPasswordStatus.Failure;
	errorMessages: Array<string> = [];
}

export class Passwords {
	newPassword: string = '';
	confirmPassword: string = '';

	constructor(newPassword: string, confirmPassword: string) {
		if(!newPassword){
			throw new Error('New password was not provided.');
		}

		if(!confirmPassword) {
			throw new Error('Password confirmation was not provided.');
		}

		this.newPassword = newPassword;
		this.confirmPassword = confirmPassword;
	}

}

export class SecurityManager {
	async loginUser(username: string, password: string) {
		var response = await fetch(ApiUrl.SecurityUrl, {
			method: "POST",
			body: JSON.stringify({
				username: username,
				password: password
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		});

		let responseJson = await response.json();
		
		let loginResponse = new LoginResponse();
		loginResponse.status = responseJson.loginStatus;
		loginResponse.errorMessages = responseJson.failureMessages;
		loginResponse.orgId = responseJson.orgId;
		loginResponse.permissionGroups = responseJson.permissionGroups;
		
		return loginResponse;
	}

	async resetUserPassword(username: string, currentPassword: string, newPassword: string, confirmPassword: string){
		var response = await fetch(`${ApiUrl.SecurityUrl}/user/reset`, {
			method: "POST",
			body: JSON.stringify({
				username: username,
				currentPassword: currentPassword,
				newPassword: newPassword,
				confirmPassword: confirmPassword
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		});

		return await this.createResetPasswordResponseFromAsync(response);
	}

	async resetUserPasswordWithTokenAsync(newPassword: string, confirmPassword: string, resetToken: string) {
		
		let response = await fetch(`${ApiUrl.SecurityUrl}/user/reset`, {
			method: "POST",
			body: JSON.stringify({
				newPassword: newPassword,
				confirmPassword: confirmPassword,
				resetToken: resetToken
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		});

		return await this.createResetPasswordResponseFromAsync(response);
	}

	private async createResetPasswordResponseFromAsync(resetResponse: Response): Promise<ResetPasswordResponse> {
		let response = await resetResponse.json();

		let resetPasswordResponse = new ResetPasswordResponse();

		if(response.userEmail) {
			resetPasswordResponse.email = response.userEmail;
		}
		
		if(response.userId) {
			resetPasswordResponse.userId = response.userId;
		}

		if(response.userName) {
			resetPasswordResponse.userName = response.userName;
		}

		resetPasswordResponse.status = response.status;

		resetPasswordResponse.errorMessages = response.failureMessages;

		return resetPasswordResponse;
	}
}