import React, { Component } from 'react';
import { ToggleAudioButton, ToggleAudioButtonProps } from './ToggleAudioButton';
import { ToggleVideoButton, ToggleVideoButtonProps } from './ToggleVideoButton';
import { ToggleChatButton, ToggleChatButtonProps } from './ToggleChatButton';
import { ToggleSettingsButton, ToggleSettingsButtonProps } from './ToggleSettingsButton';
import { LeaveRoomButton, LeaveRoomButtonProps } from './LeaveRoomButton';
import './RoomHeader.css';

interface RoomHeaderProps extends ToggleAudioButtonProps, ToggleChatButtonProps, ToggleVideoButtonProps, ToggleSettingsButtonProps, LeaveRoomButtonProps {
}

export class RoomHeader extends React.Component<Readonly<RoomHeaderProps>, Readonly<{}>> {

	constructor(props: Readonly<RoomHeaderProps>) {
		super(props);
	}

	render() {
        return (
            <div className="room-header">
                <div className="room-header-spacer"></div>
                <ToggleSettingsButton roomManager={this.props.roomManager}></ToggleSettingsButton>
                <ToggleChatButton roomManager={this.props.roomManager}></ToggleChatButton>
                <div className="room-header-divider"></div>
                <ToggleVideoButton videoManager={this.props.videoManager}></ToggleVideoButton>
                <ToggleAudioButton audioManager={this.props.audioManager}></ToggleAudioButton>
                <div className="room-header-divider"></div>
                <LeaveRoomButton showLeaveRoomModal={this.props.showLeaveRoomModal}></LeaveRoomButton>
            </div>
        );
	}
}