import Message from 'common/Message';
import { MessageId } from 'common/Messaging';

export class UserModifySuccessMessage extends Message {
    constructor() {
        super(MessageId.userModifySuccess)
    }
}

export class UserModifyFailMessage extends Message {
    constructor() {
        super(MessageId.userModifyFail);
    }
}