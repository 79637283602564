import { FontAwesome } from 'components/Common/FontAwesome';
import React, { HTMLAttributes, PropsWithChildren } from 'react';
import './DeclineCallButton.scss';

export interface DeclineCallButtonProps extends HTMLAttributes<{}> {
    onClick: () => Promise<void>;
}

export const DeclineCallButton = ({children, className, onClick, title}: PropsWithChildren<DeclineCallButtonProps>) => {
    return (
        <div className={`clickable btn-call-center btn-decline-call${className ?  ' ' + className : ''}`} onClick={async () => await onClick()} title={title}>
            <FontAwesome icon="fa-phone-slash ml-1"/>
            { children }
        </div>
    )
}