import React from "react";
import Switch, { ReactSwitchProps } from "react-switch";

interface ImageTrendToggleProps {
  checkedLabel?: string;
  uncheckedLabel?: string;
  labelDisplaySide?: string;
}

const ImageTrendToggle = ({
  checked,
  className,
  onChange,
  checkedLabel,
  uncheckedLabel,
  labelDisplaySide = "left",
}: ReactSwitchProps & ImageTrendToggleProps) => {
  let labelClass = "d-inline-block mr-2";

  if (className) {
    labelClass = labelClass + ` ${className}`;
  }

  return (
    <>
      {checkedLabel && checked && labelDisplaySide === "left" && <div className={labelClass}>{checkedLabel}</div>}
      {uncheckedLabel && !checked && labelDisplaySide === "left" && <div className={labelClass}>{uncheckedLabel}</div>}
      <Switch
        checked={checked}
        className="react-switch"
        handleDiameter={10}
        height={15}
        width={45}
        checkedIcon={false}
        onColor="#A8B930"
        onChange={(checked, event, id) => onChange(checked, event, id)}
      />
      {checkedLabel && checked && labelDisplaySide === "right" && <div className={labelClass}>{checkedLabel}</div>}
      {uncheckedLabel && !checked && labelDisplaySide === "right" && <div className={labelClass}>{uncheckedLabel}</div>}
    </>
  );
};

export default ImageTrendToggle;
