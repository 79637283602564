import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Redirect, Route, withRouter, StaticContext, Switch } from "react-router";
import Layout from "./components/Layout";
import { Home } from "./components/Home";
import { RoomRouter } from "./components/RoomRouter";
import AuthorizeRoute from "./components/ApiAuthorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "./components/ApiAuthorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./components/ApiAuthorization/ApiAuthorizationConstants";
import { CallTreeRouter } from "./components/CallTreeRouter";
import { RoutedCallToastManager } from "./components/CallToastManager";
import { UnregisterCallback } from "history";
import { RouteComponentProps } from "react-router-dom";
import { UserLogin } from "./components/UserLogin";
import { ResetPassword } from "./components/ResetPassword";
import { Teams } from "./components/Teams/Teams";
import { Users } from "./components/user/Users";
import AuthContextProvider from "components/ApiAuthorization/AuthContextProvider";
import { CallCenter } from "components/CallCenter/CallCenter";
import Presence from "components/user/Presence";
import MessagingContextProvider from "components/Messaging/MessagingContextProvider";
import { CallService } from "telehealth-api";
import { AcceptCall } from "components/AcceptCall";
import TelehealthAccountSecuredComponent from "components/ApiAuthorization/TelehealthAccountSecuredComponent";
import { SecuredPersonService } from "services/SecuredPersonService";
import { NavMenuContextProvider } from "components/Navigation/NavMenuContextProvider";
import { AuthContext } from "components/ApiAuthorization/AuthContext";
import NavMenuContext from "components/Navigation/NavMenuContext";
import { statusService } from "components/SignalR/StatusService";
import { AuthChangeCheck } from "./security/AuthChangeCheck";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "../node_modules/telehealth-styles/build/telehealth.css";

interface AppProps extends RouteComponentProps<{}, StaticContext, { action?: string }> {}

const App = ({ history, location, match }: AppProps) => {
  let unlisten = useRef<UnregisterCallback>();

  const personService = new SecuredPersonService();
  const authSubscription = useRef<number>();
  const authContext = useContext(AuthContext);
  const navContext = useContext(NavMenuContext);

  const [className, setClassName] = useState<string>();
  const [authenticated, setAuthenticated] = useState(false);

  const authChange = useMemo(() => new AuthChangeCheck(authContext.provider), [authContext]);

  const getClassName = useCallback(
    (path: string) => {
      let firstSegment = path.match(/^\/(?<path>[a-zA-Z-]*)/);

      let className = "layout container-fluid";
      if (firstSegment && firstSegment.groups?.path) {
        className = `layout-${firstSegment.groups.path} container-fluid`;
      } else {
        //this must be either the login or home path
        className = authenticated ? "layout-home container-fluid" : "layout-login container-fluid";
      }

      return className;
    },
    [authenticated]
  );

  const locationChangeHandler = useCallback(() => {
    const checkAuthenticated = async () => {
      authChange.checkAuthChanged(authenticated, (authenticated: boolean) =>
        authenticated ? navContext.showNav() : navContext.hideNav()
      );
    };

    if (getClassName(location.pathname) !== location.pathname) {
      setClassName(getClassName(location.pathname));
    }

    checkAuthenticated();
  }, [getClassName, location.pathname, navContext, authenticated, authChange]);

  useEffect(() => {
    const checkAuth = async () => {
      let userAuthenticated = await authContext.isAuthenticated();

      if (userAuthenticated !== authenticated) {
        if (authSubscription.current) {
          authContext.cancelAuthenticationChange(authSubscription.current);
        }

        authSubscription.current = authContext.onAuthenticationChange(() => {
          setAuthenticated(userAuthenticated);
        });

        setAuthenticated(userAuthenticated);
      }
    };

    checkAuth();

    return () => {
      if (authSubscription.current) {
        authContext.cancelAuthenticationChange(authSubscription.current);

        authSubscription.current = undefined;
      }
    };
  }, [authenticated, authContext]);

  useEffect(() => {
    locationChangeHandler();

    if (history) {
      unlisten.current = history.listen(() => locationChangeHandler());
    }

    return () => {
      if (unlisten.current) {
        unlisten.current();
        unlisten.current = undefined;
      }
    };
  }, [history, location, locationChangeHandler]);

  useEffect(() => {
    const updateNav = async () =>
      await authChange.checkAuthChanged(authenticated, (authenticated) => {
        authenticated ? navContext.showNav() : navContext.hideNav();
      });

    updateNav();
  }, [navContext, authenticated, authChange]);

  useEffect(() => {
    const initializeStatus = async () => {
      await statusService.initialize();
    };

    if (authenticated) {
      initializeStatus();
    }
  }, [authenticated]);

  return (
    <AuthContextProvider>
      <NavMenuContextProvider>
        {(navMenuCtx) => (
          <MessagingContextProvider>
            <Layout className={className ?? ""}>
              <Switch>
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                <Route path="/login" component={UserLogin} />
                <Route path="/first-login/:resetToken" component={ResetPassword} />
                <Route path="/reset" component={ResetPassword} />
                <Route path="/reset-password/:resetToken" component={ResetPassword} />
                <Route path="/accept-call/:userId/:roomId/:signature" component={AcceptCall} />
                <AuthorizeRoute path="/home">
                  <TelehealthAccountSecuredComponent personService={personService}>
                    <Home />
                  </TelehealthAccountSecuredComponent>
                </AuthorizeRoute>
                <AuthorizeRoute path="/call-center">
                  <TelehealthAccountSecuredComponent personService={personService}>
                    <CallCenter history={history} match={match} location={location} />
                  </TelehealthAccountSecuredComponent>
                </AuthorizeRoute>
                <AuthorizeRoute path="/contacts">
                  <TelehealthAccountSecuredComponent personService={personService}>
                    <CallTreeRouter />
                  </TelehealthAccountSecuredComponent>
                </AuthorizeRoute>
                <AuthorizeRoute path="/teams">
                  <TelehealthAccountSecuredComponent personService={personService}>
                    <Teams />
                  </TelehealthAccountSecuredComponent>
                </AuthorizeRoute>
                <AuthorizeRoute path="/users">
                  <TelehealthAccountSecuredComponent personService={personService}>
                    <Users />
                  </TelehealthAccountSecuredComponent>
                </AuthorizeRoute>
                <AuthorizeRoute
                  path="/room"
                  render={(props) => (
                    <RoomRouter
                      {...props}
                      showNavMenu={(show) => (show ? navMenuCtx.showNav() : navMenuCtx.hideNav())}
                      userService={personService}
                    />
                  )}
                />
                <Route path="/">
                  <>
                    {authenticated && <Redirect to="/home" />}
                    {!authenticated && <Redirect to="/login" />}
                  </>
                </Route>
              </Switch>
              <AuthContext.Consumer>
                {(authContext) =>
                  authenticated && (
                    <RoutedCallToastManager
                      authService={authContext.provider}
                      callService={new CallService(authContext.provider)}
                    />
                  )
                }
              </AuthContext.Consumer>
              <Presence />
            </Layout>
          </MessagingContextProvider>
        )}
      </NavMenuContextProvider>
    </AuthContextProvider>
  );
};

export default withRouter(App);
