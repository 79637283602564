import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Person } from "../../Person";
import { Column, RowPropGetter } from "react-table";
import { ImageTrendTable } from "../Common/ImageTrendTable";
import OrganizationService from "../../organization/OrganizationService";
import { DisplayUsersPermission } from "../../security/Permission/DisplayUsersPermission";
import { SecuredPersonService } from "../../services/SecuredPersonService";
import { SecuredComponent } from "../SecuredComponent";
import { NotGranted } from "../../security/Permission/NotGranted";
import { RoleRetriever } from "../../security/RoleRetriever";
import { EditUser } from "./EditUser";
import { OrgUser } from "../../organization/OrgUser";
import { Organization } from "../../Organization";
import { RenderPermission } from "../../security/Permission/RenderPermission";

export const Users = () => {
  let orgService = new OrganizationService();

  let nameColumn: Column<Person> = {
    Header: "Last Name",
    accessor: "lastName",
  };

  let columns = [
    nameColumn,
    {
      Header: "First Name",
      accessor: "firstName",
    } as Column<Person>,
    {
      Header: "Teams",
      accessor: "flatTeams",
    } as Column<Person>,
    {
      Header: "Permission Group",
      accessor: "flatPermissionGroups",
    } as Column<Person>,
    {
      Header: "Status",
      accessor: "status",
    } as Column<Person>,
    {
      Header: "Last Login",
      accessor: "lastLogin",
    } as Column<Person>,
  ];

  const [orgUsers, setOrgUsers] = useState(new Array<OrgUser>());
  const [usersPermission, setUsersPermission] = useState(new RenderPermission(new NotGranted()));
  const [renderComplete, setRenderComplete] = useState(false);
  const [userClicked, setUserClicked] = useState({} as Person);
  const [displayUsers, setDisplayUsers] = useState(true);
  const [org, setOrg] = useState(Organization.default);
  const [addUser, setAddUser] = useState(false);
  const [editUser, setEditUser] = useState(false);

  useEffect(() => {
    let mounted = true;
    usersPermission.disableRendering();

    const retrievePermissions = async () => {
      if (mounted) {
        let roleRetriever = new RoleRetriever(new SecuredPersonService());
        let roleClaim = await roleRetriever.ofLoggedInUser();

        let allowedToViewUsers = new RenderPermission(new DisplayUsersPermission(roleClaim)).enableRendering();

        setUsersPermission(allowedToViewUsers);
      }
    };

    retrievePermissions();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRenderComplete(false);

    const retrieveUsers = async () => {
      setRenderComplete(false);

      let fetchOrgResponse = await orgService.buildOrganizationTree();

      fetchOrgResponse.successful((orgTree) => {
        orgTree.getRoot().visit((visitingOrg) => {
          setOrg(visitingOrg);
          setOrgUsers(sortUsers(visitingOrg.users));
        });
      });
    };

    usersPermission.granted(async () => {
      await retrieveUsers();
      setRenderComplete(true);
    });

    usersPermission.denied(() => setRenderComplete(true));
  }, [usersPermission]);

  const onUserClicked = (user: Person) => {
    setDisplayUsers(false);
    setEditUser(true);
    setUserClicked(user);
  };

  const onAddUser = () => {
    setAddUser(true);
    setDisplayUsers(false);
  };

  const onUserEdited = (user: OrgUser) => {
    let updatedOrgUsers: OrgUser[] = [];
    for (let userIndex = 0; userIndex < orgUsers.length; userIndex++) {
      let currentUser = orgUsers[userIndex];

      if (currentUser.id !== user.id) {
        updatedOrgUsers.push(currentUser);
      } else {
        updatedOrgUsers.push(user);
      }
    }

    setOrgUsers(sortUsers(updatedOrgUsers));
    setOrg(org.updateUser(user));
  };

  const onUserAdded = (user: OrgUser) => {
    setOrgUsers(sortUsers([...orgUsers, user]));
    setOrg(org.addUser(user));
  };

  const closeUserModification = () => {
    setAddUser(false);
    setEditUser(false);
    setDisplayUsers(true);
  };

  const sortUsers = (orgUsers: OrgUser[]) => {
    return orgUsers.sort((a, b) => {
      if (a.lastName === b.lastName) {
        return 0;
      }

      return a.lastName < b.lastName ? -1 : 1;
    });
  };

  const imageTrendTable = () => {
    return orgUsers.length > 0 ? (
      <>
        {displayUsers ? (
          <>
            <Row className="upper-content">
              <Col>
                <h1>Users</h1>
              </Col>
              <Col className="pt-2">
                <Button color="orange" className="float-right mb-3" onClick={() => onAddUser()}>
                  <i className="fas fa-plus fa-lg"></i> Add User
                </Button>
              </Col>
            </Row>
            <Row className="lower-content">
              <Col xs={{ size: 12 }} className="table-responsive">
                <ImageTrendTable<Person>
                  renderableData={orgUsers}
                  headers={columns}
                  rowProps={(row: Person) =>
                    ({
                      className: "clickable",
                      onClick: () => onUserClicked(row),
                    } as RowPropGetter<Person>)
                  }
                />
              </Col>
            </Row>
          </>
        ) : null}
        <Row>
          <Col>
            {editUser ? (
              <Row>
                <Col md={{ size: 12 }}>
                  <EditUser
                    userId={userClicked.id}
                    userOrg={org}
                    editing={true}
                    onUserChanged={(user) => onUserEdited(user)}
                    onCancel={() => closeUserModification()}
                  />
                </Col>
              </Row>
            ) : null}
            {addUser ? (
              <Row>
                <Col md={{ size: 12 }}>
                  <EditUser
                    editing={false}
                    userOrg={org}
                    onUserChanged={(user) => onUserAdded(user)}
                    onCancel={() => closeUserModification()}
                  />
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </>
    ) : null;
  };

  return (
    <>
      {renderComplete ? (
        <SecuredComponent permission={usersPermission}>{imageTrendTable()}</SecuredComponent>
      ) : (
        <Row>
          <Col className="d-flex justify-content-center">
            <Spinner>Loading Users...</Spinner>
          </Col>
        </Row>
      )}
    </>
  );
};
