import React, { Component } from "react";
import { FontAwesome } from "components/Common/FontAwesome";
import { LoginActions } from "components/ApiAuthorization/ApiAuthorizationConstants";
import { Messages } from "components/Common/Messages";
import { MessageState } from "./MessageState";
import { Login } from "components/ApiAuthorization/Login";
import { LoginStatus } from "../SecurityStatus";
import { SecurityManager } from "../SecurityManager";
import { Button, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import { Redirect, RouteComponentProps, StaticContext } from "react-router";
import { ClickableText } from "components/Common/ClickableText";
import { ForgotPassword } from "./ForgotPassword";
import NavMenuContext from "./Navigation/NavMenuContext";

import "./UserLogin.scss";

export interface ILoginComponentProps extends RouteComponentProps<{}, StaticContext, { action?: string }> {
  afterLogin?: Iterable<() => Promise<void>>;
}

export class LoginState extends MessageState {
  username: string = "";
  password: string = "";
  redirectToLogin: boolean = false;
  showForgotPasswordForm: boolean = false;
  showReset: boolean = false;
  showLoader: boolean = true;
  showLogin: boolean = true;
  firstTimeLogin: boolean = false;
  enableLoginButton: boolean = true;
}

export class UserLogin extends Component<ILoginComponentProps, LoginState> {
  private jumpTable: any = {
    [LoginStatus.Success]: async () => await this.setLoginSuccessState(),
    [LoginStatus.PasswordResetRequired]: () => this.setPasswordResetRequiredState(),
    [LoginStatus.FirstTimeLogin]: () => this.setFirstTimeLoginState(),
  };

  constructor(props: ILoginComponentProps) {
    super(props);

    this.state = new LoginState();
  }

  componentDidMount() {
    this.context.hideNav();
  }

  componentWillUnmount() {
    this.context.showNav();
  }

  private async setLoginSuccessState() {
    this.setState((s) => ({
      ...s,
      redirectToLogin: true,
    }));
  }

  private setPasswordResetRequiredState() {
    this.setState((s) => ({
      ...s,
      showReset: true,
      showLogin: false,
    }));
  }

  private setFirstTimeLoginState() {
    this.setState((s) => ({
      ...s,
      showLogin: false,
      showReset: true,
      firstTimeLogin: true,
    }));
  }

  private async login() {
    this.clearErrors();
    this.disableLoginButton();

    var loginManager = new SecurityManager();

    try {
      let response = await loginManager.loginUser(this.state.username, this.state.password);

      var loginHandlerDelegate = this.jumpTable[response.status];

      if (loginHandlerDelegate) {
        loginHandlerDelegate();
      } else {
        this.populateErrorMessageStateFrom(response.errorMessages);
        this.enableLoginButton();
      }
    } catch (error) {
      this.enableLoginButton();

      if (!error.message) {
        return;
      }

      let singleErrorMessage: Array<string> = [error.message];
      this.populateErrorMessageStateFrom(singleErrorMessage);
    }
  }

  private onUsernameChange(value: string) {
    this.setState((s) => ({
      ...s,
      username: value,
    }));
  }

  private handlePasswordChange(value: string) {
    this.setState((s) => ({
      ...s,
      password: value,
    }));
  }

  private clearErrors() {
    this.setState((s) => ({
      ...s,
      errorMessages: [],
    }));
  }

  private populateErrorMessageStateFrom(messages: Array<string>) {
    if (!messages) {
      return;
    }
    let errorMessages: Array<string> = [];
    this.state.errorMessages.forEach((msg) => errorMessages.push(msg));

    messages.forEach((msg: string) => errorMessages.push(msg));
    errorMessages.push();

    this.setState((s) => ({
      ...s,
      errorMessages: errorMessages,
    }));
  }

  private enableLoginButton() {
    this.setState((s) => ({
      ...s,
      enableLoginButton: true,
    }));
  }

  private disableLoginButton() {
    this.setState((s) => ({
      ...s,
      enableLoginButton: false,
    }));
  }

  private showForgotPasswordForm() {
    this.setState((s) => ({
      ...s,
      showForgotPasswordForm: true,
      showLogin: false,
    }));
  }

  private hideForgotPasswordForm() {
    this.setState((s) => ({
      ...s,
      showForgotPasswordForm: false,
      showLogin: true,
    }));
  }

  private async onPasswordKeyPress(key: string) {
    if (key === "Enter") {
      await this.login();
    }
  }

  render() {
    return (
      <div className="d-flex align-items-center full-height">
        {this.state.showLogin ? (
          <>
            <Form className="px-4 py-4 rounded-form">
              <img
                className="d-block mx-auto mt-5 mb-5"
                src="/img/ImageTrend_TeleHealth_TM.png"
                width="299"
                height="63"
                alt="ImageTrend Telehealth"
              ></img>
              <Row>
                <Col className="justify-content-center">
                  <Messages successMessages={this.state.successMessages} errorMessages={this.state.errorMessages} />
                </Col>
              </Row>
              <InputGroup className="mb-4 mt-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesome icon="fas fa-envelope" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  name="username"
                  placeholder="Username"
                  value={this.state.username}
                  onChange={(event) => this.onUsernameChange(event.target.value)}
                />
              </InputGroup>
              <InputGroup className="mb-4">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesome icon="fas fa-lock" className="fw-16" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={(event) => this.handlePasswordChange(event.target.value)}
                  onKeyDown={(e) => this.onPasswordKeyPress(e.key)}
                />
              </InputGroup>
              <Button
                type="button"
                className="d-block mx-auto"
                color="green"
                onClick={() => this.login()}
                disabled={!this.state.enableLoginButton}
              >
                Login
              </Button>
              <ClickableText classes="text-center mt-4" onClicked={() => this.showForgotPasswordForm()}>
                Forgot Password
              </ClickableText>
            </Form>
            {this.state.redirectToLogin ? (
              <Login {...this.props} action={LoginActions.Login} afterLogin={this.props.afterLogin ?? []} />
            ) : null}
          </>
        ) : null}
        {this.state.showReset ? (
          <Redirect
            to={{
              pathname: `/reset`,
              state: {
                firstTimeLogin: false,
                username: this.state.username,
              },
            }}
          />
        ) : null}
        {this.state.showForgotPasswordForm && <ForgotPassword showLogin={() => this.hideForgotPasswordForm()} />}
      </div>
    );
  }
}

UserLogin.contextType = NavMenuContext;
