
import React from 'react';
import { LocalAudioTrack, LocalDataTrack, LocalVideoTrack, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import { TrackPublication } from './Publications';

interface TrackSelectorProps {
	publications: TrackPublication[],
	trackSelector: (publications: TrackPublication[]) => TrackPublication | undefined
}

export function selectMainVideoTrack(publications: TrackPublication[]) {
	return publications.find(p => p.kind === 'video');
}

export function selectMainAudioTrack(publications: TrackPublication[]) {
	return publications.find(p => p.kind === 'audio');
}

export type Track = RemoteVideoTrack | LocalVideoTrack | RemoteAudioTrack | LocalAudioTrack | LocalDataTrack | null | undefined

export interface ITrackContext {
	track: Track
}

export const TrackContext = React.createContext<ITrackContext>({ track: undefined })

export class TrackSelector extends React.Component<Readonly<TrackSelectorProps>, Readonly<ITrackContext>> {
	publication: TrackPublication | undefined;

	constructor(props: Readonly<TrackSelectorProps>) {
		super(props);
		this.publication = this.props.trackSelector(this.props.publications);
		this.state = {
			track: this.publication?.track
		};
	}

	trackSubscribed = (track: Track) => {
		this.setState((state) => {
			return {
				track
			};
		});
	}
	
	trackUnsubscribed = () => {
		this.setState((state) => {
			return {
				track: undefined
			};
		});
	}

	componentDidMount() {
		this.onPublication(this.publication);
	}

	componentWillUnmount() {
		this.offPublication(this.publication);
	}

	onPublication(publication: TrackPublication | undefined) {
		if (publication) {
			publication.on('subscribed', this.trackSubscribed);
			publication.on('unsubscribed', this.trackUnsubscribed);
		}
	}

	offPublication(publication: TrackPublication | undefined) {
		if (publication) {
			publication.off('subscribed', this.trackSubscribed);
			publication.off('unsubscribed', this.trackUnsubscribed);
		}
	}

	updatePublication(newPublication: TrackPublication | undefined) {
		this.setState({
			track: newPublication?.track
		});
		this.offPublication(this.publication);
		this.onPublication(newPublication);
		this.publication = newPublication;
	}

	componentDidUpdate(prevProps: TrackSelectorProps) {
		if (this.props.publications !== prevProps.publications || this.props.trackSelector !== prevProps.trackSelector) {
			const newPublication = this.props.trackSelector(this.props.publications);
			if (newPublication !== this.publication) {
				this.updatePublication(newPublication);
			}
		}
	}

	render() {
		return (
			<TrackContext.Provider value={this.state}>
				{this.props.children}
			</TrackContext.Provider>
		)
	}
}