import OrganizationService from "../../organization/OrganizationService";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { EditTeam } from "./EditTeam";
import { ImageTrendTable } from "../Common/ImageTrendTable";
import { SecuredComponent } from "../SecuredComponent";
import { DisplayTeamsPermission } from "../../security/Permission/DisplayTeamsPermission";
import { NotGranted } from "../../security/Permission/NotGranted";
import { Organization } from "../../Organization";
import { RoleRetriever } from "../../security/RoleRetriever";
import { SecuredPersonService } from "../../services/SecuredPersonService";
import { Team } from "../../Team";
import { Column, Row as TableRow, RowPropGetter } from "react-table";
import { OrgUser } from "../../organization/OrgUser";
import { RenderPermission } from "../../security/Permission/RenderPermission";

export const Teams = () => {
  let i = 0;
  let columns = useMemo(
    () => [
      { Header: "Name", accessor: "name" } as Column<Team>,
      { Header: "Number of Users", accessor: "memberCount" } as Column<Team>,
      { Header: "Date Created", accessor: "created" } as Column<Team>,
      { Header: "Status", accessor: "status" } as Column<Team>,
      {
        Cell: (
          <Button color="white" className="btn-table float-right">
            <i className="fas fa-pencil-alt fa-lg"></i>
            {"  "}Edit Details
          </Button>
        ),
        accessor: (row: TableRow, index: Number) => "",
        id: `editteam-${i++}`,
      } as Column<Team>,
    ],
    []
  );

  const [teams, updateTeams] = useState<Team[]>();
  const [viewTeamsPermission, setViewTeamsPermission] = useState(new RenderPermission(new NotGranted()));
  const [renderComplete, setRenderComplete] = useState(false);
  const [showTeams, setShowTeams] = useState(true);
  const [editTeam, setTeamEditing] = useState<Team>();
  const [teamOrg, setTeamOrg] = useState<Organization>();

  let renderTeams = useMemo(() => teams, [teams]);

  useEffect(() => {
    const retrieveRole = async () => {
      setRenderComplete(false);
      viewTeamsPermission.disableRendering();

      let roleRetriever = new RoleRetriever(new SecuredPersonService());
      let roleClaim = await roleRetriever.ofLoggedInUser();

      setViewTeamsPermission(new RenderPermission(new DisplayTeamsPermission(roleClaim)).enableRendering());

      setRenderComplete(true);
    };

    const fetchOrg = async () => {
      var orgService = new OrganizationService();

      let orgTreeResponse = await orgService.buildOrganizationTree();

      orgTreeResponse.successful((orgTree) => {
        let org = Organization.default;
        orgTree.getRoot().visit((o) => (org = o));

        updateTeams(org.teams);
        setTeamOrg(org);
      });
    };

    retrieveRole();
    fetchOrg();
  }, []);

  const onTeamClicked = (clickedTeam: Team) => {
    setTeamEditing(clickedTeam);
    setShowTeams(false);
  };

  const onTeamUpdated = (updatedTeam: Team) => {
    if (teamOrg?.teams) {
      if (teamOrg.teams.some((t) => t.id === updatedTeam.id)) {
        //editing existing team
        let updatedOrg = teamOrg.updateTeam(updatedTeam);
        setTeamOrg(updatedOrg);
        updateTeams(updatedOrg.teams);
      } else {
        //added a new team
        let updatedOrg = teamOrg.addTeam(updatedTeam);
        setTeamOrg(updatedOrg);
        updateTeams(updatedOrg.teams);
      }
    }

    setTeamEditing(undefined);
    setShowTeams(true);
  };

  const onCancelModification = () => {
    setTeamEditing(undefined);
    setShowTeams(true);
  };

  const teamsTable = () => {
    return showTeams ? (
      <>
        <Row className="upper-content">
          <Col>
            <h1>Teams</h1>
          </Col>
          <Col className="pt-2">
            <Button
              color="orange"
              className="float-right"
              type="button"
              onClick={() => {
                //setShowAdd(true);
                setShowTeams(false);
              }}
            >
              <i className="fas fa-plus fa-lg"></i> Add Team
            </Button>
          </Col>
        </Row>
        <Row className="lower-content">
          <Col className="table-responsive">
            <ImageTrendTable<Team>
              renderableData={renderTeams ?? []}
              headers={columns}
              rowProps={(row: Team) =>
                ({
                  className: "clickable",
                  onClick: () => onTeamClicked(row),
                } as RowPropGetter<Team>)
              }
            />
          </Col>
        </Row>
      </>
    ) : editTeam ? (
      <EditTeam
        teamEditing={editTeam}
        org={teamOrg!}
        onUpdated={(updatedTeam) => onTeamUpdated(updatedTeam)}
        onExit={() => onCancelModification()}
      />
    ) : (
      <EditTeam
        org={teamOrg!}
        onUpdated={(updatedTeam) => onTeamUpdated(updatedTeam)}
        onExit={() => onCancelModification()}
      />
    );
  };

  return renderComplete ? <SecuredComponent permission={viewTeamsPermission}>{teamsTable()}</SecuredComponent> : null;
};
