import React, { Fragment, useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';
import { DemographicSuffix, JuniorSuffix, SeniorSuffix } from './DemographicSuffix';

interface SuffixSelectorProps {
	selected?: string;
	onSuffixSelected: (suffix?: DemographicSuffix) => void;
}

export const SuffixSelector = ({ selected, onSuffixSelected }: SuffixSelectorProps) => {
	const [chosenSuffix, setChosenSuffix] = useState<DemographicSuffix>()

	const pleaseSelectSuffix = DemographicSuffix.empty();
	const jrSuffix = new JuniorSuffix();
	const srSuffix = new SeniorSuffix();
	
	const pleaseSelectText = pleaseSelectSuffix.descriptor;
	const jrSuffixText = jrSuffix.descriptor;
	const srSuffixText = srSuffix.descriptor;
	

	useEffect(() => {
		onSuffixSelected(chosenSuffix);
	}, [chosenSuffix])

	const onSuffixChosen = (descriptor?: string) => {
		if(!descriptor) {
			setChosenSuffix(undefined);
		}

		const suffixChooser = {
			[pleaseSelectText]: () => setChosenSuffix(pleaseSelectSuffix),
			[jrSuffixText]: () => setChosenSuffix(jrSuffix),
			[srSuffixText]: () => setChosenSuffix(srSuffix)
		};

		suffixChooser[descriptor!]();
	}

	return (<Fragment>
		<Label for="suffix">Suffix</Label>
		<Input id="suffix" type="select" name="suffix" value={selected} onChange={event => onSuffixChosen(event.target.value)}>
			<option key="please-select" value={"Please Select"}>Please Select</option>
			<option key="junior" value={new JuniorSuffix().descriptor}>{new JuniorSuffix().descriptor}</option>
			<option key="senior" value={new SeniorSuffix().descriptor}>{new SeniorSuffix().descriptor}</option>
		</Input>
	</Fragment>)
}