import { ApiUrl } from "ApiConstants";
import CallLog, { CallLogRow } from "callCenter/CallLog";
import { ApiRequest } from "common/ApiRequest";
import { ApiResponseHandler } from "common/ApiResponseHandler";
import CallMappers from "common/CallMappers";
import { CallResponse } from "common/CallResponse";
import { Messages, MessageSubscription } from "common/Messaging";
import DeclinedCallLog from "common/messaging/DeclinedCallLog";
import { Strings } from "common/Strings";
import { AnswerCallButton } from "components/CallCenter/AnswerCallButton";
import { DeclineCallButton } from "components/CallCenter/DeclineCallButton";
import { ImageTrendTable, ImageTrendTableHeader } from "components/Common/ImageTrendTable";
import { LoadingIndicator } from "components/Common/LoadingIndicator";
import { SecuredComponent } from "components/SecuredComponent";
import { statusService } from "components/SignalR/StatusService";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Column } from "react-table";
import { Granted } from "security/Permission/Granted";
import { RenderPermission } from "security/Permission/RenderPermission";
import { CallAnsweredNotification, CallEndedNotification, CallNotification } from "telehealth-abstractions";
import { CallService, HubStatusMethod } from "telehealth-api";
import MessagingContext from "common/messaging/MessagingContext";

export interface ActiveCallProps extends RouteComponentProps {
  callService: CallService;
}

export const ActiveCalls = ({ history, callService }: ActiveCallProps) => {
  const [activeCalls, setActiveCalls] = useState<CallLogRow[]>([]);
  const [callDeclined, setDeclinedCall] = useState<CallLog>();
  const [callsRetrieved, setCallsRetrieved] = useState(false);
  const [removeActiveCallId, setRemoveActiveCallId] = useState<number>();
  const [viewCallsPermission, setViewPermission] = useState(new RenderPermission(new Granted()));
  const messagePublisher = useContext(MessagingContext);

  useEffect(() => {
    let isMounted = true;
    const retrieveCalls = async () => {
      let callsRequest = new ApiRequest();

      let callsResponse = await callsRequest.send(ApiUrl.CallUrl);

      const callHandler = new ApiResponseHandler<Iterable<CallLogRow>, CallResponse[]>();
      const callMapper = new CallMappers();
      let callHandling = await callHandler.handle(
        callsResponse,
        (callsResponses) =>
          callsResponses
            .filter((cr) => !cr.endDate)
            .filter((cr) => !cr.declinedByMe)
            .map((cr) => callMapper.callLogFrom(cr).toCallLogRow()),
        () => []
      );

      callHandling.successful((calls) => {
        if (isMounted) {
          setActiveCalls(Array.from(calls).filter((call) => call.answeredState === Strings.call.NotAnswered));

          const grantedPermission = new RenderPermission(new Granted());
          setViewPermission(grantedPermission.enableRendering());
          setCallsRetrieved(true);
        }
      });
    };

    retrieveCalls();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let subscriptions: number[] = [];
    let clientSubscriptions: MessageSubscription[] = [];
    const callMapper = new CallMappers();

    const updateActiveCalls = (incomingCall: CallLogRow) => {
      setActiveCalls([incomingCall, ...activeCalls]);
    };

    const listenForIncomingCalls = async () => {
      let subscription = statusService.subscribe(async (incomingCall: CallNotification) => {
        const callRequest = new ApiRequest();
        let callLookupResponse = await callRequest.get(incomingCall.CallId.toString(), ApiUrl.CallUrl);

        //lookup the call to get needed call information like the recpient
        const callLookupHandler = new ApiResponseHandler<CallLogRow, CallResponse>();

        let callLogResponse = await callLookupHandler.handle(
          callLookupResponse,
          (cr) =>
            callMapper
              .callLogFromNotification(incomingCall, cr.recipient ?? cr.recipientTeam ?? undefined)
              .toCallLogRow(),
          () => callMapper.callLogFromNotification(incomingCall).toCallLogRow()
        );

        let callLogRow = callLogResponse.item;
        callLogResponse.successful((clr) => (callLogRow.recentlyReceived = true));

        updateActiveCalls(callLogRow);
      }, HubStatusMethod.IncomingCall);

      subscriptions.push(subscription);
    };

    const listenForAnsweredCalls = () => {
      //call can be answered from the toast message or this table, listen for answered calls and remove it from the table if needed
      let answeredSub = statusService.subscribe((answeredCall: CallAnsweredNotification) => {
        setRemoveActiveCallId(answeredCall.CallId);
      }, HubStatusMethod.CallAnswered);

      subscriptions.push(answeredSub);
    };

    //Remove any calls that get end via a participant ending a call
    const listenForEndedCalls = () => {
      let sub = statusService.subscribe((endedCall: CallEndedNotification) => {
        setRemoveActiveCallId(endedCall.CallId);
      }, HubStatusMethod.CallEnded);

      subscriptions.push(sub);

      //listen to calls ignored, not declined, from a toast notification
      let clientSub = messagePublisher.subscribeTo(Messages.userDeclinedCall(), (message: DeclinedCallLog) => {
        setRemoveActiveCallId(message.declinedCallLog.callId);
      });

      clientSubscriptions.push(clientSub);
    };

    listenForIncomingCalls();
    listenForAnsweredCalls();
    listenForEndedCalls();

    return () => {
      for (let sub of subscriptions) {
        statusService.unsubscribe(sub);
      }

      subscriptions = [];

      for (let clientSub of clientSubscriptions) {
        messagePublisher.unsubscribeFrom(clientSub);
      }

      clientSubscriptions = [];
    };
  }, [activeCalls, messagePublisher]);

  //Remove the call specified by removeActiveCall from the active calls table
  useEffect(() => {
    if (!removeActiveCallId) {
      return;
    }

    const callIndex = activeCalls.findIndex((activeCall) => activeCall.callId === removeActiveCallId);

    if (callIndex > -1) {
      let activeCallsCopy = [...activeCalls];
      const removedItems = activeCallsCopy.splice(callIndex, 1);

      setRemoveActiveCallId(undefined);
      setActiveCalls(activeCallsCopy);
    }
  }, [activeCalls, removeActiveCallId]);

  //Move Declined call to past calls table
  useEffect(() => {
    if (!callDeclined) {
      return;
    }

    messagePublisher.publish(Messages.userDeclinedCall(), new DeclinedCallLog(callDeclined));
  }, [callDeclined, messagePublisher]);

  const answerCall = async (call: CallLog) => {
    let answeredResponse = await callService.answerCall({
      callId: call.callId,
    });

    if (answeredResponse.answered) {
      history.push(`/room?roomId=${call.room}`);
      statusService.disableCallNotifications();
    }
  };

  const declineCall = async (call: CallLog) => {
    await callService.declineCall({
      callId: call.callId,
    });

    setRemoveActiveCallId(call.callId);
    setDeclinedCall(call);
  };

  const activeCallsColumns: Column<CallLogRow>[] = [
    new ImageTrendTableHeader<CallLogRow>(
      "Date Received",
      (callLog) => callLog.callReceivedDateOnlyFormatted,
      "active-call-received-date"
    ) as Column<CallLogRow>,
    new ImageTrendTableHeader<CallLogRow>(
      "Start Time",
      (callLog) => callLog.callReceivedTimeOnlyFormatted,
      "active-call-received-time"
    ) as Column<CallLogRow>,
    new ImageTrendTableHeader<CallLogRow>(
      "Recipient",
      (callLog) => callLog.recipient,
      "active-call-to"
    ) as Column<CallLogRow>,
    new ImageTrendTableHeader<CallLogRow>(
      "Caller",
      (callLog) => callLog.displayCaller(),
      "active-call-from"
    ) as Column<CallLogRow>,
    new ImageTrendTableHeader(
      "",
      (callLog) => {
        return (
          <>
            <AnswerCallButton
              className="d-block d-lg-none"
              onClick={() => answerCall(callLog)}
              aria-role="button"
              title={`Answer call from ${callLog.displayCaller()}`}
            />
            <AnswerCallButton
              className="d-none d-lg-inline-block mr-lg-2"
              onClick={() => answerCall(callLog)}
              aria-role="button"
              title={`Answer call from ${callLog.displayCaller()}`}
            >
              Answer
            </AnswerCallButton>
            <DeclineCallButton
              className="d-block d-md-inline-block mt-3 mt-md-0"
              onClick={() => declineCall(callLog)}
              aria-role="button"
              title={`Decline call from ${callLog.displayCaller()}`}
            />
          </>
        );
      },
      "answer-call"
    ) as Column<CallLogRow>,
  ];

  return (
    <>
      {!callsRetrieved && <LoadingIndicator loadingMessage="Loading Calls..." />}
      {callsRetrieved && (
        <SecuredComponent permission={viewCallsPermission}>
          <ImageTrendTable
            renderableData={activeCalls}
            headers={activeCallsColumns}
            rowProps={(row: CallLogRow) => {
              if (row.recentlyReceived) {
                return { className: "new-row" };
              }

              return { className: "" };
            }}
          />
        </SecuredComponent>
      )}
    </>
  );
};
