import React, { PropsWithChildren } from 'react';
import { Alert } from 'reactstrap';

export const ErrorMessages = ({children}: PropsWithChildren<{}>) => {

	return(
		<Alert color="danger">
			{children}
		</Alert>
	)
}