import { DateTime } from "luxon";

export default class CallLog {
	private readonly _caller: string;
	private readonly _agency?: string;
	private readonly _agencyNumber?: number;
	private _answeredBy?: string;
	private  _roomId?: string;
	readonly callId: number;
	readonly recipient: string;
	readonly receivedOn: DateTime;

	constructor(id: number, caller: string,	callerAgency: string, callRecipientName: string, received: DateTime, callerAgencyNumber?: number) {
		this.callId = id;
		this._caller = caller;
		this._agency = callerAgency;
		this._agencyNumber = callerAgencyNumber;
		this.recipient = callRecipientName;
		this.receivedOn = received;
	}

	displayCaller(): string {
		let display = '';

		if(this._agency) {
			display = this.appendDisplayPart(this._agency, display);
		}

		if(this._agencyNumber) {
			display = this.appendDisplayPart(this._agencyNumber.toString(), display);
		}

		if(display) {
			display = display.concat(";")
		}

		display = this.appendDisplayPart(this._caller, display);

		return display;
	 }

	 get startTime(): string {
		 var now = DateTime.now();

		 //convert to user's local
		 const localReceivedOn = this.receivedOn.setZone(now.zone);
		 return localReceivedOn.toLocaleString(DateTime.TIME_SIMPLE)
	 }

	 get answeredState(): string {
		 let state = "Not Answered";

		 this.answeredBy(person => state = "Answered");
		 return state;
	 }

	 get callReceivedFormatted() {
		 return this.formatForUser(this.receivedOn);
	 }

	 get callReceivedDateOnlyFormatted() {
		 return this.receivedOn.toLocaleString(DateTime.DATE_SHORT);
	 }

	 get callReceivedTimeOnlyFormatted() {
		return this.receivedOn.toLocaleString(DateTime.TIME_24_SIMPLE);
	 }

	 get room(): string | undefined {
		 return this._roomId;
	 }

	 answeredBy(whenAnsweredBy: (person: string) => {}) {
		 if(this._answeredBy) {
			 whenAnsweredBy(this._answeredBy);
		 }
	 }

	 answer(byPerson: string) {
		 this._answeredBy = byPerson;
	 }

	formatForUser(dateTime: DateTime): string {
		return`${dateTime.toLocaleString(DateTime.DATE_SHORT)}, ${dateTime.toLocaleString(DateTime.TIME_24_SIMPLE)}`
	}

	 inRoom(roomId: string) {
		 this._roomId = roomId;
	 }

	 toCallLogRow() {
		 let callLogRow =  new CallLogRow(this.callId, this._caller, this._agency ?? '', this.recipient, this.receivedOn, this._agencyNumber);
		
		if(this._roomId) {
			callLogRow.inRoom(this._roomId);
		}

		callLogRow._answeredBy = this._answeredBy;

		return callLogRow;
	 }

	 private appendDisplayPart(displayPart: string, toCaller: string) {
		 return toCaller ? toCaller.concat(` ${displayPart}`) : toCaller.concat(`${displayPart}`)
	 }
}

export class CallLogRow extends CallLog {
	recentlyReceived: boolean = false;

	constructor(id: number, caller: string,	callerAgency: string, callRecipientName: string, received: DateTime, callerAgencyNumber?: number) {
		super(id, caller, callerAgency, callRecipientName, received, callerAgencyNumber);
	}
}
