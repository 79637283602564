import { LocalParticipant, createLocalVideoTrack, LocalVideoTrack } from 'twilio-video';
import { EventEmitter } from 'events';

export enum VideoManagerEvents {
	VIDEO_STATUS = "videoStatus"
}

export class VideoManager extends EventEmitter {
	private readonly participant: LocalParticipant;
	private deviceId: string;
	private videoTrack: LocalVideoTrack | null = null;
	private isSwitching: boolean = false;

	constructor(participant: LocalParticipant, deviceId: string) {
		super();
		this.participant = participant;
		this.deviceId = deviceId;
		const trackPublications = Array.from(participant.videoTracks.values());
		if (trackPublications.length > 0) {
			this.videoTrack = trackPublications[0].track;
		}
	}

	async changeCamera(newDeviceId: string) {
		this.pauseVideo();
		this.deviceId = newDeviceId;
		await this.resumeVideo();
	}

	async pauseOrUnpauseVideo() {
		if (!this.videoTrack) {
			await this.resumeVideo();
		} else {
			this.pauseVideo();
		}
	}

	getVideoStatus() {
		return !!this.videoTrack;
	}

	pauseVideo() {
		if (this.isSwitching) {
			return;
		}
		this.isSwitching = true;
		if (!this.videoTrack) {
			return;
		}
		// We are stopping and unpublishing the track here in order to make your camera light turn off.
		// You could alternatively use enable()/disable() here but the camera light would remain on.
		this.videoTrack.stop();
		const localTrackPublication = this.participant.unpublishTrack(this.videoTrack);
		// Twilio apparently has a bug in their SDK that doesn't fire this event,
		// but the link requires a login: https://issues.corp.twilio.com/browse/JSDK-2592
        this.participant.emit('trackUnpublished', localTrackPublication);
		this.videoTrack = null;
		this.isSwitching = false;
		this.emit(VideoManagerEvents.VIDEO_STATUS, false);
	}

	async resumeVideo() {
		if (this.isSwitching) {
			return;
		}
		this.isSwitching = true;
		this.videoTrack = await createLocalVideoTrack({
			deviceId: {
				exact: this.deviceId
			}
		});
		await this.participant.publishTrack(this.videoTrack);
		this.isSwitching = false;
		this.emit(VideoManagerEvents.VIDEO_STATUS, true);
	}
}