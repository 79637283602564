import React, { PropsWithChildren } from "react";
import MessagingContext from "../../common/messaging/MessagingContext";
import Message from "../../common/Message";
import messenger, { MessageSubscription } from "../../common/Messaging";
import voidMessage from "../../common/messaging/VoidMessage";

const MessagingContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const handlePublish = <T,>(msg: Message, value: T) => {
    messenger.publish(msg, value);
  };

  const handleSubscribe = <T,>(msg: Message, callback: (value: T) => void) => {
    if (!msg) {
      return new MessageSubscription(-1, voidMessage, () => {});
    }

    const sub = messenger.subscribe(msg, callback);
    return sub;
  };

  const handleUnsubscribe = (messageSub: MessageSubscription) => {
    if (!messageSub) {
      return;
    }

    messenger.unsubscribeFrom(messageSub);
  };

  return (
    <MessagingContext.Provider
      value={{
        publish: handlePublish,
        subscribeTo: handleSubscribe,
        unsubscribeFrom: handleUnsubscribe,
      }}
    >
      {children}
    </MessagingContext.Provider>
  );
};

export default MessagingContextProvider;
