import TreeNode from './treeNode';
import Queue from './queue';

export default class Tree<T> {
	private root: TreeNode<T>;

	/**
	 * Constructs a new instance of a Tree, which holds items of type T
	 * 
	 * @param root the node which is the root of this tree.
	 */
	constructor(root: TreeNode<T>) {
		if(!root) {
			throw new Error('A root for this tree was not defined. Initialize the tree by passing a non-null instance of a TreeNode<T>');
		}

		this.root = root;
	}

	/** 
	 * Visits every node in the tree, starting from the root.
	 * It visits the node, and then its children in the order
	 * they were added to the node.
	 * 
	 * @param nodeVisitor  a callback which will be called with the
	 * 						node being visited as a parameter. This 
	 * 						cannot be null or undefined. 
	 */
	traverse(nodeVisitor: (visitingNode: TreeNode<T>) => void) {
		var queue = new Queue<TreeNode<T>>();

		var currentNode = this.root;

		while(currentNode != null) {
			nodeVisitor(currentNode);

			currentNode.visitChildren(visitingNodeChild => {
				queue.enqueue(visitingNodeChild);
			});

			if(!queue.peek()) {
				currentNode = null as any;
			} else {
				currentNode = queue.dequeue();
			}
		}
	}

	/**
	 * Searches the tree for a node in the tree.
	 * 
	 * @param searchNode the node in the tree being searched for.
	 * @returns the found TreeNode<T>, or null if not found.
	 */
	find(searchNode: TreeNode<T>, nodesEqual: ((first: TreeNode<T>, second: TreeNode<T>) => boolean)) : TreeNode<T> | null {
		var foundNode: TreeNode<T> | null = null;

		this.traverse((visitingNode) => {
			if(!nodesEqual) {
				let firstValue: T | null = null;
				let secondValue: T | null = null;

				searchNode.visit(data => firstValue = data);
				visitingNode.visit(data => secondValue = data);

				if(firstValue === secondValue){
					foundNode = visitingNode;
				}

			} else if(nodesEqual(visitingNode, searchNode)) {
				foundNode = visitingNode;
			}
		});
		
		return foundNode;
	}

	/**
	 * Finds the depth of the node in the tree. Returns the depth of the node if
	 * its in the tree, or -1 if not.
	 * 
	 * @param node the node to find the depth of in this tree
	 */
	depthOf(node: TreeNode<T>): number {
		let currentLevel: number = -1;

		var nodeData: T | null = null;
		var rootData: T | null = null;

		node.visit(data => nodeData = data);
		this.root.visit(data => rootData = data);
		

		if(nodeData === rootData) {
			return 0;
		}

		currentLevel = this.recurseNodeDepth(node, this.root, 0);

		return currentLevel;
	}

	getRoot(): TreeNode<T> {
		return this.root;
	}

	private recurseNodeDepth(targetNode: TreeNode<T>, currentNode: TreeNode<T>, level: number): number {
		var targetNodeData: T | null = null;
		var currentNodeData: T | null = null;

		targetNode.visit(data => targetNodeData = data);
		currentNode.visit(data => currentNodeData = data);

		if(targetNodeData === currentNodeData) {
			return level;
		}
		
		var nextLevel = -1;

		currentNode.visitChildren(child => {
			nextLevel = this.recurseNodeDepth(targetNode, child, level + 1);

			if(nextLevel !== -1) {
				return nextLevel;
			}
		});

		return nextLevel;
	}
}