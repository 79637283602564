export class CookieManager {
	readonly cameraCookieName = "selected-camera";
    readonly microphoneCookieName = "selected-microphone";

    private readCookie(name: string) {
		var nameEq,
			cookieArray = document.cookie.split(";"),
			i,
			len = cookieArray.length,
			c;
		if (!name) {
			return null;
		}
		nameEq = name + "=";
		for (i = 0; i < len; i++) {
			c = cookieArray[i];
			while (c.charAt(0) === " ") {
				c = c.substring(1, c.length);
			}
			if (c.indexOf(nameEq) === 0) {
				return c.substring(nameEq.length, c.length);
			}
		}
		return null;
	}

    private setCookie = function (name: string, value: string, expirationDate: Date) {
		var expires;
		if (!!expirationDate) {
			expires = "; expires=" + expirationDate.toUTCString();
		} else {
			expires = "";
		}
		if (!name) {
			return;
		}
		if (value === null || value === undefined) {
			// not a good situation because readCookie will return "null" instead of null and "undefined" instead of undefined
			throw new Error("value was null or undefined in setCookie");
		}
		var isInsecureCookieAllowed = (location.hostname === "localhost" || location.hostname === "127.0.0.1");
		document.cookie = name + "=" + value + expires + "; path=/" + (isInsecureCookieAllowed ? "" : ";secure") + "; SameSite=Lax";
	}

    getSelectedCamera() {
        return this.readCookie(this.cameraCookieName);
    }

    setSelectedCamera(deviceId: string) {
        const date = new Date();
        date.setUTCFullYear(date.getUTCFullYear() + 1);
        return this.setCookie(this.cameraCookieName, deviceId, date);
    }

    getSelectedMicrophone() {
        return this.readCookie(this.microphoneCookieName);
    }

    setSelectedMicrophone(deviceId: string) {
        const date = new Date();
        date.setUTCFullYear(date.getUTCFullYear() + 1);
        return this.setCookie(this.microphoneCookieName, deviceId, date);
    }
}

export var cookieManager = new CookieManager();