import { IPermission } from "../IPermission";

export class Granted implements IPermission {
	granted(onGranted: () => void) {
		if(!!!onGranted) {
			throw new Error("onGranted callback was not provided");
		}

		onGranted();
	}

	async grantedAsync(onGrantedAsync: () => Promise<void>) {
		if(!onGrantedAsync) {
			throw new Error("onGrantedAsync callback was not provided");
		}

		await onGrantedAsync();
	}

	denied(onDenied: () => void) {
	}

	deniedAsync(onDenialAsync: () => Promise<void>) {
		return Promise.resolve();
	}
}