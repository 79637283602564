import { LocalParticipant, LocalAudioTrack, createLocalAudioTrack } from 'twilio-video';
import { EventEmitter } from 'events';

export enum AudioManagerEvents {
	AUDIO_MUTED = "audioMuted"
}

export class AudioManager extends EventEmitter {
	private readonly participant: LocalParticipant;
	private _isMuted: boolean = false;
	
	constructor(participant: LocalParticipant) {
		super();
		this.participant = participant;
	}

	async changeMicrophone(newDeviceId: string) {
		this.participant.audioTracks.forEach(a => {
			a.track.stop();
			this.participant.unpublishTrack(a.track);
		});
		const audioTrack = await createLocalAudioTrack({
			deviceId: {
				exact: newDeviceId
			}
		});
		await this.participant.publishTrack(audioTrack);
	}

	mute() {
		this.participant.audioTracks.forEach(a => a.track.disable());
		this._isMuted = true;
		this.emit(AudioManagerEvents.AUDIO_MUTED, true);
	}

	unmute() {
		this.participant.audioTracks.forEach(a => a.track.enable());
		this._isMuted = false;
		this.emit(AudioManagerEvents.AUDIO_MUTED, false);
	}

	isMuted() {
		return this._isMuted;
	}
}