export enum ApiUrl {
	NonTenantOrgLookupUrl = "/api/v1/security/organization",
	CallUrl = "/api/v1/call",
	OrgUrl = "/api/v1/organization",
	SecurityUrl = "/api/v1/security",
	SettingUrl = "/api/v1/setting",
	UserUrl = '/api/v1/user'
}

export enum SecurityStrings {
	AdminRoleName = "Administrator",
	RoleClaimType = "role",
	UserRoleName = "User"
}