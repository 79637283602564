export class NameParts {
	readonly firstName: string;
	readonly lastName: string;
	readonly middleName?: string;
	private _prefix?: string;
	private _suffix?: string;

	constructor(firstName: string, lastName: string, middleName?: string) {
		if(!!!firstName) {
			throw new Error("firstName was not provided");
		}

		if(!!!lastName) {
			throw new Error("lastName was not provided");
		}

		this.firstName = firstName;
		this.lastName = lastName;

		if(middleName) {
			this.middleName = middleName;	
		}
	}

	get prefix() {
		return this._prefix;
	}

	get suffix() {
		return this._suffix;
	}

	addPrefix(prefix: string) {
		if(!this._prefix) {
			this._prefix = prefix;
		}
	}

	addSuffix(suffix: string) {
		if(!this._suffix) {
			this._suffix = suffix;
		}
	}

	static EmptyName: NameParts = new NameParts("First", "Last");
} 