import React, { Component } from 'react';
import { Team, TeamMember } from 'telehealth-abstractions';
import { CallTreeTeamMember } from './CallTreeTeamMember';

import './CallTreeTeam.css';

export interface CallTreeTeamProps {
	createTeamCall: (team: Team) => Promise<void>,
	createTeamMemberCall: (teamMember: TeamMember) => Promise<void>,
	team: Team
}

interface CallTreeTeamState {
	isExpanded: boolean
}

export class CallTreeTeam extends Component<Readonly<CallTreeTeamProps>, CallTreeTeamState> {
	team: Team;
	createTeamCall: (team: Team) => Promise<void>;
	createTeamMemberCall: (teamMember: TeamMember) => Promise<void>;
	isAvailable: boolean;

	constructor(props: Readonly<CallTreeTeamProps>) {
		super(props);
		this.team = props.team;
		this.createTeamCall = props.createTeamCall;
		this.createTeamMemberCall = props.createTeamMemberCall;
		this.isAvailable = props.team.existingMembers && props.team.existingMembers.some(m => {
			return m.status == true;
		});
		this.state = {
			isExpanded: false
		};
	}

	expandOrContract() {
		this.setState({ isExpanded: !this.state.isExpanded });
	}

	renderTeamMember(teamMember: TeamMember) {
		return (<CallTreeTeamMember teamMember={teamMember} createTeamMemberCall={this.createTeamMemberCall}></CallTreeTeamMember>);
	}

	render() {
		let teamMembersContents: JSX.Element | null = null;
		let expandoIcon: JSX.Element | null = null;
		let headerClassName = "call-tree-team-header";
		if (this.state.isExpanded) {
			let teamMembers = this.team.existingMembers.map(this.renderTeamMember.bind(this));
			teamMembersContents = (
				<div className="call-tree-team-members">
					{teamMembers}
				</div>
			);
			expandoIcon = (<i className="fas fa-angle-up fa-2x expando-icon"></i>);
			headerClassName += " expanded";
		} else {
			expandoIcon = (<i className="fas fa-angle-down fa-2x expando-icon"></i>);
			teamMembersContents = (<div className="call-tree-team-members-spacer"></div>);
		}
		let callButton: JSX.Element | null = null;
		if (this.isAvailable) {
			callButton = (<button type="button" className="btn" onClick={this.createTeamCall.bind(this, this.team)}><i className="fa fa-phone"></i>Start Call</button>);
		} else {
			callButton = (<button type="button" className="btn btn-disabled">Unavailable</button>);
		}
		
		return (
			<div>
				<div className={headerClassName} onClick={this.expandOrContract.bind(this)}>
					<p className="call-tree-team-title">{this.team.name}</p>
					{callButton}
					{expandoIcon}
				</div>
				{teamMembersContents}
			</div>
			);
	}
}