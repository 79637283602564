import React, { PropsWithChildren } from 'react';
import { Col, Row } from 'reactstrap';

import './Panel.scss'

export interface PanelProps extends PropsWithChildren<{}>{
	name?: string;
	infoText?: string
}

export const PanelInfo = ({name, infoText}: PanelProps) => {
	return(
		<Row>
			<Col className="clearfix">
			{ name && 
				<h5 className="float-left">
					{name}
				</h5> }
			{ infoText && 
				<p className="float-right">
					{infoText}
				</p>}
			</Col>
		</Row>
	)
}

export const Panel = ({name, infoText, children}: PanelProps) => {
	return(
		<div className="panel px-3 py-3 rounded">
			{ (name || infoText) && 
			<PanelInfo name={name} infoText={infoText} /> }
			<Row>
				<Col>
					{children}
				</Col>
			</Row>
		</div>
	)
}