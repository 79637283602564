///
//Represents a response from an api call which returns json.
//
//Note: this is poorly named as it is attempting to return json in both the 
//succeeded and failed methods when there may not be json being returned from the api.
//Probably warrants some refactoring to not make assumptions about the content returns
//and only worry about the status code.
export abstract class ApiResponse<T> {
	readonly statusCode: number = 0;
	readonly item: T;
	protected _failureMessages: string[] = [];

	constructor(statusCode: number, item: T) {
		if(!item) {
			throw new Error("item cannot be null");
		}

		if(statusCode < 0) {
			throw new Error(`Invalid status code of ${statusCode} provided`)
		}

		this.statusCode = statusCode;
		this.item = item;
	}

	abstract successful(onSucceededResponse: (result: T) => void): void;

	abstract failure(onFailed: () => void): void;

	errorMessages() {
		return [...this._failureMessages];
	} 

	static async create<T, TResponse>(response: Response, successItemFactory: (json: TResponse) => T, failureItemFactory: () => T): Promise<ApiResponse<T>> {
		if(!!!response) {
			throw new Error("response was not provided");
		}

		if(!successItemFactory){
			throw new Error("successItemFactory was not provided");
		}

		if(!failureItemFactory){
			throw new Error("failureItemFactory was not provided");
		}

		if(response.status < 400) {
			return await this.succeeded(response, successItemFactory);
		} 

		return this.failed(response, failureItemFactory);
	}

	static async succeeded<T>(response: Response, itemFactory: (json: any) => T) {
		this.creationChecks(response, itemFactory);

		var contentType = response.headers.get('Content-Type');

		let responseJson = '{}';
		let item = {};
		if(contentType && contentType.includes("application/json")) {
			responseJson = await response.json();
			item = itemFactory(responseJson);
		}

		return new SuccessfulApiResponse(response.status, item) as ApiResponse<T>;;
	}

	static async failed<T>(response: Response, itemFactory: () => T) {
		this.creationChecks(response, itemFactory);

		let item = itemFactory();

		let failedApiResponse = new FailureApiResponse<T>(response.status, item);

		try {
			let failedResponse = await response.json();

			if(failedResponse.failureMessages) {
				for(let failureMessage of failedResponse.failureMessages) {
					failedApiResponse._failureMessages.push(failureMessage);
				}
			}
		} catch(error) {
			console.error(`${error}`);
			failedApiResponse._failureMessages.push("We're sorry, we ran into an issue. We are looking into it now.");
		}

		return failedApiResponse as ApiResponse<T>;
	}

	private static creationChecks<T>(response: Response, itemFactory: (response: Response) => T){
		if(!!!response) { 
			throw new Error("response was not provided");
		}

		if(!itemFactory){
			throw new Error("itemFactory was not provided")
		}
	}
}

export class SuccessfulApiResponse<T> extends ApiResponse<T> {
	successful(onSucceded: (result: T) => void) {
		onSucceded(this.item)
	}

	failure(onFailed: () => void) {
	}
}

export class FailureApiResponse<T> extends ApiResponse<T> {
	successful(onSucceededResponse: (result: T) => void) {
	}

	failure(onFailed: (failedResponse: FailureApiResponse<T>) => void) {
			onFailed(this as FailureApiResponse<T>)
	}
}