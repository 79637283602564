import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import NavMenuContext from 'components/Navigation/NavMenuContext';

import './Home.scss'

export class Home extends Component {
	static displayName = Home.name;
	
	componentDidMount() {
		this.context.showNav();
	}

	render() {
		return (
			<>
				<Row className="upper-content">
					<Col>
						<h1>Dashboard</h1>
					</Col>
				</Row>
				<Row>
					<Col sm className="mt-4 mt-md-0">
						<Card className="border-0 dashboard">
							<CardBody className="d-flex flex-column">
								<CardTitle>
									<h2 className="text-center font-weight-bold">Answer a Call</h2>
								</CardTitle>
								<img className="d-block mx-auto" src="/img/dashboard_answerCall.jpg" width="275" height="179"></img>
								<p className="mt-3">Incoming calls will appear in a notification message within the Telehealth portal. Details of the caller and agency will appear in addition to the ability to answer (or decline) the incoming call.</p>
							</CardBody>
						</Card>
					</Col>
					<Col sm className="mt-4 mt-md-0">
						<Card className="border-0 dashboard">
							<CardBody className="d-flex flex-column">
								<CardTitle>
									<h2 className="text-center font-weight-bold">Enable Browser Access</h2>
								</CardTitle>
								<img className="d-block mx-auto" src="/img/dashboard_enableAccess.jpg" width="275" height="179"></img>
								<p className="mt-3">Upon answering your first incoming call, your browser will prompt you to allow access to both your camera and your microphone. Please do so to enable answering all future calls successfully.</p>
							</CardBody>
						</Card>
					</Col>
					<Col sm className="my-4 mt-md-0">
						<Card className="border-0 dashboard">
							<CardBody className="d-flex flex-column">
								<CardTitle>
									<h2 className="text-center font-weight-bold">Improve the App</h2>
								</CardTitle>
								<img className="d-block mx-auto" src="/img/dashboard_feedback.jpg" width="275" height="179"></img>
								<p className="mt-3">We want to hear from you! Continuous updates will be made to our platform. <a href="https://support.imagetrend.com" target="_blank">Tell us what works</a> or what we can improve on!</p>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</>
		);
	}
}

Home.contextType = NavMenuContext;
