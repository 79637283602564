import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { RoomManager, RoomManagerEvents } from './RoomManager';
import { DeviceManager, Devices, DeviceInfo, DeviceManagerEvents } from './DeviceManager';
import { SelectInput } from './common/SelectInput';

import './SettingsSlider.css';

interface SettingsSliderProps {
    roomManager: RoomManager,
    deviceManager: DeviceManager
}

interface SettingsSliderState {
    sliderCss: string,
    selectedCamera: string | undefined,
    selectedMicrophone: string | undefined
}

export class SettingsSlider extends React.Component<Readonly<SettingsSliderProps>, Readonly<SettingsSliderState>> {
    chatComposerRef: HTMLTextAreaElement | null = null;
	chatMessagesRef: HTMLElement | null = null;
    devices: Devices;

	constructor(props: Readonly<SettingsSliderProps>) {
		super(props);
        this.devices = this.getDevices();
        this.state = {
            sliderCss: 'room-slider settings-slider',
            selectedCamera: this.devices.selectedVideoDevice?.deviceId,
            selectedMicrophone: this.devices.selectedAudioDevice?.deviceId
        };
	}

    componentDidMount() {
        this.props.roomManager.on(RoomManagerEvents.SHOW_SETTINGS_STATUS, this.hideOrShowSlider);
        this.props.deviceManager.on(DeviceManagerEvents.SELECTED_CAMERA_CHANGED, this.loadDevices);
        this.props.deviceManager.on(DeviceManagerEvents.SELECTED_MICROPHONE_CHANGED, this.loadDevices);
    }

    componentWillUnmount() {
        this.props.roomManager.off(RoomManagerEvents.SHOW_SETTINGS_STATUS, this.hideOrShowSlider);
        this.props.deviceManager.off(DeviceManagerEvents.SELECTED_CAMERA_CHANGED, this.loadDevices);
        this.props.deviceManager.off(DeviceManagerEvents.SELECTED_MICROPHONE_CHANGED, this.loadDevices);
	}

    private hideOrShowSlider = (showChat: boolean) => {
        if (showChat) {
            this.setState({ sliderCss: 'room-slider settings-slider show-slider'});
        } else {
            this.setState({ sliderCss: 'room-slider settings-slider' });
        }
    }

    private getDevices() {
        let devices = this.props.deviceManager.getCachedDevices();
        if (!devices) {
            throw new Error('Devices must be loaded before displaying Settings panel.');
        }
        return devices;
    }

    private loadDevices = () => {
        this.devices = this.getDevices();
    }

    private selectCamera(camera: DeviceInfo | undefined) {
        if (!camera) {
            return;
        }
        this.props.deviceManager.setCamera(camera);
        this.setState({ selectedCamera: camera.deviceId });
    }

    private selectMicrophone(microphone: DeviceInfo | undefined) {
        if (!microphone) {
            return;
        }
        this.props.deviceManager.setMicrophone(microphone);
        this.setState({ selectedMicrophone: microphone.deviceId });
    }

	render() {
        return (
            <article className={this.state.sliderCss}>
                <Row>
                    <Col>
                        <h2 className="fs-18 font-weight-bold">Camera and Microphone Settings</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 className="fs-16 font-weight-bold">Camera Device</h3>
                        <SelectInput
                            options={this.devices?.videoDevices}
                            onSelected={chosen => this.selectCamera(chosen) }
                            controlId="select-camera"
                            labelText="Camera"
                            icon="fa-video"
                            optionText={option => option?.label ?? "Please Select"}
                            optionValue={option => option.deviceId}
                            selected={this.state.selectedCamera}
							/>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <h3 className="fs-16 font-weight-bold">Audio Device</h3>
                        <SelectInput
                            options={this.devices?.audioDevices}
                            onSelected={chosen => this.selectMicrophone(chosen) }
                            controlId="select-microphone"
                            labelText="Microphone"
                            icon="fa-microphone"
                            optionText={option => option?.label ?? "Please Select"}
                            optionValue={option => option.deviceId}
                            selected={this.state.selectedMicrophone}
							/>
                    </Col>
                </Row>
            </article>
        );
	}
}