export enum LoginStatus {
	Failure = 0,
	Success = 1,
	PasswordResetRequired = 2,
	FirstTimeLogin = 3
}

export enum ResetPasswordStatus {
	Failure = 0,
	Success = 1,
	NotAuthorized = 401
}