import { AuthContext } from "components/ApiAuthorization/AuthContext";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { AuthorizeNavItem } from "../ApiAuthorization/AuthorizeNavItem";
import { LoginMenu } from "../ApiAuthorization/LoginMenu";
import { UserManagerContext } from "components/ApiAuthorization/UserManagerContext";
import { NavPresenceToggle } from "components/user/NavPresenceToggle";

import "./NavMenu.css";

interface NavMenuState {
  collapsed: boolean;
  userAvailable: boolean;
}

export class NavMenu extends Component<Readonly<{}>, Readonly<NavMenuState>> {
  static displayName = NavMenu.name;
  static contextType: React.Context<UserManagerContext> = AuthContext;

  context!: UserManagerContext;

  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      collapsed: true,
      userAvailable: true,
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  isInIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  render() {
    if (this.isInIframe()) {
      return null;
    }

    return (
      <header className="layout-header">
        <Navbar className="navbar-expand-sm navbar-toggleable-sm" light>
          <NavbarBrand tag={Link} to="/">
            <img src="/img/ImageTrend_TeleHealth_TM.png" width="200" height="35" alt="ImageTrend Telehealth Logo"></img>
          </NavbarBrand>
          <NavbarToggler onClick={() => this.toggleNavbar()} className="mr-2" />
          <Collapse className="d-sm-inline-flex" isOpen={!this.state.collapsed} navbar>
            <Nav className="navbar-nav flex-grow w-100">
              <NavItem>
                <NavLink tag={Link} to="/home">
                  Home
                </NavLink>
              </NavItem>
              <AuthorizeNavItem to="/call-center" display="Call Center"></AuthorizeNavItem>
              <AuthorizeNavItem to="/teams" display="Teams"></AuthorizeNavItem>
              <AuthorizeNavItem to="/users" display="Users"></AuthorizeNavItem>
            </Nav>
            <Nav className="navbar-nav justify-content-end w-100">
              <NavPresenceToggle />
              <LoginMenu />
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
