import { Team } from './Team';
import { OrgUser } from './organization/OrgUser';

export class Organization {
	id: string = '';
	name: string = '';
	addedTeamName: string = '';

	teams: Team[] = [];
	users: OrgUser[] = []

	constructor(id: string, name: string){
		this.id = id;
		this.name = name;
	}

	manages(teams: Team[]) {
		this.teams = teams;
	}

	accessibleBy(users: OrgUser[]) {
		this.users = users;
	}

	findUserWithId(id: string) {
		let foundUser = this.users.find(user => user.id === id);

		return foundUser;
	}

	addUser(user: OrgUser) {
		let updatedUsers = [...this.users];
		updatedUsers.push(user);
		
		let updatedOrg = new Organization(this.id, this.name);
		updatedOrg.teams = this.teams;
		updatedOrg.addedTeamName = this.addedTeamName;
		updatedOrg.users = updatedUsers;

		return updatedOrg;
	}

	updateUser(user: OrgUser): Organization {
		let updatedUsers = [];

		for(let currentUser of this.users) {
			if(currentUser.id === user.id) {
				updatedUsers.push(user);
			} else {
				updatedUsers.push(currentUser);
			}
		}
		
		let updatedOrg = new Organization(this.id, this.name);
		updatedOrg.teams = this.teams;
		updatedOrg.addedTeamName = this.addedTeamName;
		updatedOrg.users = updatedUsers;

		return updatedOrg;
	}

	addTeam(addedTeam: Team) {
		let updatedUsers = [];

		for(let user of this.users) {
			if(addedTeam.members.some(m => m.id === user.id)){
				user.onTeam(addedTeam);
			}

			updatedUsers.push(user);
		}

		let updatedTeams = [...this.teams, addedTeam]

		let updatedOrg = new Organization(this.id, this.name);
		updatedOrg.teams = [...this.teams, addedTeam].sort((a, b) => this.sortTeams(a, b));
		updatedOrg.users = updatedUsers;
		
		return updatedOrg;
	}

	updateTeam(updatedTeam: Team) {
		let updatedTeams = this.teams.filter(t => t.id !== updatedTeam.id);
		updatedTeams.push(updatedTeam);

		updatedTeams.sort((a, b) => this.sortTeams(a, b));

		let updatedUsers = [];

		for(let user of this.users) {
			let userTeams = [];
			for(let userTeam of user.teams){
				if(userTeam.id === updatedTeam.id){
					userTeams.push(updatedTeam);
				} else {
					userTeams.push(userTeam);
				}
			}

			user.teams = [...userTeams];
			updatedUsers.push(user);
			userTeams = [];
		}

		let updatedOrg = new Organization(this.id, this.name);
		updatedOrg.teams = updatedTeams;
		updatedOrg.users = updatedUsers;
		
		return updatedOrg;
	}

	private sortTeams(a: Team, b: Team) {
		return this.compareStrings(a.name, b.name);
	}

	private compareStrings(a: string, b: string) {
		if(!a && !b) {
			return 0;
		}

		if(a === b) {
			return 0;
		}

		if(a < b) {
			return -1;
		}

		return 1;	
	}
 
	static default = new Organization("", "");
}