
import React from 'react';
import { AudioTrackPublication, LocalTrackPublication, Participant, RemoteVideoTrackPublication, VideoTrackPublication } from 'twilio-video';

interface PublicationsProps {
	participant: Participant
}

// Twilio's typing for publications is not great. Their TrackPublication type is missing a lot of properties found on other publication types.
// And we can get a list of Audio or Video publications to start with, but published event gives us Local or Remote publications.
export type TrackPublication = AudioTrackPublication | VideoTrackPublication | LocalTrackPublication | RemoteVideoTrackPublication;

export interface IPublicationsContext {
	publications: TrackPublication[]
}

export const PublicationsContext = React.createContext<IPublicationsContext>({ publications: [] });

export class Publications extends React.Component<Readonly<PublicationsProps>, Readonly<IPublicationsContext>> {
	// What we want is a Participant component
	// which has a Publications, Tracks, AudioTrack, and VideoTrack component
	// Publications accepts ParticipantInfo in the props
	// Tracks takes in Publications
	// AudioTrack, and VideoTrack depend on Tracks
	// So it looks something like: 
	// <Participant>
	//		<Publications participantInfo />
	//		<Tracks publicationsRef />
	//		<AudioTrack publicationRef />
	//		<AudioTrack publicationRef />
	// </Participant>

	constructor(props: Readonly<PublicationsProps>) {
		super(props);
		this.state = {
			publications: []
		};
	}

	trackPublished = (publication: TrackPublication) => {
		this.setState((state) => {
			return {
				publications: [...state.publications, publication]
			};
		});
	}

	trackUnpublished = (publication: TrackPublication) => {
		this.setState((state) => {
			return {
				publications: state.publications.filter(p => p !== publication)
			};
		});
	}

	componentDidMount() {
		let publications: TrackPublication[] = Array.from(this.props.participant.audioTracks.values());
		publications = publications.concat(Array.from(this.props.participant.videoTracks.values()));
		this.setState({
			publications
		});
		this.props.participant.on('trackPublished', this.trackPublished);
		this.props.participant.on('trackUnpublished', this.trackUnpublished);
	}

	componentWillUnmount() {
		this.props.participant.off('trackPublished', this.trackPublished);
		this.props.participant.off('trackUnpublished', this.trackUnpublished);
	}

	render() {
		return (
			<PublicationsContext.Provider value={this.state}>
				{this.props.children}
			</PublicationsContext.Provider>
		)
	}
}