export abstract class DemographicSuffix {
	descriptor: string;
	private static _pleaseSelect: DemographicSuffix;
	
	constructor(descriptor: string) {
		if(!descriptor) {
			throw new Error("suffix descriptor was not provided");
		}

		this.descriptor = descriptor;
	}

	static empty(){
		if(!this._pleaseSelect) {
			this._pleaseSelect = new EmptySuffix();
			return this._pleaseSelect;
		}
		
		return this._pleaseSelect;
	}
}

class EmptySuffix extends DemographicSuffix {
	constructor(){
		super("Please Select");
	}
}

export class JuniorSuffix extends DemographicSuffix {
	constructor() {
		super("Jr.");
	}
}

export class SeniorSuffix extends DemographicSuffix {
	constructor() {
		super("Sr.");
	}
}