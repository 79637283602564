import { ApiUrl } from '../ApiConstants';
import authService from '../components/ApiAuthorization/AuthorizeService';
import { ApiResponseHandler } from '../common/ApiResponseHandler';
import { SecurePerson } from '../Person';
import { Claim } from '../security/Claim';

export class SecuredPersonService {
	private userResponseFactory = (userResponse: any) => {
		if(userResponse.userEmail && userResponse.claims) {
			let userClaims = userResponse.claims.map((c: {type: string, value: string}) => new Claim(c.type, c.value));

			return new SecurePerson(userResponse.id, userResponse.name, userResponse.userEmail, userResponse.username,  userClaims);
		}

		throw new Error("Properties are missing from response object needed to create a SecurePerson");
	}

	async lookupLoggedInUser(): Promise<SecurePerson> {
		let token = await authService.getAccessToken();

		let userLookupResponse = await fetch(`${ApiUrl.UserUrl}/lookupSignedInUser`, {
			headers: !token ? {} : {
				'content-type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});

		let handler = new ApiResponseHandler<SecurePerson, {type: string, value: string}>();

		let apiResponse = await handler.handle(userLookupResponse, 
						json => this.userResponseFactory(json),
						() => new SecurePerson('-1', 'Unknown Person', "unknown@no.email", "nouser", []));

		return apiResponse.item;
	}
}