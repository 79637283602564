export default class Queue<T> {
	private items: Array<T> = [];

	enqueue(item: T): void {
		if(!item) {
			throw new Error('Item provided was undefined. Pass an initialized item to enqueue.');
		}

		this.items.push(item);
	}

	dequeue(): T {
		if(this.items.length === 0) {
			throw new Error('No items have been enqueued to this queue. Enqueue an item to be able to call dequeue.');
		}

		var item = this.items[0];

		if(this.items.length === 1) {
			this.items = [];
		} else {
			this.items = this.items.slice(1, this.items.length);
		}
		
		return item;
	}

	peek(): T | null {
		if(this.items.length === 0) {
			return null;
		}

		var item = this.items[0];

		return item;
	}
}