import React, { Component } from 'react';
import { ChatManager, ChatManagerEvents } from './ChatManager';
import { RoomManager, RoomManagerEvents } from './RoomManager';
import { ChatMessage } from './ChatMessage';
import './ChatSlider.css';

interface ChatSliderProps {
    chatManager: ChatManager,
	roomManager: RoomManager,
    currentUserId: string | null | undefined,
    roomId: string | null
}

interface ChatSliderState {
    messages: ChatMessage[],
    sliderCss: string
}

export class ChatSlider extends React.Component<Readonly<ChatSliderProps>, Readonly<ChatSliderState>> {
    chatComposerRef: HTMLTextAreaElement | null = null;
	chatMessagesRef: HTMLElement | null = null;

	constructor(props: Readonly<ChatSliderProps>) {
		super(props);
        this.state = {
            messages: [],
            sliderCss: 'room-slider'
        };
	}

	componentDidMount() {
		this.props.chatManager.on(ChatManagerEvents.MESSAGE_RECEIVED, this.messageReceived);
        this.props.roomManager.on(RoomManagerEvents.SHOW_CHAT_STATUS, this.hideOrShowSlider);
	}

    componentWillUnmount() {
		this.props.chatManager.off(ChatManagerEvents.MESSAGE_RECEIVED, this.messageReceived);
        this.props.chatManager.off(RoomManagerEvents.SHOW_CHAT_STATUS, this.hideOrShowSlider);
	}

    private hideOrShowSlider = (showChat: boolean) => {
        if (showChat) {
            this.setState({ sliderCss: 'room-slider show-slider'});
        } else {
            this.setState({ sliderCss: 'room-slider' });
        }
    }

    private messageReceived = (message: ChatMessage) => {
        const messageClone = this.state.messages.concat([]);
		messageClone.push(message);
		this.setState({ messages: messageClone });
		if (this.chatMessagesRef) {
			this.chatMessagesRef.scrollTo(0, this.chatMessagesRef.scrollHeight);
		}
    }

    private renderChatMessages() {
		if (this.state.messages.length === 0) {
			return null;
		}
		let previousMessage = null;
		let currentMessage = null;
		let lineNumber = 0;
		let lines = [];
		for (let i = 0; i < this.state.messages.length; i++) {
			previousMessage = currentMessage;
			currentMessage = this.state.messages[i];
			let alignmentClass = currentMessage.userId === this.props.currentUserId ? 'room-chat-message-right' : 'room-chat-message-left';
			if (previousMessage === null || previousMessage.userId !== currentMessage.userId) {
				// add the user's display name if the previous chat message was not
				// sent by that user.
				let userClassname = alignmentClass + " room-chat-username";
				lines.push((<div className={userClassname} key={lineNumber}>{currentMessage.userDisplayName}</div>));
				lineNumber++;
			}
			let messageClassname = alignmentClass + " room-chat-message";
			lines.push((<div className={messageClassname} key={lineNumber}><div className="room-chat-message-inner">{currentMessage.message}</div></div>));
			lineNumber++;
		}
		return lines;
	}

    private handleMessageKeyDown(event: React.KeyboardEvent) {
		if (event.key !== 'Enter') {
			return;
		}
		if (!this.chatComposerRef) {
			return;
		}
		if (!this.props.chatManager.getIsConnected()) {
			return;
		}
        if (!this.props.roomId) {
            return;
        }
		event.preventDefault();
		let message = this.chatComposerRef.value;
		if (!message) {
			return;
		}
        this.props.chatManager.broadcastMessage(message, this.props.roomId);
		this.chatComposerRef.value = '';
	}

	render() {
        let messageContents = this.renderChatMessages();
        return (
            <div className={this.state.sliderCss}>
                <div className="room-chat" >
                    <div className="room-chat-messages" ref={chatMessagesRef => { this.chatMessagesRef = chatMessagesRef }}>
                        {messageContents}
                    </div>
                    <div className="room-chat-message-composer">
                        <textarea placeholder="Type a message" ref={chatComposerRef => { this.chatComposerRef = chatComposerRef }} onKeyDown={this.handleMessageKeyDown.bind(this)} ></textarea>
                    </div>
                </div>
            </div>
        );
	}
}