import { Organization } from '../Organization';
import { ChangeUserRequest } from '../user/ChangeUserRequest';
import { UpdateTeamRequest } from './UpdateTeamRequest';

export class UpdateOrganizationRequest {
	organization: Organization;
	addedTeam?: UpdateTeamRequest;
	editedTeam?: UpdateTeamRequest;
	addedUser?: ChangeUserRequest;
	editedUser?: ChangeUserRequest;

	constructor(org: Organization) {
		if(!org) {
			throw new Error('Organization provided was undefined');
		}

		this.organization = org;
	}

	addNewTeam(addedTeam: UpdateTeamRequest) {
		this.addedTeam = addedTeam;
	}

	editTeam(updatedTeam: UpdateTeamRequest) {
		this.editedTeam = updatedTeam;
	}

	addNewUser(user: ChangeUserRequest) {
		if(!!!user) {
			throw new Error("user was not provided for add user request.");
		}

		this.addedUser = user;
	}

	editUser(user: ChangeUserRequest) {
		if(!!!user) {
			throw new Error("user was not provided for edit user request.");
		}

		this.editedUser = user;
	}
}