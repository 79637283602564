import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { CallTree } from 'telehealth-client';
import authService from './ApiAuthorization/AuthorizeService'
import React from 'react';
import { Col, Row } from 'reactstrap';

interface CallTreeRouterState {
	roomNameRedirect: string | null
}

export class CallTreeRouter extends Component<Readonly<{}>, CallTreeRouterState> {
	constructor(props: Readonly<{}>) {
		super(props);
		this.state = {
			roomNameRedirect: null
		};
	}

	getCallDetailsAsync() {
		return Promise.resolve({
			unitNumber: undefined,
			agencyId: undefined,
			agencyName: undefined
		});
	}

	onCallCreated(roomId: string) {
		this.setState({ roomNameRedirect: roomId });
	}

	render() {
		if (this.state.roomNameRedirect) {
			return (
				<Redirect to={`/room?roomId=${this.state.roomNameRedirect}`} />
			);
		}
		return (<>
			<Row>
				<Col className="upper-content">
					Call Center
					</Col>
			</Row>
			<div className="lower-content card-body">
			<CallTree authService={authService} onCallCreated={this.onCallCreated.bind(this)} getCallDetailsAsync={this.getCallDetailsAsync} />
			</div>
		</>);
	}
}