import React from 'react';

export const UnexpectedError =() => {
	return (
		<span>
			<strong>Uh-oh, an unexpected error occurred within the system.</strong>{' '} 
			The issue has been logged and will be reviewed by our team.{' '}If you would 
			like, you can <a href="https://support.imagetrend.com">create a support ticket for this issue</a>.
		</span> 
	)
}