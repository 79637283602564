import { IPermission } from '../IPermission';
import { Claims } from '../Claims';

export default class TelehealthPortalPermission implements IPermission {
    private _onGranted: ((onGrantedCallback: () => void) => void) = (onGrantedCallback) => {};
    private _onUngranted: ((onDeniedCallback: () => void) => void) = (onDeniedCallback) => onDeniedCallback();

    constructor(userClaims: Claims) {
        const accountClaim = userClaims.findClaimsByType("account");

        if(accountClaim) {
            switch(accountClaim.value) {
                case 'telehealth':
                    this._onGranted = (onGranted: () => void) => onGranted();
                    this._onUngranted = (onDenied: () => void) => {};
                break;    
            }
        
        }
    }

    granted(onGranted: () => void) {
        if(!onGranted) {
            return;
        }

        this._onGranted(() => onGranted());
    }

    async grantedAsync(onGrantedAsync: () => Promise<void>) {
        if(!onGrantedAsync) {
            return;
        }

        let granted = false;
        this._onGranted(() => granted = true);

        if(granted) {
            await onGrantedAsync();
        }
    }

    denied(onDenial: () => void) {
        if(!onDenial) {
            return;
        }

        this._onUngranted(() => onDenial());  
    }

    async deniedAsync(onDeniedAsync: () => Promise<void>) {
        if(!onDeniedAsync) {
            return;
        }

        let denied = false;
        this.denied(() => denied = true);

        if(denied) {
            await onDeniedAsync();
        }
    }
}