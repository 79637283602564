import React, { useEffect, useState, Fragment } from 'react';
import { Input, Label } from 'reactstrap';

interface PrefixSelectInputProps<T> {
	options: Iterable<T>
	controlId?: string;
	labelText?: string;
	selected?: string;
	onSelected: (prefixChosen?: T) => void;
	optionText: (option: T) => string;
}

export const PrefixSelectInput = <T,>({ options, controlId, labelText, selected, onSelected, optionText }: PrefixSelectInputProps<T>) => {
	const [selectOptions, setOptions] = useState([] as JSX.Element[]);
	const [selectedPrefix, setSelected] = useState<T>();
 
	const selectId = "prefix-select";

	useEffect(() => {
		const buildOptions = () => {
			let builtOptions = [];

			for (let option of options) {
				builtOptions.push(option);
			}

			setOptions(builtOptions.map(builtOpt => <option key={optionText(builtOpt)} value={optionText(builtOpt)}>{optionText(builtOpt)}</option>));
		}

		buildOptions();
	}, []);

	useEffect(() => {
		onSelected(selectedPrefix);
	},[selectedPrefix]);

	const onPrefixChange = (prefixSelected: string) => {
		if(!prefixSelected){
			setSelected(undefined);
		} else {
			for(let option of options) {
				if(optionText(option) === prefixSelected) {
					setSelected(option);
					break;
				}
			}
		}
	}

	return (
		<Fragment>
			<Label for={controlId ?? selectId}>{ labelText ?? "Prefix"}</Label>
			<Input type="select" id={controlId ?? selectId} value={selected} onChange={event => onPrefixChange(event.target.value)} role="option">
				{selectOptions}
			</Input>
		</Fragment>
	)
}