export default class TreeNode<T> {
	private data: T;
	private parent: TreeNode<T> | null = null;
	private children: Array<TreeNode<T>> = [];

	constructor(data: T) {
		if(!data) {
			throw new Error('Data was not provided for this nodes data.')
		}

		this.data = data;
	}

	addChild(childNode: TreeNode<T>): void {
		if(!childNode) {
			throw new Error('Provided childNode was undefined. Provide an initialized childNode to continue.')
		}
		
		childNode.parent = this;
		this.children.push(childNode);
	}

	removeChild(childNode: TreeNode<T>): void {
		let childRemovedCollection: Array<TreeNode<T>> = [];

		for(let child of this.children) {
			if(childNode.data === child.data){
				continue;
			}

			childRemovedCollection.push(child);
		}

		this.children = childRemovedCollection;
	}

	visit(visitCallback: (nodeData: T) => void): void {
		if(!visitCallback) {
			throw new Error("No callback was provided for visitCallBack. Provide a callback to continue.");
		}

		visitCallback(this.data);
	}

	visitParent(visitCallback: (parentNode: TreeNode<T>) => void): void {
		if(!visitCallback) {
			throw new Error("No callback was provided for visitCallBack. Provide a callback to continue.");
		}

		if(!this.parent) {
			return;
		}
		
		visitCallback(this.parent);
	}

	visitChildren(visitCallback: (childNode: TreeNode<T>) => void): void {
		if(!visitCallback) {
			throw new Error("No callback was provided for visitCallBack. Provide a callback to continue.");
		}

		for(let child of this.children) {
			visitCallback(child);
		}
	}
}