import { Person } from './Person';

export enum TeamStatus {
	Active ="Active",
	Inactive = "Inactive"
}

export class Team {
	id: number = 0;
	name: string = "Default Team";
	members: Person[] = [];
	availableUsers: Person[] = [];
	created = new Date().toDateString();
	status = TeamStatus.Active;

	static empty: Team = new Team(0, "Default Team");
	static unknown = new Team(0, "Unknown");

	constructor(id: number, name: string){
		this.id = id;
		this.name = name
	}

	get memberCount() {
		return this.members.length;
	}

	addToTeam(thisMember: Person) {
		this.members.push(thisMember);
	}

	removeFromTeam(thisMember: Person) {
		this.members = this.members.filter(teamMember => thisMember.id !== teamMember.id);
	}

	withExisistingMembers(members: Person[]){
		this.members = members;
	}

	withAvailableUser(users: Person[]){
		this.availableUsers = users;
	}

	activate() {
		this.status = TeamStatus.Active;
	}

	deactivate() {
		this.status = TeamStatus.Inactive;
	}

	equals(other: Team): boolean {
		if(other === null) {
			return false;
		}

		if(this.id === other.id && this.name === other.name) {
			return true;
		}

		return false;
	}
}