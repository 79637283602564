import React, { ComponentProps, useEffect, useState } from 'react';
import { Button, Form, FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText, } from 'reactstrap';
import { FontAwesome } from './Common/FontAwesome';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AnonymousApiRequest from '../common/AnonymousApiRequest';
import { ForgotPasswordRequest } from '../user/ForgotPasswordRequest';
import { ApiUrl } from '../ApiConstants';
import { ErrorMessages } from './Common/ErrorMessages';
import { UnexpectedError } from './Common/UnexpectedError';
import { ClickableText } from './Common/ClickableText';

interface ForgotPasswordProps extends ComponentProps<any> {
	showLogin: () => void;
}

export const ForgotPassword = ({ showLogin } : ForgotPasswordProps)  => {
	const [emailValid, setEmailValid] = useState<boolean>();
	const [resetRequestSent, setResetRequestSent] = useState(false);
	const [showErrors, setShowErrors] = useState(false);;

	const resetForm = useFormik({
		initialValues: {
			email: ''
		},
		validationSchema: Yup.object({
			email: Yup.string().required('An email address is required').email('Invalid email address provided')
		}),
		onSubmit: async values => {
			setShowErrors(false);

			const forgotPasswordApi = new AnonymousApiRequest();
			const forgotPasswordRequest = new ForgotPasswordRequest(values.email);

			const forgotPasswordResponse = await forgotPasswordApi.post(`${ApiUrl.UserUrl}/forgot-password`, forgotPasswordRequest);

			if(forgotPasswordResponse.ok) {
				setResetRequestSent(true);
			} else {
				setShowErrors(true);
			}
		}
	});

	useEffect(() => {
		if(resetForm.errors.email) {
			setEmailValid(false);
		} else {
			setEmailValid(true);
		}
	}, [resetForm.errors.email])

	const returnToLogin = () => {
		showLogin();
	}

	return (
			<Form className="px-4 py-4 rounded-form" onSubmit={resetForm.handleSubmit} title="Forgot Password">
				<img className="d-block mx-auto mt-5 mb-5" src="/img/ImageTrend_TeleHealth_TM.png" width="299" height="63"></img>
				{ showErrors && 
					<ErrorMessages>
						<UnexpectedError />
					</ErrorMessages> }
					<strong>Forgot Password</strong>
				{ !resetRequestSent &&
					<>
						<p className="mt-2">A link to reset your password will be sent to your email address.</p>
						<InputGroup className="mb-4 mt-3">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									<FontAwesome icon="far fa-envelope" />
								</InputGroupText>
							</InputGroupAddon>
							<Input type="email" 
								id="email" name="email" 
								placeholder="Email Address" 
								value={resetForm.values.email} 
								onBlur={resetForm.handleBlur} 
								onChange={resetForm.handleChange} 
								invalid={resetForm.touched.email && !emailValid} 
								valid={resetForm.touched.email && emailValid} 
								aria-describedby="email-feedback"
								title="Email Address Textbox" />
							<FormFeedback id="email-feedback">{resetForm.touched.email && resetForm.errors.email ? resetForm.errors.email : null}</FormFeedback>
						</InputGroup>								
						<Button type="submit" className="d-block mx-auto" color="green" title="Submit Forgot Password Request">Send Me the Link</Button>
						<ClickableText classes="text-center mt-4" onClicked={() => returnToLogin()}>Back to Login</ClickableText>
					</> }
				{ resetRequestSent && 
					<p className="mt-2">An email has been sent. Please click the link in it to reset your password.</p> }
			</Form>
	)
}