import React, { useContext, useEffect, useRef } from "react";
import { statusService } from "components/SignalR/StatusService";
import { AuthContext } from "components/ApiAuthorization/AuthContext";
import { HubStatusMethod } from "telehealth-api";

export const Presence = () => {
  const authContext = useContext(AuthContext);
  const authSubscriptions = useRef([] as Array<number>);
  const userPresenceSubscriptions = useRef([] as Array<number>);
  const reconnectedHanderInitialized = useRef(false);

  // a handler which makes the user available in the event their
  // SignalR connection drops and reconnects
  const reconnectedHandler = (error?: string) => {
    let logMsg = "Reconnected to status hub";
    if (error) {
      logMsg = `${logMsg} after failing with: ${error}`;
    }

    console.debug(logMsg);

    //if (!reconnectedHanderInitialized.current) {
    statusService.enableCallNotifications();
    reconnectedHanderInitialized.current = true;
    //}

    return Promise.resolve();
  };

  // setup a reconnection handler for SignalR to ensure call notifications are re-enabled
  useEffect(() => {
    statusService.addReconnectedHandler(reconnectedHandler);
  }, []);

  useEffect(() => {
    let authedSub = authContext.onAuthenticationChange(async () => {
      if (await authContext.isAuthenticated()) {
        await statusService.makeUserAvailable();
      }
    });

    authSubscriptions.current.push(authedSub);

    //listen for user availablity change so we know when to display/send call notifications
    let presenceChangeSub = statusService.subscribe(async (userId: string, available: boolean) => {
      let myUserId = await authContext.provider.getUserId();

      if (!myUserId) {
        return;
      }

      if (myUserId !== userId) {
        return;
      }

      if (available) {
        statusService.enableCallNotifications();
      } else {
        statusService.disableCallNotifications();
      }
    }, HubStatusMethod.UpdateStatus);

    userPresenceSubscriptions.current.push(presenceChangeSub);

    return () => {
      authSubscriptions.current.forEach((authSub) => authContext.cancelAuthenticationChange(authSub));
      userPresenceSubscriptions.current.forEach((presenceSub) => statusService.unsubscribe(presenceSub));

      authSubscriptions.current = [];
      userPresenceSubscriptions.current = [];
    };
  }, [authContext]);

  return <></>;
};

export default Presence;
