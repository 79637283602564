import { ApiUrl } from "ApiConstants";
import CallLog from "callCenter/CallLog";
import { ApiRequest } from "common/ApiRequest";
import { ApiResponseHandler } from "common/ApiResponseHandler";
import { Messages, MessageSubscription } from "common/Messaging";
import DeclinedCallLog from "common/messaging/DeclinedCallLog";
import {
  EnabledUserCallNotificationSetting,
  UserCallNotificationSetting,
} from "common/settings/UserCallNotificationSetting";
import { DateTime } from "luxon";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  CallAnsweredNotification,
  CallEndedNotification,
  CallNotification,
  IAuthService,
} from "telehealth-abstractions";
import { CallService, HubStatusMethod } from "telehealth-api";
import { CallToast } from "./CallToast";
import { statusService } from "./SignalR/StatusService";
import { SettingRequestPayload } from "../common/SettingRequestPayload";
import SettingStrings from "common/settings/SettingStrings";
import MessagingContext, { IMessagePublisherContext } from "common/messaging/MessagingContext";
import { DeclineCallRequest } from "telehealth-abstractions";

interface CallToastManagerState {
  calls: Array<CallNotification>;
  callIndex: number;
  callAlertEnabled: boolean;
  showToasts: boolean;
}

interface CallToastManagerProps extends RouteComponentProps {
  authService: IAuthService;
  callService: CallService;
}

class CallToastManager extends Component<Readonly<CallToastManagerProps>, Readonly<CallToastManagerState>> {
  _subscriptions: Array<number>;
  callService!: CallService;
  _audioAlert: HTMLAudioElement;
  _messageSubs: MessageSubscription[];

  static contextType: React.Context<IMessagePublisherContext> = MessagingContext;
  context!: IMessagePublisherContext;

  constructor(props: CallToastManagerProps) {
    super(props);
    this._subscriptions = [];
    this._messageSubs = [];

    this.state = {
      calls: [],
      callIndex: 0,
      callAlertEnabled: false,
      showToasts: true,
    };

    //preload the alert audio file so it's ready to be played when needed
    this._audioAlert = new Audio("/call-alert.mp3");
    this._audioAlert.loop = true;

    this.callService = props.callService;
  }

  async componentDidMount() {
    this._subscriptions.push(statusService.subscribe(this.incomingCall.bind(this), HubStatusMethod.IncomingCall));
    this._subscriptions.push(statusService.subscribe(this.callAnswered.bind(this), HubStatusMethod.CallAnswered));
    this._subscriptions.push(
      statusService.subscribe(
        (endedCall: CallEndedNotification) => this.removeCall(endedCall.CallId),
        HubStatusMethod.CallEnded
      )
    );
    this._subscriptions.push(
      statusService.subscribe(
        async (userId: string, available: boolean) => await this.handleUserStatusChange(userId, available),
        HubStatusMethod.UpdateStatus
      )
    );

    //listen for audio alert changes a user may make
    let audioAlertSub = this.context.subscribeTo(Messages.callAudioAlertChange(), (alertEnabled: boolean) => {
      if (alertEnabled !== this.state.callAlertEnabled) {
        this.setState({
          callAlertEnabled: alertEnabled,
        });
      }
    });

    if (audioAlertSub) {
      this._messageSubs.push(audioAlertSub);
    }

    await this.setupIncomingCallAlert();
  }

  componentDidUpdate(prevProps: RouteComponentProps, prevState: CallToastManagerState) {
    if (prevState.calls.length !== this.state.calls.length) {
      //unsubscribe from all call declination messages
      const removeSubscriptions: number[] = [];
      this._messageSubs.forEach((messageSub, index) => {
        if (messageSub.subscribedTo.equals(Messages.userDeclinedCall())) {
          removeSubscriptions.push(index);
          this.context.unsubscribeFrom(messageSub);
        }
      });

      for (let callSubRemoval of removeSubscriptions) {
        this._messageSubs.splice(callSubRemoval, 1);
      }

      //setup the call declined subscription again so if a user declines a call, we remove it from the toast list
      let sub = this.context.subscribeTo(Messages.userDeclinedCall(), (declinedMessage: DeclinedCallLog) => {
        const callIndex = this.state.calls.findIndex((c) => c.CallId === declinedMessage.declinedCallLog.callId);

        if (callIndex > -1) {
          this.setState((s) => {
            let callsCopy = s.calls;

            callsCopy.splice(callIndex, 1);
            return {
              calls: callsCopy,
            };
          });
        }
      });

      if (sub) {
        this._messageSubs.push(sub);
      }
    }
  }

  componentWillUnmount() {
    this._subscriptions.forEach((s) => statusService.unsubscribe(s));
    this._messageSubs.forEach((ms) => this.context.unsubscribeFrom(ms));
    this._subscriptions = [];
    this._messageSubs = [];
  }

  incomingCall(call: CallNotification) {
    if (this.state.callAlertEnabled && this._audioAlert.paused) {
      this._audioAlert.play();
    }

    this.setState((state, props) => {
      call.CallIndex = state.callIndex + 1;
      let callsClone = state.calls.concat([]);
      callsClone.push(call);
      return {
        calls: callsClone,
        callIndex: call.CallIndex,
      };
    });
  }

  removeCall(callId: number) {
    this.stopAudioNotification();

    this.setState((state, props) => {
      let callsClone = state.calls.concat([]);
      let index = callsClone.findIndex((c) => c.CallId === callId);

      if (index > -1) {
        callsClone.splice(index, 1);
      }

      return {
        calls: callsClone,
      };
    });
  }

  // Someone else answered the call
  callAnswered(call: CallAnsweredNotification) {
    this.removeCall(call.CallId);
  }

  handleAnswerCall = async (call: CallNotification) => {
    this.stopAudioNotification();

    let response = await this.callService.answerCall({
      callId: call.CallId,
    });

    if (response.answered) {
      this.props.history.push(`/room?roomId=${call.RoomId}`);
      statusService.disableCallNotifications();
      this.setState({
        calls: [],
      });
    } else {
      this.removeCall(call.CallId);
      console.log(response.reason);
    }
  };

  handleIgnoreCall = (call: CallNotification) => {
    this.removeCall(call.CallId);
  };

  handleRejectCall = async (call: CallNotification) => {
    let awaitableCallDecline = this.callService.declineCall({ callId: call.CallId } as DeclineCallRequest);

    this.publishCallDeclinedMessage(call);
    this.removeCall(call.CallId);

    await awaitableCallDecline;
  };

  private async setupIncomingCallAlert() {
    let userAlertPreferenceRequest = new ApiRequest();
    let userAlertPreferenceResponse = await userAlertPreferenceRequest.post(
      `${ApiUrl.SettingUrl}/user/search`,
      new EnabledUserCallNotificationSetting()
    );

    let userAlertResponseHandler = new ApiResponseHandler<UserCallNotificationSetting, SettingRequestPayload[]>();
    let userAlertSetting = await userAlertResponseHandler.handle(
      userAlertPreferenceResponse,
      (responseJson) => {
        var audioAlert = new UserCallNotificationSetting(false);
        for (let setting of responseJson) {
          if (setting.name !== SettingStrings.userIncomingCallAudioNotification) {
            continue;
          }

          if (setting.value === "yes") {
            audioAlert = new UserCallNotificationSetting(true);
          }
        }

        return audioAlert;
      },
      () => new UserCallNotificationSetting(false)
    );

    this.setState({
      callAlertEnabled: Boolean(userAlertSetting.item.value).valueOf(),
    });
  }

  private stopAudioNotification() {
    if (!this._audioAlert.paused) {
      this._audioAlert.pause();
    }
  }

  private async handleUserStatusChange(userId: string, available: boolean) {
    const id = await this.props.authService.getUserId();

    if (userId !== id) {
      return;
    }

    this.setState({
      showToasts: available,
    });
  }

  private publishCallDeclinedMessage(call: CallNotification) {
    this.context.publish(
      Messages.userDeclinedCall(),
      new DeclinedCallLog(
        new CallLog(
          call.CallId,
          call.From ?? "Telehealth User",
          call.AgencyName ?? "Unknown Agency",
          "",
          DateTime.now()
        )
      )
    );
  }

  render() {
    let toasts = this.state.calls.map((c) => (
      <CallToast
        key={c.CallIndex}
        call={c}
        handleAnswerCall={this.handleAnswerCall}
        handleIgnoreCall={(call: CallNotification) => this.handleIgnoreCall(call)}
        handleRejectCall={this.handleRejectCall}
      />
    ));

    return (
      <>
        {this.state.showToasts && (
          <div
            aria-live="polite"
            aria-atomic="true"
            className="justify-content-center align-items-center toast-container"
          >
            {toasts}
          </div>
        )}
        {!this.state.showToasts && null}
      </>
    );
  }
}

export const RoutedCallToastManager = withRouter(CallToastManager);
