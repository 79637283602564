import React, { PropsWithChildren } from "react";
import { Col, Row } from "reactstrap";

export interface UpperContentComponentProps
	extends PropsWithChildren<{}> {
	page: string;
	}

export const UpperContent = ({
	children,
	page
}: UpperContentComponentProps) => {
	return (
		<Row className="upper-content">
			<Col>
				<h1>{page}</h1>
			</Col>
			{children && <Col>{children}</Col>}
		</Row>
	);
};
