import { IPermission } from '../IPermission';

/*
* An instance of this class represents a permission which 
* also has the ability to signal that a SecuredComponent
* should render it's decision about a permission. This is 
* useful in situations where the permission being provided
* is asynchronously retrieved.
*
* For example, if we default a components permission to 
* "Not Granted" (secure by default), but want really want 
* to base the display off a checking a user's role (an async op),
* the "Permission Denied" message would be displayed as
* we wait for the user's role to be retrieved over the network.
*/
export class RenderPermission implements IPermission {
	private _inner: IPermission;
	
	//the action which will invoked when renderOutput is called. It defaults to not invoking the provided render action (it does nothing)
	private _renderThisUsing: (renderAction: () => void) => void = render => {};

	constructor(inner: IPermission) {
		if(!!!inner) {
			throw new Error("inner permission was not provided");
		}

		this._inner = inner;
	}

	granted(onGranted: () => void) {
		if(!!!onGranted) {
			throw new Error("onGranted callback was not provided");
		}

		this._inner.granted(onGranted);
	}

	async grantedAsync(onGrantedAsync: () => Promise<void>) {
		if(!!!onGrantedAsync) {
			throw new Error("onGrantedAsync callback was not provided");
		}

		await this._inner.grantedAsync(async () => await onGrantedAsync());
	}

	denied(onDenied: () => void) {
		if(!!!onDenied) {
			throw new Error("onDenied callback was not provided");
		}

		this._inner.denied(onDenied);
	}

	async deniedAsync(onDeniedAsync: () => Promise<void>) {
		if(!onDeniedAsync) {
			throw new Error("onDeniedAsync callback was not provided");
		}

		await this._inner.deniedAsync(async () => await onDeniedAsync());
	}

	disableRendering() {
		//disable the invocation of renderAction
		let renderDisabled = new RenderPermission(this._inner);
		renderDisabled._renderThisUsing = renderAction => {};


		return renderDisabled;
	}

	enableRendering() {
		//update the rendering action to invoke whatever the passed render action is. This action is passed via the shouldRenderOutput method
		let renderEnabled = new RenderPermission(this._inner);
		renderEnabled._renderThisUsing = renderAction => renderAction();

		return renderEnabled;
	}

	shouldRenderOutput(renderer: () => void) {
		if(!!!renderer) {
			throw new Error("renderer callback was not provided");
		}

		this._renderThisUsing(renderer);
	}
}