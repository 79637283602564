import { EventEmitter } from 'events';

export enum RoomManagerEvents {
    SHOW_CHAT_STATUS = "showChatStatus",
    SHOW_SETTINGS_STATUS = "showSettingsStatus"
}

export class RoomManager extends EventEmitter {
	private showChatStatus: boolean = false;
    private showSettingsStatus: boolean = false;

	constructor() {
		super();
	}

	getShowChat() {
		return this.showChatStatus;
	}

    getShowSettings() {
        return this.showSettingsStatus;
    }

	hideChat() {
        if (!this.showChatStatus) {
            // chat is already hidden
            return;
        }
        this.showChatStatus = false;
        this.emit(RoomManagerEvents.SHOW_CHAT_STATUS, false);
	}

    hideSettings() {
        if (!this.showSettingsStatus) {
            return;
        }
        this.showSettingsStatus = false;
        this.emit(RoomManagerEvents.SHOW_SETTINGS_STATUS, false);
    }

    showChat() {
        if (this.showChatStatus) {
            // chat is already shown
            return;
        }
        if (this.showSettingsStatus) {
            this.hideSettings();
        }
        this.showChatStatus = true;
        this.emit(RoomManagerEvents.SHOW_CHAT_STATUS, true);
    }

    showSettings() {
        if (this.showSettingsStatus) {
            return;
        }
        if (this.showChatStatus) {
            this.hideChat();
        }
        this.showSettingsStatus = true;
        this.emit(RoomManagerEvents.SHOW_SETTINGS_STATUS, true);
    }
}