import { SettingRequestPayload } from 'common/SettingRequestPayload'
import SettingStrings from 'common/settings/SettingStrings';

export class UserCallNotificationSetting extends SettingRequestPayload {
	constructor(value: boolean | string) {
		super('call', 'user', SettingStrings.modifyAction, value, SettingStrings.userIncomingCallAudioNotification, 'notification');
	}
}

export class EnabledUserCallNotificationSetting extends SettingRequestPayload {
	constructor() {
		super('call', 'user', SettingStrings.modifyAction, 'yes', SettingStrings.userIncomingCallAudioNotification, 'notification');
	}
}

export class UserCallSmsNotificationSetting extends SettingRequestPayload {
	constructor(value: string) {
		super('call', 'user', SettingStrings.modifyAction, value, SettingStrings.userIncomingCallSmsNotification, 'notification');
	}
}

export class DeleteUserCallSmsNotificationSetting extends SettingRequestPayload {
	constructor() {
		super('call', 'user', SettingStrings.deleteAction, '', SettingStrings.userIncomingCallSmsNotification, 'notification');
	}
}