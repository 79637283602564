export abstract class RequestBuilder<TBuilt> {
	abstract build(): TBuilt;
	abstract canBuild(): boolean;
	
	protected throwBuildError(item: string) {
		throw new Error(this.createMissingItemMessage(item));
	}

	private createMissingItemMessage(item: string): string {
		return `${item} has not been built`;
	}
}