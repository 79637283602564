export default class PhoneNumber {
    private _formatter: PhoneNumberPart;

    constructor(formatters: Iterable<PhoneNumberPart>) {
        this._formatter = new PhoneNumberParts(formatters);
    }

    format(unformatted?: string) {
        if(!unformatted) {
            return undefined;
        }

        const formatted = this._formatter.format(unformatted);
        
        return formatted;
    }

    static createWithDefaultFormatters() {
        return new PhoneNumber([
            new AreaCodeParenthesis(), 
            new PhoneNumberHyphenizer()
        ])
    }
}

export interface PhoneNumberPart {
    format: (phoneNumber: string) => string | undefined;
}

class AreaCodeParenthesis implements PhoneNumberPart {
    format(phoneNumber?: string) {
        if(!phoneNumber) {
            return phoneNumber;
        }

        let numberDigitsMatch = phoneNumber.match(/\d+/g);

        //ensure we have numbers
        if((numberDigitsMatch?.length ?? 0) === 0) {
            return phoneNumber;
        }

        const parensRegex = /^(?<areaCode>\(\d{3}\){0,1})\s{0,1}(?<remaining>.*)/;
        if(parensRegex.test(phoneNumber)) {        
            //we're already formatted, pass on the number, but we may or may not have a space, so add it
            const parensMatch = phoneNumber.match(parensRegex)!;
            const areaCode = parensMatch.groups!.areaCode;
            const remaining = parensMatch.groups!.remaining;

            return !remaining ? areaCode : `${areaCode} ${parensMatch.groups!.remaining}`;
        }

        let trimmedNumber: string | undefined = undefined;
        for(let phoneNumberDigitGroup of numberDigitsMatch!) {
            if(!trimmedNumber) {
                trimmedNumber = phoneNumberDigitGroup;
            } else {
                trimmedNumber = trimmedNumber.concat(phoneNumberDigitGroup)
            }
        }

         //ensure we have at least 3 numbers
         if(trimmedNumber!.length < 3) {
            return phoneNumber;
        }


        const areaCode = trimmedNumber!.substring(0, 3);
        
        return `(${areaCode}) ${trimmedNumber!.substring(3)}`
    }
}

class PhoneNumberHyphenizer implements PhoneNumberPart {
    format(phoneNumber?: string) {
        if(!phoneNumber) {
            return phoneNumber;
        }

        let numberDigitsMatch = phoneNumber.match(/\d+/g);

        //ensure we have some numbers
        if(!numberDigitsMatch?.length) {
            return phoneNumber;
        }
    
        //match something that remotely resembles a phone number
        let fullNumber = phoneNumber.match(/^\({0,1}(?<areacode>[0-9]{3})\){0,1}(?:-*|\s*)(?<prefix>[0-9]{1,3})(?<numberHyphen>-*|\s*)(?<number>[0-9]{0,4})$/); 

        if(fullNumber?.groups) {
            const areaCode = fullNumber.groups.areacode;
            const prefix = fullNumber.groups.prefix;
            const numberHyphenated = fullNumber.groups.numberHyphen;
            const number = fullNumber.groups.number;

            if(prefix && number) {
                return `(${areaCode}) ${prefix}-${number}`;
            }

            if(prefix) {
                //if the user entered a hyphen, we should let it through
                return numberHyphenated ? `(${areaCode}) ${prefix}-` : `(${areaCode}) ${prefix}`;
            }

            return `(${areaCode})`;
        }

        return phoneNumber;
    }
}


class PhoneNumberParts implements PhoneNumberParts {
    private _formatters: Iterable<PhoneNumberPart> = [];

    constructor(formatters: Iterable<PhoneNumberPart>){
        this._formatters = formatters;
    }

    format(phoneNumber?: string) {
        if(!phoneNumber) {
            return phoneNumber;
        }

        let formatted = phoneNumber;
        for(const formatter of this._formatters) {
            formatted = formatter.format(formatted) ?? formatted;
        }

        return formatted;
    }
}