import Message from 'common/Message';
import { MessageId } from 'common/Messaging';
import CallLog from 'callCenter/CallLog';

/*
* A message that is published when a user declines answering a call
*/
export default class DeclinedCallLog extends Message {
    readonly declinedCallLog: CallLog;

    constructor(log: CallLog) {
        super(MessageId.userCallDecline);

        this.declinedCallLog = log;
    }

    displayCaller() {
        return this.declinedCallLog.displayCaller();
    }
}