import { EventEmitter } from "events";
import { IAuthService } from "telehealth-abstractions";
import { signalRConnectionBuilder, urlHelper } from "telehealth-api";
import { HubConnection } from "@microsoft/signalr";

export enum ChatManagerEvents {
  MESSAGE_RECEIVED = "messageReceived",
}

export class ChatManager extends EventEmitter {
  private isConnected: boolean = false;
  private authService: IAuthService;
  private chatConnection: HubConnection | null = null;
  private messageIndex: number = 0;

  constructor(authService: IAuthService) {
    super();
    this.authService = authService;
  }

  private async getAccessToken() {
    const token = await this.authService.getAccessToken();
    if (token === null) {
      throw new Error("Unable to get auth token for chat service.");
    }
    return token;
  }

  private messageReceived(userId: string, userDisplayName: string, message: string) {
    var messageObj = {
      userId: userId,
      userDisplayName: userDisplayName,
      message: message,
      index: this.messageIndex,
    };
    this.messageIndex++;
    this.emit(ChatManagerEvents.MESSAGE_RECEIVED, messageObj);
  }

  async connectAsync() {
    if (this.isConnected) {
      return;
    }
    const connection = await signalRConnectionBuilder.connect(
      urlHelper.resolveUrl("/hub/chat/"),
      this.getAccessToken.bind(this)
    );
    connection.on("broadcastMessage", this.messageReceived.bind(this));
    this.chatConnection = connection;
    this.isConnected = true;
  }

  disconnect() {
    if (this.chatConnection) {
      this.chatConnection.off("broadcastMessage");
      this.chatConnection.stop();
    }
    this.chatConnection = null;
    this.isConnected = false;
  }

  getIsConnected() {
    return this.isConnected;
  }

  broadcastMessage(message: string, roomId: string) {
    if (!this.chatConnection || !this.isConnected) {
      throw new Error("Chat is not connected. Cannot send message.");
    }
    this.chatConnection.send("broadcastMessage", message, roomId);
  }
}
