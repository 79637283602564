import { ApiResponse } from "common/ApiResponse";

export class ApiResponseHandler<T, TResponse> {
	async handle(unhandled: Response, onSuccess: (result: TResponse) => T, onFailure: () => T) {
		let response = await ApiResponse.create<T, TResponse>(unhandled, 
			r => onSuccess(r), 
			() => onFailure());

		return response;
	}
}