import React, { ChangeEvent, useState } from "react";
import { Col, Row } from "reactstrap";
import { FontAwesome } from "../Common/FontAwesome";

interface SlushboxProps<T> {
  availableItems: Iterable<T>;
  assignedItems?: Iterable<T>;
  displayText: (item: T) => string;
  valueFactory?: (item: T) => string;
  mapOptionFrom: (option: HTMLOptionElement) => T;
  onAssignedItemsChanged: (allAssignedItems: T[]) => void;
  onAvailableItemsChanged: (availableItems: T[]) => void;
  itemsEqual?: (first: T, second: T) => boolean;
}

export const Slushbox = <T,>({
  availableItems,
  assignedItems,
  displayText,
  valueFactory,
  mapOptionFrom,
  onAssignedItemsChanged,
  onAvailableItemsChanged,
  itemsEqual,
}: SlushboxProps<T>) => {
  const [pendingAssignments, setPendingAssignments] = useState<T[]>([]);
  const [pendingUnassignments, setPendingUnassignments] = useState<T[]>([]);

  const itemOptions = (items: T[]) => {
    return items.map((item) => {
      const optionValue = valueFactory ? valueFactory(item) : displayText(item);
      return (
        <option key={optionValue} value={optionValue}>
          {displayText(item)}
        </option>
      );
    });
  };

  const assign = () => {
    onAssignedItemsChanged(sortItemsByDisplayText([...(assignedItems ?? []), ...pendingAssignments]));

    var available = Array.from(availableItems ?? []);

    onAvailableItemsChanged(
      sortItemsByDisplayText([...available.filter((a) => !!!pendingAssignments.some((p) => itemsSame(a, p)))])
    );

    setPendingAssignments([]);
  };

  const unassign = () => {
    let assignments = Array.from(assignedItems ?? []);
    onAssignedItemsChanged([...assignments.filter((x) => !!!pendingUnassignments.some((p) => itemsSame(x, p)))]);

    var available = Array.from(availableItems ?? []);
    onAvailableItemsChanged(sortItemsByDisplayText([...available, ...pendingUnassignments]));
    setPendingUnassignments([]);
  };

  const markAvailableForAssignment = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = mapSelectedOptionsToArrayT(event.target.selectedOptions);

    setPendingAssignments([...selectedOptions]);
  };

  const markAssignmentForAvailable = (event: ChangeEvent<HTMLSelectElement>) => {
    const itemsToMakeAvailable = mapSelectedOptionsToArrayT(event.target.selectedOptions);

    setPendingUnassignments([...itemsToMakeAvailable]);
  };

  const mapSelectedOptionsToArrayT = (selectedOptions: HTMLCollectionOf<HTMLOptionElement>) => {
    return Array.from(selectedOptions).map((opt) => mapOptionFrom(opt));
  };

  const sortItemsByDisplayText = (items: T[]) => {
    if (items && items.length === 1) {
      return items;
    }

    return items.sort((first, second) => {
      const firstText = displayText(first);
      const secondText = displayText(second);

      return firstText < secondText ? -1 : firstText === secondText ? 0 : 1;
    });
  };

  const itemsSame = (first: T, second: T) => {
    if (itemsEqual) {
      return itemsEqual(first, second);
    } else {
      return first === second;
    }
  };

  return (
    <Row>
      <Col xs={{ size: 5 }}>
        <select
          className="form-control"
          name="availableItems"
          aria-label="available items"
          onChange={(event) => markAvailableForAssignment(event)}
          multiple={true}
        >
          {itemOptions(Array.from(availableItems ?? []))}
        </select>
      </Col>
      <Col className="d-inline-flex flex-column align-items-center justify-content-center">
        <FontAwesome className="clickable mb-2" icon="fa-chevron-right" onClick={() => assign()}></FontAwesome>
        <br />
        <FontAwesome className="clickable" icon="fa-chevron-left" onClick={() => unassign()}></FontAwesome>
      </Col>
      <Col xs={{ size: 5 }}>
        <select
          className="form-control"
          name="selectedItems"
          aria-label="assigned items"
          onChange={(event) => markAssignmentForAvailable(event)}
          multiple={true}
        >
          {itemOptions(Array.from(assignedItems ?? []))}
        </select>
      </Col>
    </Row>
  );
};
